import { useCallback } from "react"
import { localStorageCons } from "utils/constants"


export const useAutoCompleteInput = () => {
    const data = JSON.parse(localStorage.getItem(localStorageCons.AUTO_COMPLETE_DATA) ?? '{}')
    const saveInformation = useCallback((keyData,key, item) => {
        if (!Object.keys(data).includes(keyData)) {
            data[keyData] = []
        }
        const index = data[keyData].findIndex((i: any) => i[key] === item[key])
        if (index === -1) {
            data[keyData].push(item)
        }
        else {
            data[keyData][index] = item
        }
        localStorage.setItem(localStorageCons.AUTO_COMPLETE_DATA, JSON.stringify(data))
    }, [data])

    return {
        dataAutoComplete: data,
        saveInformationAutoComplete: saveInformation
    }
}