import { theme } from "theme";

export default {
    active: {
        display:'flex',
        alignItems:'center',
        backgroundColor: theme.background.third,
        color: theme.text.secondary,
        border: `1px solid ${theme.background.primary}`,
    },
    deactive: {
        color: theme.background.black,
        display:'flex',
        alignItems:'center',
    }
}