export const areEqual = (prev, next) => {
  try {
    return JSON.stringify(prev) === JSON.stringify(next);
  } catch (error) {
    return false;
  }
};

export const debounce = (func, timeout = 300) => {
  let timer
  return function (...args) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      func.apply(debounce, args)
    }, timeout)
  }
}

export const getMeta = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = url;
  });

export const parseObjectToQueryString = (obj: any) => {
  const str: any[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((item) => {
          if (typeof item === "object") str.push(`${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(item))}`);
          else str.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
        });
      }
      else str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
    // if obj[key] is array
  });
  return str.join("&");
};