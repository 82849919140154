import React, { createContext, memo, useCallback, useEffect, useRef, useState } from "react";
import { areEqual } from "utils/libs";
import { List } from "./components";
import { Flex } from "antd";
// import { IHotel } from "./components/type";
import { useCommon } from "hooks";
import { Detail } from "./components/Detail";
import { STEP } from "../types";
import { PaymentForm } from "./components";
import dayjs from "dayjs";
import PaymentFormMobile from "./components/Booking/PaymentFormMobile";
import Hook from "../hook";
import { filterInit } from "./components/List/data";
//import HookBase from "pages/base/hook";
// import { LayoutContext } from "./Layout/Layout";
// import CartForm from "./components/Cart/CartForm";

export const stepContext = createContext({})

const Hotel = () => {
    const { isSmallScreen } = useCommon()
   
    const { getAccommodation, getChainBrands, getProperty } = Hook()
    const [step, setStep] = useState<string>(STEP.LIST)
    const hashUrl = window.location.hash
    const [dataStep, setDataStep] = useState({
        [STEP.LIST]: {
            isBottom: false,
            searchParams: filterInit
        },
        [STEP.DETAIL]: {
            guidView: null,
            data: null,
            checkindt: null,
            checkoutdt: null,
            selectedRoom: null,
            activeKey: 'overview',
        },
        [STEP.PAYMENT]: {
            roomtype: null,
            bookingDate: dayjs().format('DD/MM/YYYY'),
            bookingCode: '---',
            expiredRequest: '---',
            method: null
        },
        [STEP.COMMON]: {
            hoverItem: null,
            center: {
                lat: 0,
                lng: 0
            },
            showMaps: false,
        },
        [STEP.CART]: {
            cartOption: 0,
            roomtype: null,
            overview: null,
            bookingDate: dayjs().format('DD/MM/YYYY'),
            bookingCode: '---',
            expiredRequest: '---',
            method: null
        },
    })

    useEffect(() => {
        if (hashUrl) {
            const decodedString = atob(hashUrl.replace('#', ''));
            const decodedObject = JSON.parse(decodedString);
            if (decodedObject?.step) {
                setStep(decodedObject.step)
            }
            if (decodedObject.dataStep) {
                setDataStep(old=>({...old, ...decodedObject.dataStep}))
            }
        }
    }, [hashUrl]);

    const handleDataStep = useCallback((method: 'GET' | 'SET', _step: string, key: string, value?: any) => {
        if (method === 'GET') {
            return dataStep[_step][key]
        }
        if (method === 'SET') {
            if (key === undefined) {
                setDataStep(old => {
                    if (typeof value === 'function') {
                        value = value(old[_step])
                    }
                    return {
                        ...old,
                        [_step]: value
                    }
                })
            }
            else
                setDataStep(old => {
                    if (typeof value === 'function') {
                        value = value(old[_step][key])
                    }
                    return {
                        ...old,
                        [_step]: {
                            ...old[_step],
                            [key]: value
                        }
                    }

                })
        }
    }, [dataStep, setDataStep])

    const listScroll = useRef<HTMLElement>(null)

    // const showMaps = useMemo(() => {
    //     return dataStep[STEP.COMMON]?.showMaps
    // }, [dataStep?.[STEP.COMMON]?.showMaps])

    useEffect(() => {
        listScroll.current && listScroll?.current?.addEventListener('scroll', () => {
            const { scrollTop = 0, scrollHeight, clientHeight } = listScroll.current as any
            if ((scrollTop + clientHeight) === scrollHeight) {
                handleDataStep('SET', STEP.LIST, 'isBottom', true)
            }
        })
    }, [listScroll?.current])

    useEffect(() => {
        getAccommodation()
        getChainBrands({
            keywords: '',
            page: 1,
            pageSize: 5,
        })
        getProperty()
    }, [])

    // useEffect(() => {
    //     setLayoutProps(old => {
    //         if (step === STEP.LIST) {
    //             return {
    //                 ...old,
    //                 isShow: false,
    //                 handleBack: () => location.replace('/')
    //             }
    //         }
    //         if (step === STEP.DETAIL) {
    //             return {
    //                 ...old,
    //                 isShow: true,
    //                 handleBack: () => setStep(STEP.LIST)
    //             }
    //         }
    //         if (step === STEP.PAYMENT) {
    //             return {
    //                 ...old,
    //                 isShow: true,
    //                 handleBack: () => setStep(STEP.DETAIL)
    //             }
    //         }
    //         return {
    //             ...old,
    //             isShow: false,
    //             handleBack: () => location.replace('/')
    //         }

    //     })
    // }, [step])
    

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }}>
            <stepContext.Provider value={{ step, setStep, handleDataStep }}>

                {isSmallScreen
                    ?
                    // MOBILE VIEW
                    (<>
                        {step === STEP.PAYMENT && (
                            <PaymentFormMobile />
                        )}
                        {step === STEP.LIST && (
                            <Flex
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'auto',
                                    backgroundColor: 'white',
                                    borderRight: '1px solid var(--black-black-200, #E5E5E5)',
                                }}
                            >
                                <List />
                                {/* <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        zIndex: 999,
                                        bottom: 6,
                                        justifyContent: 'center',
                                        position: 'absolute',
                                    }}
                                >
                                    <Button
                                        style={{
                                            display: 'flex',
                                            padding: 10,
                                            borderRadius: '1000px',
                                            alignItems: 'center',
                                            boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)'

                                        }}
                                        icon={<Icons.mapsIcon />}
                                        onClick={() => handleDataStep('SET', STEP.COMMON, 'showMaps', true)}
                                    >
                                        {t('hotel.view_maps')}
                                    </Button>
                                </div> */}
                                {/* <Modal
                                    footer={null}
                                    onCancel={() => setStep(STEP.LIST)}
                                    closeIcon={null}
                                    width={'100%'}
                                    style={{ height: 'calc(100% - 50px)', top: 50, left: 0, }}
                                    styles={{
                                        content: { width: '100%', height: '100%', overflowY: 'auto', padding: 0 },
                                        body: { padding: 0, width: '100%', height: '100%' },
                                    }}
                                    open={step == STEP.DETAIL ? true : false}
                                >
                                    <Detail
                                    />
                                </Modal> */}
                            </Flex>)
                        }
                        {step === STEP.DETAIL && (
                            <Flex
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'auto',
                                    backgroundColor: 'white',
                                    borderRight: '1px solid var(--black-black-200, #E5E5E5)',
                                }}
                            >
                                <Detail
                                />
                            </Flex>
                        )}
                    </>)
                    :
                    // DESKTOP VIEW
                    (<>
                        {step === STEP.PAYMENT && (
                            <PaymentForm />
                        )}
                        {(step === STEP.LIST || step === STEP.DETAIL) && (
                            <>
                                <Flex
                                    ref={listScroll}
                                    style={{
                                        // width: step == STEP.DETAIL ? '25%' : '50%',
                                        height: '100%',
                                        overflow: 'auto',
                                        backgroundColor: 'white',
                                        borderRight: '1px solid var(--black-black-200, #E5E5E5)',
                                        padding: '12px 12px',
                                        flex: 'initial',
                                        transition: 'all 0.3s ease-in-out'
                                    }}
                                >
                                    <List />
                                </Flex>
                                {/* DETAIL VIEW */}
                                {step === STEP.DETAIL &&
                                    <Flex
                                        style={{
                                            height: '100%',
                                            backgroundColor: 'white',
                                            borderRight: '1px solid var(--black-black-200, #E5E5E5)',
                                            flex: '1',
                                            transition: 'all 0.3s ease-in-out'
                                        }}>
                                        <Detail
                                        />
                                    </Flex>}
                                {/* <Flex
                                    style={{
                                        flex: step === STEP.DETAIL ? 'unset' : 'auto',
                                        width: step === STEP.DETAIL ? '25%' : 'unset',
                                        height: '100%',
                                        transition: 'all 0.3s ease-in-out'
                                    }}
                                >
                                    {API_KEY && <Maps API_KEY={API_KEY} />}
                                </Flex> */}
                            </>)}
                    </>)
                }
                {/* {
                    // MAP POPUP IN MOBILE VIEW
                    showMaps && (
                        <Modal
                            open={showMaps}
                            onCancel={() => handleDataStep('SET', STEP.COMMON, 'showMaps', false)}
                            footer={null}
                            width={'100%'}
                            title={t('hotel.view_maps')}
                            styles={{
                                content: {
                                    padding: 0,
                                    width: window.innerWidth - 24,
                                    height: window.innerHeight - 100,
                                },
                                body: {
                                    borderRadius: 6,
                                    padding: 12,
                                    width: '100%',
                                    height: '90%',
                                },
                                header: {
                                    padding: 12
                                }
                            }}
                        >
                            {API_KEY && <Maps API_KEY={API_KEY} />}
                        </Modal>
                    )
                } */}

            </stepContext.Provider>
        </div>
    )
}

export default memo(Hotel, areEqual);