export default {
    // home:"Trang chủ",
    // hotel:"Khách sạn",
    // flight:"Vé máy bay",
    // tours:"Tour",
    // cars:"Xe sân bay",
    // motel:"Nhà nghỉ cho thuê",
    // profile: "Quản lý tài khoản",
    // balance: "Số dư",
    // logout: "Đăng xuất",
    // login_failed: "Đăng nhập thất bại",
    // username: "Tên đăng nhập",
    // password: "Mật khẩu",
    // bookingsList:'Danh sách đặt phòng',
    // username_required: "Tên đăng nhập không được để trống",
    // password_required: "Mật khẩu không được để trống",
    // yes:'Có',
    // no:'Không',
    // login_success: "Đăng nhập thành công",
    // certificate: "Chứng nhận",
    // address: "Địa chỉ",
    // "phone-number": "Số điện thoại",
    // email: "Email",
    // subcribe: "Đăng ký nhận tin",
    // follow_us: "Theo dõi chúng tôi",
    // error: "Lỗi",
    login: 'Login',
    register: 'Register',
    hotel: 'Hotel',
    flight: 'Flight',
    tours: 'Tours',
    cars: 'Cars',
    motel: 'Motel',
    profile: 'Profile',
    balance: 'Balance',
    logout: 'Logout',
    login_failed: 'Login failed',
    username: 'Username',
    password: 'Password',
    bookingsList: 'Bookings List',
    username_required: 'Username is required',
    password_required: 'Password is required',
    yes: 'Yes',
    no: 'No',
    login_success: 'Login success',
    certificate: 'Certificate',
    address: 'Address',
    'phone-number': 'Phone number',
    email: 'Email',
    subcribe: 'Subcribe',
    follow_us: 'Follow us',
    error: 'Error',
}