import { theme } from "theme"


export default () => {
    return {
        col: {
            width: '100%',
            height: 'fit-content',
            padding: '32px 32px 48px 32px',
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            borderRadius: 8,
            backgroundColor: theme.background.white,
        },
        title: {
            color: theme.text.secondary,
            fontSize: 28,
            fontWeight: 600,
            lineHeight: '40px',
            lineSpacing: '0.28px',
        },
        roomInfo: {
            marginLeft: 12,
            fontSize: 20,
            fontWeight: 600,
            lineHeight: '28px',
        },
        roomTypeItem: {
            border: '1px solid #E5E5E5',
            borderRadius: '12px'
        },
        categoryName: {
            color: theme.text.black_900,
            fontSize: 14,
            fontWeight: 600,
        },
        imgReview: {
            width: '100%',
            borderRadius: '12px'
        },
        hotelName: {
            color: theme.text.blue,
            fontSize: 20,
            fontWeight: 600,
        },
        hotelAddress: {
            fontSize: 16,
            fontWeight: 500,
        },
        input: {
            display: 'flex',
            height: 44,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
        select: {
            height: '44px',
            width: '100%',
            alignItems: 'center',
            gap: '10px',
        },
        bookingCondition: {
            color: theme.text.black_900,
            fontSize: 14,
            fontWeight: 400,
        },
        attention: {
            display: 'flex',
            padding: 12,
            gap: 10,
            fexDirection: 'column',
            alignItems: 'flex-start',
            borderRadius: 6,
            border: `1px solid ${theme.background.blue_600}`,
            background: theme.background.blue_50,
        },
        attentionTitile: {
            color: theme.text.blue_600,
            fontSize: 16,
            fontWeight: 600,
        },
        attentionContent: {
            color: theme.text.blue_600,
            fontSize: 14,
            fontWeight: 400,
            marginLeft: 24,
        },
        billInfoLeft: {
            color: theme.text.black_800,
            fontSize: 14,
            fontWeight: 500,
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
        },
        billInfoRight: {
            color: theme.text.black_800,
            fontSize: 14,
            fontWeight: 500,
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
        },
        totalLabel: {
            color: theme.text.secondary,
            fontSize: 14,
            fontWeight: 600,
        },
        totalValue: {
            color: theme.text.secondary,
            fontSize: 14,
            fontWeight: 600,
        },
        backIcon: {
            width: '44px',
            height: '44px',
            cursor: 'pointer',
            padding: 10,
            boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
            borderRadius: 100,
            backgroundColor: theme.background.white,
        },
        payTitle: {
            color: theme.text.black_900,
            fontSize: 16,
            fontWeight: 600,
        },
        payDesc: {
            color: theme.text.black_900,
            fontSize: 14,
            fontWeight: 400,
        },
        balance: {
            color: theme.text.blue_600,
            fontSize: 14,
            fontWeight: 600,
        },
        balanceWarning: {
            color: theme.text.red_700,
            fontSize: 14,
            fontWeight: 400,
        },
        roomStatusOnRequest: {
            display: 'flex',
            alignItems: 'center',
            color: '#8338EC',
            backgroundColor: '#EFE4FC',
            border: '1px solid #8338EC',
            borderRadius: 4,
            padding: '5px 8px',
            marginBottom: 12,
            width: 'fit-content',
        },
        roomCompare: {
            color: theme.text.secondary,
            backgroundColor: theme.background.third,
            border: `1px solid ${theme.text.secondary}`,
            borderRadius: 4,
            padding: '5px 8px',
            display: 'flex',
            alignItems: 'center',
            height: 36,
        },
        roomStatusInstant: {
            display: 'flex',
            alignItems: 'center',
            color: theme.text.green,
            backgroundColor: '#E9FFE9',
            border: `1px solid ${theme.text.green}`,
            borderRadius: 4,
            padding: '5px 8px',
            marginBottom: 12,
            width: 'fit-content',
        },

        successNoti: {
            color: theme.text.green,
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
        }
    }
}

