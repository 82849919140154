export default {
    showTotal:'Tổng {{total}} bản ghi',
    title:'Quản lý công nợ',
    Keywords:'Từ khóa',
    FromDate:'Từ ngày',
    ToDate:'Đến ngày',
    Type:'Loại giao dịch',
    all:'Tất cả',
    transfer:'Chuyển khoản',
    debt:'Công nợ',
    pay:'Thanh toán',
    Search:'Tìm kiếm',
    transactionTime:'Ngày giao dịch',
    createdDateTS:'Ngày tạo',
    code:'Mã giao dịch',
    amount:'Số tiền',
    fee:'Phí xuất',
    accumulatedAmount:'Lũy kế',
    note:'Ghi chú',
    totalDebt:'Tổng tiền: {{total}}',
}