import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'startup';
import { Skeleton } from 'antd';
import Config from 'Config';
import { BoxSearchWidget } from 'pages/BoxSearchWidget/view';


const root = ReactDOM.createRoot(
  document.getElementById('widget-search') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Suspense fallback={<Skeleton active />}>
    <Provider store={store}>
      <Config>
        <BoxSearchWidget />
      </Config>
    </Provider>
  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
