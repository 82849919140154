/** @format */

import { lazy } from 'react';
import { enTrans, viTrans } from './trans';
import reducer from './store/reducer';
import watcher from './store/watcher';
import { pageLayout } from './view/Layout';

const Tour = lazy(() => import('./view/Tour'));
const TourDetail = lazy(() => import('./view/components/TourDetail/TourDetail'));
const Booking = lazy(() => import('./view/components/Booking/Booking'));
const BookingDetail = lazy(() => import('./view/components/BookingDetail/BookingDetail'));

export function init(saga: any[], reducers: any, route: any, en: any, vi: any) {
  reducers['tours'] = reducer;
  saga.push(...watcher);
  route.push({
    path: '/tours',
    Component: pageLayout,
    children: [
      {
        path: '',
        Component: Tour,
        exact: true,
      },
      {
        path: 'detail/:id',
        Component: TourDetail,
        exact: true,
      },
      {
        path: 'booking/:id',
        Component: Booking,
        exact: true,
      },
      {
        path: 'booking/detail/:id',
        Component: BookingDetail,
        exact: true,
      },
    ],
  });
  vi['tour'] = viTrans;
  en['tour'] = enTrans;
}
