/** @format */

import { takeEvery } from 'redux-saga/effects';

import {
  getSearchTourAction,
  getSegmentsAction,
  getTourDetailAction,
  getTourBookingAction,
  getBookingDetailAction,
  getNewstypeAction,
  getAllQuestionAction,
} from './actions';
import {
  getSegmentsRequest,
  getSearchTourRequest,
  getTourDetailRequest,
  getTourBookingRequest,
  getBookingDetailRequest,
  getNewstypeRequest,
  getAllQuestionsRequest,
} from './reducer';

export default [
  takeEvery(getSegmentsRequest, getSegmentsAction),
  takeEvery(getSearchTourRequest, getSearchTourAction),
  takeEvery(getTourDetailRequest, getTourDetailAction),
  takeEvery(getTourBookingRequest, getTourBookingAction),
  takeEvery(getBookingDetailRequest, getBookingDetailAction),
  takeEvery(getNewstypeRequest, getNewstypeAction),
  takeEvery(getAllQuestionsRequest, getAllQuestionAction),
];
