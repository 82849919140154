import { createSlice } from '@reduxjs/toolkit';

interface IBookingState {
    loading: boolean;
    error: string | null;
    message: string | null;
    data: any[];
    total: number;
    locationLoading: boolean;
    loadingDetail: boolean;
}
const initialState: IBookingState = {
    loading: false,
    error: null,
    message: null,
    data: [],
    total: 0,
    locationLoading: false,
    loadingDetail: false,
}

const hotelsSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        requestSuccess: (state) => ({
            ...state,
            loading: false,
            loadingDetail: false,
        }),
        requestFailed: (state, action) => ({
            ...state,
            loading: false,
            loadingDetail: false,
            error: action.payload?.errorMessages,
        }),
        getRequest: (state) => ({
            ...state,
            loading: true,
            data: []
        }),
        getSuccess: (state, action) => ({
            ...state,
            loading: false,
            data: action.payload?.items,
            total: action.payload?.pagingInfo?.totalItems,
            pageSize: action.payload?.pagingInfo?.pageSize,
            page: action.payload?.pagingInfo?.page,
        }),
        getFailed: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload?.errorMessages,
        }),
    }
});
const { actions, reducer } = hotelsSlice;

export const {
    requestSuccess,
    requestFailed,
    getRequest,
    getSuccess,
    getFailed,
} = actions;

export default reducer;
export type {
    IBookingState,
}
