import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getPaymentDetailRequest, getPaymentDetailFail, getPaymentDetailSuccess } from '../reducer'
import { getPaymentDetail } from '../../services';

export const getPaymentDetailNotifier = (params: any = {}, onSuccess, onFailure) => {
    return {
        type: getPaymentDetailRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getPaymentDetailAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: getPaymentDetail,
        params: payload,
        cancelId: type,
        failureAction: getPaymentDetailFail,
        successAction: getPaymentDetailSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}