import { theme } from "theme"


export default () => {
    return {
        input: {
            display: 'flex',
            height: 44,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
        totalLabel: {
            color: theme.text.secondary,
            fontSize: 14,
            fontWeight: 600,
        },
        totalValue: {
            color: theme.text.secondary,
            fontSize: 14,
            fontWeight: 600,
        },
        payTitle: {
            color: theme.text.black_900,
            fontSize: 16,
            fontWeight: 600,
        },
        payDesc: {
            color: theme.text.black_900,
            fontSize: 14,
            fontWeight: 400,
        },
        balance: {
            color: theme.text.blue_600,
            fontSize: 14,
            fontWeight: 600,
        },
        balanceWarning: {
            color: theme.text.red_700,
            fontSize: 14,
            fontWeight: 400,
        },
        success: {
            color: theme.text.green,
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
        },
        pending: {
            color: theme.text.blue_600,
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
        },
        failed: {
            color: theme.text.red_700,
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
        },
    }
}

