import { createSelector } from 'reselect';

const selector = (state: { flight }) => state.flight;

const error = createSelector(selector, ({ error }) => error);
const loading = createSelector(selector, ({ loading }) => loading);
const data = createSelector(selector, ({ data }) => data);
const total = createSelector(selector, ({ total }) => total);
const pageSize = createSelector(selector, ({ pageSize }) => pageSize);
const page = createSelector(selector, ({ page }) => page);

export {
    error,
    loading,
    data,
    total,
    pageSize,
    page,
}
