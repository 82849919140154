import { Col, DatePicker, Form, Row } from "antd";
import { TextCus } from "components";
import React, { memo } from "react";
import { theme } from "theme";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { t } from "i18next";
import Icons from "assets/icons";


const TimeInfo = () => {
    const styles = createStyles();
    return (
        <>
            <Row align='middle' gutter={[12, 12]} style={{ marginTop: 24 }}>
                <div style={{
                    width: 16,
                    height: 16,
                    position: 'relative',
                    background: theme.background.primary,
                    borderRadius: 100
                }}
                />
                <TextCus style={styles.roomInfo}>{t('hotel.time_info')}</TextCus>
            </Row>
            <Row align='middle' gutter={[24, 24]}>
                <Col span={12}>
                    <Form.Item
                        name='checkInDT'
                        label={<>
                            <span>{t('hotel.checkInDT')}</span>
                            <span style={{ color: 'red' }}>*</span>
                        </>
                        }
                        required
                        style={{ marginBottom: 0 }}
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            style={styles.input}
                            suffixIcon={<Icons.calendarIcon />}
                            inputReadOnly={true}
                            open={false}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='checkOutDT'
                        label={<>
                            <span>{t('hotel.checkOutDT')}</span>
                            <span style={{ color: 'red' }}>*</span>
                        </>
                        }
                        required
                        style={{ marginBottom: 0 }}
                    >
                        <DatePicker
                            format="DD/MM/YYYY"
                            style={styles.input}
                            suffixIcon={<Icons.calendarIcon />}
                            inputReadOnly={true}
                            open={false}
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}
export default memo(TimeInfo, areEqual);
