import React, { memo, useMemo } from "react";
import { Button, Col, Divider, Form, Input, Row, Space, Typography } from "antd";
import { useCommon } from "hooks";
import { areEqual } from "utils/libs";
import Hook from "pages/base/hook";
import { t } from "i18next";
import { Dialog, Image } from 'antd-mobile';
import Certificate from 'assets/images/Certificate.png'
import facebookIcon from 'assets/images/logo-facebook.png'
import linkedinIcon from 'assets/images/logo-linkedin.png'
import youtubeIcon from 'assets/images/logo-youtube.png'
import twitterIcon from 'assets/images/logo-twitter.png'
import iOsIcon from 'assets/images/ios-app.png'
import HdAddBookMaskForAndroid from 'assets/images/hd-android.jpeg'
import HdAddBookMask from 'assets/images/hd.png'
import androidIcon from 'assets/images/android-icon.jpg'
import { Link } from "react-router-dom";
import { TextCus } from "components";
import { theme } from "theme";

const { Title, Paragraph } = Typography

const Footer = () => {
    const { isSmallScreen } = useCommon()
    const { publishedConfigData, getNewsData } = Hook()
    const logo = useMemo(() => publishedConfigData?.find((item: any) => item.key === 'LOGO')?.value, [publishedConfigData])
    const companyName = useMemo(() => publishedConfigData?.find((item: any) => item.key === 'COMPANY_NAME')?.value, [publishedConfigData])
    const address = useMemo(() => publishedConfigData?.find((item: any) => item.key === 'ADDRESS')?.value, [publishedConfigData])
    const phoneNumber = useMemo(() => publishedConfigData?.find((item: any) => item.key === 'NUMBER_PHONE')?.value, [publishedConfigData])
    const email = useMemo(() => publishedConfigData?.find((item: any) => item.key === 'EMAIL')?.value, [publishedConfigData])
    const linkReference = getNewsData("LINK_FOOTER") || []
    const linkSocial = getNewsData("SOCIAL") || []

    return (
        <>
            <Row style={{ backgroundColor: '#F8F8F8', height: 'fit-content', overflow:'hidden' }} >
                <Col xs={24} xl={11} style={{ backgroundColor: '#F8F8F8' }}>
                    <Space
                        direction={'vertical'}
                        style={{
                            padding: '18px 18px 0px',
                            width: '100%',
                            lineHeight: '16px',
                        }}>
                        <img src={logo} style={{ height: '50px' }} />
                        <Title level={3} style={{ lineHeight: 1 }}>
                            {companyName}
                        </Title>
                        <Typography>
                            <Row
                                justify='space-between'
                            >
                                <Col span={5}>
                                    <TextCus style={{ fontWeight: 'bold' }}>base.address</TextCus>
                                </Col>
                                <Col span={19}>
                                    <TextCus
                                        style={{
                                            fontSize: 17,
                                            marginBottom: 6,
                                        }}>
                                        {address}
                                    </TextCus>
                                </Col>
                                <Col span={5}>
                                    <TextCus style={{ fontWeight: 'bold' }}>base.phone-number</TextCus>
                                </Col>
                                <Col span={19}>
                                    <TextCus
                                        style={{
                                            fontSize: 17,
                                            marginBottom: 6,
                                        }}>
                                        {phoneNumber}
                                    </TextCus>
                                </Col>
                                <Col span={5}>
                                    <TextCus style={{ fontWeight: 'bold' }}>base.email</TextCus>
                                </Col>
                                <Col span={19}>
                                    <TextCus
                                        style={{
                                            fontSize: 17,
                                            marginBottom: 6,
                                        }}>
                                        {email}
                                    </TextCus>
                                </Col>
                            </Row>
                            {/* <Paragraph
                                style={{
                                    fontSize: 17,
                                    marginBottom: 6,
                                }}>
                                <div style={!isSmallScreen ? { display: 'flex' } : { display: 'flex', flexDirection: 'column' }}>
                                    <Text style={!isSmallScreen ? { width: 166, fontWeight: 'bold' } : { fontWeight: 'bold' }}>
                                        {t('base.address')}
                                    </Text>
                                    <Text style={{
                                        fontSize: 17,
                                        marginBottom: 6,
                                    }}>
                                        {address}
                                    </Text>
                                </div>
                            </Paragraph> */}

                            {/* <Paragraph
                                style={{
                                    fontSize: 17,
                                    marginBottom: 6,
                                }}>
                                <div style={!isSmallScreen ? { display: 'flex' } : { display: 'flex', flexDirection: 'column' }}>
                                    <Text style={!isSmallScreen ? { width: 166, fontWeight: 'bold' } : { fontWeight: 'bold' }}>
                                        {t('base.phone-number')}
                                    </Text>
                                    <Text style={{
                                        fontSize: 17,
                                        marginBottom: 6,
                                    }}>
                                        {phoneNumber}
                                    </Text>
                                </div>
                            </Paragraph>
                            <Paragraph
                                style={{
                                    fontSize: 17,
                                    marginBottom: 6,
                                }}>
                                <div style={!isSmallScreen ? { display: 'flex' } : { display: 'flex', flexDirection: 'column' }}>
                                    <Text style={!isSmallScreen ? { width: 166, fontWeight: 'bold' } : { fontWeight: 'bold' }}>
                                        {t('base.email')}
                                    </Text>
                                    <Text style={{
                                        fontSize: 17,
                                        marginBottom: 6,
                                    }}>
                                        {email}
                                    </Text>
                                </div>
                            </Paragraph> */}
                        </Typography>
                        <Paragraph
                            style={{
                                fontSize: 17,
                                marginBottom: 6,
                            }}>
                            Giấy phép kinh doanh số 0314787204 do Sở kế hoạch và Đầu tư Tp.Hồ Chí Minh cấp ngày 13/12/2017
                        </Paragraph>
                    </Space>
                </Col>
                <Col xs={24} xl={3} style={{ backgroundColor: '#F8F8F8' }}>
                    <Space
                        direction={'vertical'}
                        style={{
                            padding: '18px 18px 0px',
                            width: '100%',
                            lineHeight: '20px',
                        }}>
                        <Typography>
                            <Title
                                style={{
                                    marginBottom: 0,
                                    marginTop: 12,
                                    fontSize: '20px',
                                    height: '32px',
                                    lineHeight: '0.9',
                                }}>
                                {t('base.certificate')}
                            </Title>
                            <img src={Certificate} alt="Certificate" />
                        </Typography>
                    </Space>
                </Col>
                <Col xs={24} xl={4} style={{ backgroundColor: '#F8F8F8' }}>
                    <Space
                        direction={'vertical'}
                        style={{
                            padding: '18px 18px 0px',
                            width: '100%',
                            lineHeight: '20px',
                        }}>
                        <Typography>
                            <Title level={4} style={{
                                marginBottom: 3,
                                marginTop: 12,
                                height: '32px',
                                lineHeight: '0.9',
                            }}>
                                {t('base.follow_us')}
                            </Title>

                            <div style={{
                                display: 'flex',
                                gap: '10px',
                            }}>
                                <a>
                                    <img
                                        src={facebookIcon}
                                        alt="Facebook"
                                        onClick={() => window.open(linkSocial?.find(i => i.title === 'facebook')?.url, '_blank')}
                                    />
                                </a>
                                <a>
                                    <img
                                        src={linkedinIcon}
                                        alt="Linkedin"
                                        onClick={() => window.open(linkSocial?.find(i => i.title === 'linkedin')?.url, '_blank')}
                                    />
                                </a>
                                <a>
                                    <img
                                        src={youtubeIcon}
                                        alt="Youtube"
                                        onClick={() => window.open(linkSocial?.find(i => i.title === 'youtube')?.url, '_blank')}
                                    />
                                </a>
                                <a>
                                    <img
                                        src={twitterIcon}
                                        alt="Twitter"
                                        onClick={() => window.open(linkSocial?.find(i => i.title === 'twitter')?.url, '_blank')}
                                    />
                                </a>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: 5 }}>
                                <a
                                    className="add-button"
                                    onClick={() =>
                                        Dialog.alert({
                                            confirmText: 'Đóng',
                                            content: (
                                                <>
                                                    <Space direction="vertical">
                                                        <Title level={4}>Hướng dẫn cài đặt</Title>
                                                        <Image src={HdAddBookMaskForAndroid} alt="contain" />
                                                    </Space>
                                                </>
                                            ),
                                            closeOnMaskClick: true,
                                        })
                                    }>
                                    <img width="58px" src={androidIcon} alt="andriod" />
                                </a>
                                <a
                                    className="add-button"
                                    onClick={() =>
                                        Dialog.alert({
                                            confirmText: 'Đóng',
                                            content: (
                                                <>
                                                    <Space direction="vertical">
                                                        <Title level={4}>Hướng dẫn cài đặt</Title>
                                                        <Image src={HdAddBookMask} alt="contain" />
                                                    </Space>
                                                </>
                                            ),
                                            closeOnMaskClick: true,
                                        })
                                    }>
                                    <img width="100px" src={iOsIcon} alt="ios" />
                                </a>
                            </div>
                        </Typography>
                    </Space>
                </Col>
                <Col xs={24} xl={6} style={{ backgroundColor: '#F8F8F8' }}>
                    <Space
                        direction={'vertical'}
                        style={{
                            padding: '18px 18px 0px',
                            width: '100%',
                            lineHeight: '20px',
                        }}>
                        <Typography>
                            <Title
                                level={4}
                                style={{
                                    marginBottom: 0,
                                    marginTop: 12,
                                    height: '32px',
                                    lineHeight: '0.9',
                                }}>
                                {t('base.subcribe')}
                            </Title>
                            <Col className="register_info">
                                <Form.Item name="email" className="form-control">
                                    <Input
                                        type="text"
                                        size="large"
                                        style={{
                                            width: '100%',
                                            padding: '10px 12px',
                                            borderRadius: 6,
                                        }}
                                        placeholder={t('base.email')}
                                    />
                                </Form.Item>
                                <Button block htmlType="submit" style={{
                                    backgroundColor: theme.background.primary,
                                    color: 'white',
                                    width: '100px',
                                    height: '44px',
                                    borderRadius: '8px',
                                    gap: '10px',
                                }}>
                                    {t('base.register')}
                                </Button>
                            </Col>
                        </Typography>
                    </Space>
                </Col>
                <Divider style={!isSmallScreen ? { margin: '10px 0 0 0' } : { margin: '10px 0 10px 0' }} />
            </Row >
            <Row style={{ backgroundColor: '#F8F8F8', display: 'flex', justifyContent: 'flex-start' }}>
                {
                    linkReference?.map((item: { url: string, title: string }, index: number) => (
                        <>
                            <div key={index} style={!isSmallScreen ? { padding: '20px 24px' } : { paddingLeft: 24 }}>
                                <Link
                                    to={item?.url}
                                    className="link-hover"
                                // onClick={() => MenuItemsDispatcher.getMenuItemsClicked(item.id)}
                                >
                                    {item?.title}
                                </Link>
                            </div>
                            <Divider
                                type={!isSmallScreen ? 'vertical' : 'horizontal'}
                                style={!isSmallScreen ? { margin: '20px 0' } : { margin: '10px 0' }}
                            />
                        </>
                    ))
                }
            </Row>
        </>
    )
}
export default memo(Footer, areEqual);