import { CSSProperties } from "react"


export default () => {
    return {
        input: {
            height: '44px',
            width: '100%',
            padding: '5px 5px',
            alignItems: 'center',
            gap: '10px',
        },
        datepicker: {
            height: '44px',
            width: '100%',
            padding: '5px 10px',
            alignItems: 'center',
            // gap: '10px',
        },
        select: {
            height: '44px',
            width: '100%',
            alignItems: 'center',
            gap: '10px',
        },
        btn_Search: {
            width: '100%',
            height: '44px',
            borderRadius: '8px',
        },
        groupDate: {
            display: 'flex'
        }
    } as { [key: string]: CSSProperties }
}

