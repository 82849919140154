
import { useDispatch, useSelector } from "react-redux";

const useConfig = () => {
    const dispatch = useDispatch();
    const currency = useSelector((state: any) => state.base.currency);
    const language = useSelector((state: any) => state.base.language);
    const setCurrentLanguage = (lang: string) => {
        dispatch({ type: 'base/setLanguage', payload: lang });
    }
    const setCurrentCurrency = (currency: string) => {
        dispatch({ type: 'base/setCurrency', payload: currency });
    }
    return {
        currency,
        language,
        setCurrentLanguage,
        setCurrentCurrency,
    };
}
export default useConfig;