import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getSearchTourRequest, getSearchTourSuccess, getSearchTourFailed } from '../reducer'
import { searchTour } from '../../services';

export const getSearchTourNotifier = (params: any = {},onSuccess: any, onFailure: any) => {
    return {
        type: getSearchTourRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getSearchTourAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: searchTour,
        params: payload,
        cancelId: type,
        failureAction: getSearchTourFailed,
        successAction: getSearchTourSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}