import React, { memo, useContext, useMemo } from "react";
import { Col, Divider, Row } from "antd";
import { areEqual } from "utils/libs";
import { theme } from "theme";
import { TextCus } from "components";
import createStyles from "./styles";
import { t } from "i18next";
import { stepContext } from "../../Hotel";
import Icons from "assets/icons";
import dayjs from "dayjs";
import { STEP } from "pages/Hotel/types";
import { useTranslation } from "react-i18next";

interface IBenefit {
    id: number;
    benefitName: string;
    translatedBenefitName: string;
}

const RoomInfo = () => {
    const styles = createStyles();
    const { i18n } = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])
    const { handleDataStep } = useContext(stepContext) as any
    const roomtype = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'roomtype'), [handleDataStep])
    return (
        <>
            <Row align='middle' gutter={[12, 12]}>
                <div style={{
                    width: 16,
                    height: 16,
                    position: 'relative',
                    background: theme.background.primary,
                    borderRadius: 100
                }}
                />
                <TextCus style={styles.roomInfo}>{t('hotel.room_info')}</TextCus>
            </Row>
            <div style={styles.roomTypeItem}>
                <div style={{ ...styles.categoryName, margin: '12px 24px', fontSize: 20 }}>
                    {`${roomtype?.occupancy?.Count} x ${roomtype.translatedRoomName && isVietnamese ? roomtype.translatedRoomName : roomtype.roomName}`}
                </div>
                <Row style={{ margin: 24 }} gutter={[24, 24]}>
                    <Col
                        sm={24}
                        xs={24}
                        md={12}
                        lg={6}
                        xl={6}
                        xxl={6}
                    >
                        <img src={roomtype?.roomPictureURL} alt="hotelRoomtypePicture" style={styles.imgReview}></img>
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={12}
                        lg={11}
                        xl={11}
                        xxl={11}>
                        <div style={{ marginBottom: 8 }}>{roomtype.bedType}</div>
                        {roomtype?.freeCancellation && <div style={{ marginBottom: 8 }}>Miễn phí hủy đến: {dayjs(roomtype.freeCancellationDate).format('DD/MM/YYYY')}</div>}
                        <div style={{ marginBottom: 8 }}><TextCus replace={{ 0: roomtype.maxOccupancyPerRoom }}>hotel.detail.maxOccupancyPerRoom</TextCus></div>
                        {roomtype.sizeOfRoom > 0 && <div style={{ marginBottom: 8 }}><TextCus replace={{ 0: roomtype.sizeOfRoom }}>hotel.detail.sizeOfRoom</TextCus></div>}
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={0}
                        xl={0}
                        xxl={0}
                    >
                        <Divider style={{ margin: 0 }} />
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={7}
                        xl={7}
                        xxl={7} >
                        <div style={{ ...styles.categoryName, marginBottom: 12 }}>Thông tin</div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6, gap: 6 }}>
                            <span><Icons.checkFillIcon /></span><span>Miễn phí hủy: {roomtype?.freeCancellation ? "Có" : "Không"}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 6, gap: 6 }}>
                            <span><Icons.checkFillIcon /></span><span>{`${t('hotel.detail.isCanPayLater')}: ${roomtype?.isCanPayLater ? t('Có') : t('Không')}`}</span>
                        </div>
                        <div style={{ marginBottom: 6 }}>
                            {roomtype?.benefits?.map((benefit: IBenefit) => (
                                <div
                                    key={benefit.id}
                                    style={{ display: 'flex', alignItems: 'center', marginBottom: 6, gap: 6 }}>
                                    <div>
                                        <Icons.checkFillIcon />
                                    </div>
                                    {benefit?.translatedBenefitName && isVietnamese ? benefit?.translatedBenefitName : benefit?.benefitName}
                                </div>
                            ))}
                        </div>
                    </Col>

                </Row>
            </div>
        </>
    );
}
export default memo(RoomInfo, areEqual);