import { CSSProperties } from "react"
import { theme } from "theme"


export default () => {
    return {
        input: {
            height: '44px',
            width: '100%',
            padding: '5px 5px',
            alignItems: 'center',
            gap: '10px',
        },
        titlePerson: {
            fontSize: 14,
            fontWeight: 600,
            color: theme.text.black_900
        },
        personFilterContainer: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 304,
            maxHeight: 408,
            overflowY: 'auto',
            padding: '0px 12px'
        },
    } as { [key: string]: CSSProperties }
}

