import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { TextCus } from "components";
import { theme } from "theme";
import { areEqual } from "utils/libs";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Hook from "pages/Hotel/hook";
import { useTranslation } from "react-i18next";
library.add(fab, fas)

interface IProps {
    value?: any
    setValue?: any
}

const Convenient: FC<IProps> = ({ value = [], setValue }) => {
    const [hover, setHover] = useState('')
    const [active, setActive] = useState<any[]>([])
    const { propertyData } = Hook()
    const {i18n} = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])

    const handleClick = useCallback((guid: string) => {
        if (active.includes(guid)) {
            setActive(old => old.filter(item => item !== guid))
        } else {
            setActive(old => [...old, guid])
        }
    }, [active])

    useEffect(() => {
        setValue(active)
    }, [JSON.stringify(active)])

    useEffect(() => {
        setActive(value)
    }, [JSON.stringify(value)])

    return (
        <Row
            style={{
                borderRadius: 6,
                border: '1px solid #E5E5E5',
                marginTop: 12
            }}
        >
            {propertyData?.map((item: any) => (
                <Col
                    key={item?.guid}
                    onMouseEnter={() => setHover(item?.guid)}
                    onMouseLeave={() => setHover('')}
                    onClick={() => handleClick(item?.guid)}
                    span={12}
                    style={{
                        width: '180px',
                        height: '85px',
                        border: `1px solid ${hover === item?.guid ? theme.background.primary : '#E5E5E5'}`,
                        borderTopLeftRadius: 6,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: active.includes(item?.guid) ? theme.background.third : 'unset',
                    }}>
                    <FontAwesomeIcon icon={item?.urlIcon} style={{ color: active.includes(item?.guid) ? theme.background.primary : theme.background.black, fontSize: 20 }} />
                    <TextCus
                        key='text'
                        style={{
                            fontSize: '14px',
                            fontWeight: 600,
                            lineHeight: '158%',
                            textAlign: 'center',
                            cursor: 'pointer',
                            color: active.includes(item?.guid) ? theme.text.secondary : theme.text.black_900,
                        }}
                    >
                        {item?.propertyGroupDescriptionTranslated && isVietnamese ? item?.propertyGroupDescriptionTranslated : item?.propertyGroupDescription}
                    </TextCus>
                </Col>
            ))}
            {/* <Col
                key='swim'
                onMouseEnter={() => setHover('swim')}
                onMouseLeave={() => setHover('')}
                onClick={() => setActive(old => [...old, 'swim'])}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === 'swim' ? theme.background.primary : '#E5E5E5'}`,
                    borderTopRightRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active.includes('swim') ? theme.background.third : 'unset',
                }}>
                <Icons.swimIcon
                    key='icon'
                    width={24}
                    height={24}
                    color={active.includes('swim') ? theme.background.primary : theme.background.black}
                />
                <TextCus
                    key='text'
                    style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active.includes('swim') ? theme.text.secondary : theme.text.black_900,
                    }}
                >
                    hotel.convenient.swim
                </TextCus>
            </Col>
            <Col
                key='parking'
                onMouseEnter={() => setHover('parking')}
                onMouseLeave={() => setHover('')}
                onClick={() => setActive(old => [...old, 'parking'])}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === 'parking' ? theme.background.primary : '#E5E5E5'}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active.includes('parking') ? theme.background.third : 'unset',
                }}>
                <Icons.parkingIcon
                    key='icon'
                    width={24}
                    height={24}
                    color={active.includes('parking') ? theme.background.primary : theme.background.black_900}
                />
                <TextCus
                    key='text'
                    style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active.includes('parking') ? theme.text.secondary : theme.text.black_900,
                    }}
                >
                    hotel.convenient.parking
                </TextCus>
            </Col>
            <Col
                key='spa'
                onMouseEnter={() => setHover('spa')}
                onMouseLeave={() => setHover('')}
                onClick={() => setActive(old => [...old, 'spa'])}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === 'spa' ? theme.background.primary : '#E5E5E5'}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active.includes('spa') ? theme.background.third : 'unset',
                }}>
                <Icons.spaIcon
                    key='icon'
                    width={24}
                    height={24}
                    color={active.includes('spa') ? theme.background.primary : theme.background.black_900}
                />
                <TextCus
                    key='text'
                    style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active.includes('spa') ? theme.text.secondary : theme.text.black_900,
                    }}
                >
                    hotel.convenient.spa
                </TextCus>
            </Col>
            <Col
                key='airCondition'
                onMouseEnter={() => setHover('airCondition')}
                onMouseLeave={() => setHover('')}
                onClick={() => setActive(old => [...old, 'airCondition'])}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === 'airCondition' ? theme.background.primary : '#E5E5E5'}`,
                    borderBottomLeftRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active.includes('airCondition') ? theme.background.third : 'unset',
                }}>
                <Icons.snowflakeIcon
                    key='icon'
                    width={24}
                    height={24}
                    color={active.includes('airCondition') ? theme.background.primary : theme.background.black_900}
                />
                <TextCus
                    key='text'
                    style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active.includes('airCondition') ? theme.text.secondary : theme.text.black_900,
                    }}
                >
                    hotel.convenient.airCondition
                </TextCus>
            </Col>
            <Col
                key='child'
                onMouseEnter={() => setHover('child')}
                onMouseLeave={() => setHover('')}
                onClick={() => setActive(old => [...old, 'child'])}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === 'child' ? theme.background.primary : '#E5E5E5'}`,
                    borderBottomRightRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active.includes('child') ? theme.background.third : 'unset',
                }}>
                <Icons.strollerIcon
                    key='icon'
                    width={24}
                    height={24}
                    color={active.includes('child') ? theme.background.primary : theme.background.black}
                />
                <TextCus
                    key='text'
                    style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active.includes('child') ? theme.text.secondary : theme.text.black_900,
                    }}
                >
                    hotel.convenient.child
                </TextCus>
            </Col> */}
        </Row>
    )
}

export default memo(Convenient, areEqual)