import { TextCus } from "components";
import React, { FC, memo, useContext, useEffect, useMemo } from "react";
import { areEqual } from "utils/libs";
import createStyles from "../styles";
import { Col, Divider, Row, Spin } from "antd";
import Hook from "pages/Hotel/hook";
import Icons from "assets/icons";
import { stepContext } from "pages/Hotel/view/Hotel";

const Policies: FC = () => {
    const styles = createStyles()
    const { isLoadingdetail, getPolicies, policiesData } = Hook()
    const { handleDataStep } = useContext(stepContext) as any
    const guid = useMemo(() => handleDataStep('GET', 'DETAIL', 'guidView'), [handleDataStep])

    useEffect(() => {
        guid && policiesData?.guid !== guid && getPolicies(guid)
    }, [guid])

    return (
        <Spin spinning={isLoadingdetail}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Row align='middle' style={{ margin: '6px 0' }} >
                    <TextCus style={styles.titleSecondary}>hotel.policyHotel</TextCus>
                </Row>
                {policiesData?.childAndExtraBedPolicy ?
                    <Row gutter={[24, 24]} style={{ margin: 0, gap: 12 }}>
                        <Col xs={24}
                            sm={24}
                            md={11}
                            lg={24}
                            xl={11}
                            xxl={11} style={{ border: '1px solid #CCC', borderRadius: 12 }}>
                            <Row style={{ margin: '24px 0' }}>
                                <Col span={3}>
                                    <Icons.kidIcon />
                                </Col>
                                <Col span={21} >
                                    <TextCus
                                        replace={{
                                            0: policiesData?.childAndExtraBedPolicy?.infantAge,
                                            1: policiesData?.childAndExtraBedPolicy?.childrenAgeFrom - 1 as any,
                                            2: policiesData?.childAndExtraBedPolicy?.childrenAgeFrom - 1 as any
                                        }}
                                        style={{ ...styles.categoryName, fontSize: 16 }}>hotel.policyInfantAge</TextCus>
                                </Col>
                            </Row>
                            <Divider />
                            <TextCus style={styles.categoryName}>
                                hotel.policyStayFree1
                            </TextCus>
                            <Row>
                                <TextCus style={{ padding: '12px 0' }}>
                                    hotel.policyInfantAgeSubInfo
                                </TextCus>
                            </Row>
                        </Col>
                        <Col xs={24}
                            sm={24}
                            md={11}
                            lg={24}
                            xl={11}
                            xxl={11} style={{ border: '1px solid #CCC', borderRadius: 12 }}>
                            <Row style={{ margin: '24px 0' }}>
                                <Col span={3}>
                                    <Icons.kidIcon />
                                </Col>
                                <Col span={21}>
                                    <TextCus
                                        replace={{
                                            0: policiesData?.childAndExtraBedPolicy?.childrenAgeFrom,
                                            1: policiesData?.childAndExtraBedPolicy?.childrenAgeTo as any,
                                            2: policiesData?.childAndExtraBedPolicy?.childrenAgeTo as any
                                        }}
                                        style={{ ...styles.categoryName, fontSize: 16 }}>hotel.policyChildrenAge</TextCus>
                                </Col>
                            </Row>
                            <Divider />
                            <TextCus style={styles.categoryName}>
                                {policiesData?.childAndExtraBedPolicy?.childrenStayFree === 'true' ? 'hotel.policyStayFree1' : 'hotel.policyStayFree0'}
                            </TextCus>
                            <Row>
                                <TextCus style={{ padding: '12px 0' }}>
                                    hotel.policyChildrenAgeSubInfo
                                </TextCus>
                            </Row>
                        </Col>
                        <Col xs={24}
                            sm={24}
                            md={11}
                            lg={24}
                            xl={11}
                            xxl={11} style={{ border: '1px solid #CCC', borderRadius: 12 }}>
                            <Row style={{ margin: '24px 0' }}>
                                <Col span={3}>
                                    <Icons.kidIcon />
                                </Col>
                                <Col span={21}>
                                    <TextCus
                                        replace={{ 0: policiesData.childAndExtraBedPolicy.childrenAgeTo + 1 }}
                                        style={{ ...styles.categoryName, fontSize: 16 }}>hotel.policyAdultAge</TextCus>
                                </Col>
                            </Row>
                            <Divider />
                            <TextCus style={styles.categoryName}>hotel.policyStayFree0</TextCus>
                            <Row>
                                <TextCus style={{ padding: '12px 0' }}>
                                    hotel.policyAdultAgeSubInfo
                                </TextCus>
                            </Row>
                        </Col>
                    </Row> : <Row>
                        <TextCus>hotel.updating</TextCus>
                    </Row>}

                <Row align='middle' style={{ margin: '6px 0' }} >
                    <TextCus style={styles.titleSecondary}>hotel.otherPolicy</TextCus>
                </Row>
                <Row>
                    <Col span={23}>
                        {policiesData?.remark ? <div dangerouslySetInnerHTML={{ __html: policiesData?.remark || '' }} /> : <Row>
                            <TextCus>hotel.updating</TextCus>
                        </Row>}
                    </Col>
                </Row>
                <Divider />
            </div >
        </Spin>
    )
}
export default memo(Policies, areEqual)