export default {
    login: 'Đăng nhập',
    register: 'Đăng ký',
    home:"Trang chủ",
    hotel:"Khách sạn",
    flight:"Vé máy bay",
    tours:"Tour",
    cars:"Xe sân bay",
    motel:"Nhà nghỉ cho thuê",
    profile: "Quản lý tài khoản",
    balance: "Số dư",
    logout: "Đăng xuất",
    login_failed: "Đăng nhập thất bại",
    username: "Tên đăng nhập",
    password: "Mật khẩu",
    bookingsList:'Danh sách đặt phòng',
    username_required: "Tên đăng nhập không được để trống",
    password_required: "Mật khẩu không được để trống",
    yes:'Có',
    no:'Không',
    login_success: "Đăng nhập thành công",
    certificate: "Chứng nhận",
    address: "Địa chỉ",
    "phone-number": "Số điện thoại",
    email: "Email",
    subcribe: "Đăng ký nhận tin",
    follow_us: "Theo dõi chúng tôi",
    error: "Lỗi",
    warning: "Chú ý",
    notification: {
        "ERRCH01": "Đã vượt quá thời gian đặt phòng, vui lòng tìm kiếm lại để cập nhật giá mới nhất!",
    },
    pay_now: "Thanh toán ngay",
    pay_now_desc: 'Bằng hạn mức có sẵn',
    pay_later: 'Thanh toán sau',
    pay_later_desc: 'Đặt trước & thanh toán sau',
    transfer: "Chuyển khoản",
    deposit: "Nạp tiền",
    transfer_desc: "Chuyển tiền từ tài khoản ngân hàng của bạn",
    credit: "Thẻ",
    credit_desc: "Nạp tiền từ thẻ ngân hàng của bạn",
    transfer_success: "Chuyển khoản thành công",
    transfer_pending: "Đang chờ xử lý",
    transfer_failed: "Chuyển khoản thất bại",
    close: "Đóng",
    voucher: "Voucher",
    totalBill: "Tổng hóa đơn",
    confirm: "Xác nhận",
    balance1: 'Số dư: {{balance}} đ',
    bank: "Chuyển khoản",
    deposit_amount: "Số tiền nạp",
    payment_method: "Phương thức thanh toán",
    cancel: "Hủy",
    payment_method_choose:"Chọn phương thức thanh toán",
    balanceWarning: 'Số dư của bạn không đủ để thanh toán, vui lòng ấn nạp tiền để nạp',

}