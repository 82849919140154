import { createSelector } from 'reselect';

const selector = (state: { hotels }) => state.hotels;

const error = createSelector(selector, ({ error }) => error);
const loading = createSelector(selector, ({ loading }) => loading);
const data = createSelector(selector, ({ data }) => data);
const total = createSelector(selector, ({ total }) => total);
const locLoading = createSelector(selector, ({ locationLoading }) => locationLoading);
const loadingDetail = createSelector(selector, ({ loadingDetail }) => loadingDetail);
const overview = createSelector(selector, ({ overview }) => overview);
const facilities = createSelector(selector, ({ facilities }) => facilities);
const roomtypes = createSelector(selector, ({ roomTypes }) => roomTypes);
const policies = createSelector(selector, ({ policies }) => policies);
const loadingBooking = createSelector(selector, ({ bookingLoading }) => bookingLoading);
const bookingDetail = createSelector(selector, ({ bookingDetail }) => bookingDetail);
const accommodation = createSelector(selector, ({ accommodation }) => accommodation);
const property = createSelector(selector, ({ property }) => property);
const chainBrands = createSelector(selector, ({ chainBrands }) => chainBrands);
const addCarts = createSelector(selector, ({ addCart }) => addCart);
const loadingAddCart = createSelector(selector, ({ addCartLoading }) => addCartLoading);
const loadingCancelBooking = createSelector(selector, ({ cancelBookingLoading }) => cancelBookingLoading);
const segments = createSelector(selector, ({ segments }) => segments);
const loadingSegment = createSelector(selector, ({ loadingSegment }) => loadingSegment);


export {
    error,
    loading,
    data,
    locLoading,
    total,
    loadingDetail,
    overview,
    facilities,
    roomtypes,
    policies,
    loadingBooking,
    loadingCancelBooking,
    bookingDetail,
    accommodation,
    property,
    chainBrands,
    addCarts,
    loadingAddCart,
    segments,
    loadingSegment,
}
