import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { Col, Row, Tooltip } from "antd";
import { areEqual } from "utils/libs";
import createStyles from '../styles'
import { IBenefit } from "pages/Hotel/types";
import Icons from "assets/icons";
import { TextCus } from "components";
import { t } from "i18next";
import { CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";


interface IProps {
    blockData: any
    selectedRoom?: any
    onSelected?: (data) => void
    main: any
}

const BlockItem: FC<IProps> = ({ blockData, selectedRoom, onSelected, main }) => {
    const styles = createStyles();
    const {i18n} = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])
    const [hover, setHover] = useState('');
    const isSelected = useMemo(() => selectedRoom?.blockId === blockData?.blockId, [selectedRoom, blockData]);
    const handleSelected = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        onSelected?.({
            blockId: blockData?.blockId,
            totalPayment: blockData?.totalPayment?.totalPrice,
            currency: blockData?.rate?.currency,
        });
    }, [blockData, onSelected]);

    const isHightlight = useCallback((benefit) => {
        return main?.benefits?.findIndex((item) => item.id === benefit.id) === -1
    }, [main]);

    return (
        <Row
            onMouseEnter={() => setHover(blockData?.blockId)}
            onMouseLeave={() => setHover('')}
            onClick={handleSelected}
            gutter={[12, 12]}
            style={{
                borderRadius: 12,
                padding: '8px 8px',
                border: hover === blockData?.blockId ? '1px solid #FB5607' : '1px solid #E5E5E5',
                ...isSelected && styles.blockSeleted
            }}>
            <Col span={12}>
                <div style={{ ...styles.categoryName, marginBottom: 6 }}>Thông tin</div>
                <div style={{ marginBottom: 3 }}>
                    {blockData?.benefits?.map((benefit: IBenefit) => (
                        <div key={benefit.id} style={{ display: 'flex', alignItems: 'center', gap: 6, lineHeight: 1.2 }}>
                            <div style={{ marginTop: 4 }}><Icons.checkFillIcon width={20} height={20} /></div>
                            <span
                                {...isHightlight(benefit) && { style: { fontWeight: 600 } }}
                            >{benefit?.translatedBenefitName && isVietnamese ? benefit?.translatedBenefitName :  benefit?.benefitName}</span>
                        </div>
                    ))}
                </div>
            </Col>
            <Col span={12}>
                <Row justify='end' align='middle' gutter={[12, 12]}>
                    <Col
                        span={24}
                        style={{ padding: 0 }}
                    >
                        <Row justify={'end'} align={'middle'} >
                            <TextCus
                                replace={{
                                    0: blockData?.remainingRooms
                                }}
                                style={{ ...styles.categoryName }}>
                                hotel.remainingRooms
                            </TextCus>
                        </Row>
                    </Col>
                    <Col
                        span={24}
                        style={{ textAlign: 'right', padding: 0 }}
                    >
                        <div style={{ ...styles.roomPrice, textAlign: 'right' }}>{`${blockData?.rate?.totalPrice?.toLocaleString()} ${blockData?.rate?.currency}`}</div>
                    </Col>
                    <Col style={blockData?.enumRoomtypeStatus === 1 ? styles.roomStatusOnRequest : styles.roomStatusInstant}>
                        {blockData?.enumRoomtypeStatus === 1 ? (
                            <>
                                <Icons.hourGlassIcon />
                                <span>On request</span>
                            </>

                        ) : (
                            <>
                                <Icons.flashIcon />
                                <span>Instant</span>
                            </>
                        )}
                    </Col>
                    {blockData?.freeCancellation &&
                        <Col style={{
                            ...styles.tag,
                            color: '#8338EC',
                            backgroundColor: '#EFE4FC',
                            border: '1px solid #8338EC'
                        }}>
                            <Tooltip title={t('hotel.detail.freeCancellationTooltip', { date: dayjs(blockData?.freeCancellationDate).format('HH:mm DD-MM-YYYY') })} color={'white'} fresh>
                                <CloseCircleOutlined style={{ marginRight: 6 }} />
                                <span>{t('hotel.detail.freeCancellation')}</span>
                            </Tooltip>
                        </Col>
                    }
                    {
                        blockData?.freeBreakfast && (
                            <Col style={{
                                ...styles.tag,
                                color: '#00A3FF',
                                backgroundColor: '#E6F7FF',
                                border: '1px solid #00A3FF'
                            }}>
                                <Icons.lunchIcon width={18} height={18} color='currentColor' style={{ marginRight: 6 }} />
                                <span>{t('hotel.detail.freeBreakfast')}</span>
                            </Col>
                        )
                    }
                    {
                        blockData?.isCanPayLater && (
                            <Col style={{
                                ...styles.tag,
                                color: '#da9100',
                                backgroundColor: '#fffacd',
                                border: '1px solid #da9100'
                            }}>
                                <Icons.moneyIcon style={{ marginRight: 6 }} color="currentColor"/>
                                <span>{t('hotel.detail.isCanPayLater')}</span>
                            </Col>
                        )
                    }
                    {
                        blockData?.extraBeds > 0 && (
                            <Col style={{
                                ...styles.tag,
                                color: '#FF8C00',
                                backgroundColor: '#FFF3E0',
                                border: '1px solid #FF8C00'
                            }}>
                                <Icons.bedIcon style={{ marginRight: 6 }} />
                                <span>{t('hotel.detail.extraBeds', { 0: blockData?.extraBeds })}</span>
                            </Col>
                        )
                    }
                </Row>
            </Col>
        </Row>
    )
}

export default memo(BlockItem, areEqual);
