import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { Col, Modal, QRCode, Row, Spin } from "antd";
import { areEqual } from "utils/libs";
import Icons from "assets/icons";
import createStyles from "./styles";
import { t } from "i18next";
import { TextCus } from "components";
import Hook from "pages/base/hook";

interface IProps {
    open: boolean
    onClose: () => void
    qrCode: string
    transactionId: string
    handleSuccess: (transactionId) => void
}
const QrTransfer: FC<IProps> = ({ open, onClose, qrCode, transactionId, handleSuccess }) => {
    const styles = createStyles();
    const [status, setStatus] = useState('PENDING')
    const { getPaymentDetail, publishedConfigData } = Hook()

    const Logo = useMemo(() => publishedConfigData?.find(i => i?.key === 'LOGO_SMALL')?.value, [publishedConfigData])


    useEffect(() => {
        if (transactionId) {
            const interval = setInterval(() => {
                status === 'PENDING' && transactionId &&
                    getPaymentDetail({ transactionId }, (res) => {
                        setStatus(res.paymentStatus)
                    })
                if (status === 'SUCCESSED') {
                    // tự tắt sau 2s
                    setTimeout(() => {
                        handleSuccess(transactionId)
                        onClose()
                    }, 2000)
                    clearInterval(interval)
                }
            }, 2000)
            return () => clearInterval(interval)
        }
    }, [transactionId, status])

    return (
        <Modal
            open={open}
            onCancel={onClose}
            okButtonProps={{ style: { display: 'none' } }}
            cancelText={t('base.close')}
        >
            <Spin spinning={status === 'loading'}>
                {status === 'SUCCESSED' &&
                    <Row justify='center' align='middle' style={{ width: '100%' }} gutter={[12, 12]}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Icons.successIcon size={60} />
                        </Col>
                        <Col span={24}>
                            <TextCus style={styles.success}>{t('base.transfer_success')}</TextCus>
                        </Col>
                    </Row>
                }
                {
                    status === 'PENDING' &&
                    <Row justify='center' align='middle' style={{ width: '100%' }} gutter={[12, 12]}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <QRCode
                                errorLevel="H"
                                size={400}
                                iconSize={400 / 4}
                                value={qrCode}
                                icon={Logo}
                            />
                        </Col>
                        <Col span={24}>
                            <TextCus style={styles.pending}>{t('base.transfer_pending')}</TextCus>
                        </Col>
                    </Row>
                }
                {
                    status === 'FAILED' &&
                    <Row justify='center' align='middle' style={{ width: '100%' }} gutter={[12, 12]}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Icons.xCircleIcon size={60} />
                        </Col>
                        <Col span={24}>
                            <TextCus style={styles.failed}>{t('base.transfer_failed')}</TextCus>
                        </Col>
                    </Row>
                }
            </Spin>
        </Modal>
    )
};
export default memo(QrTransfer, areEqual)