import { lazy } from "react";
import { enTrans, viTrans } from "./trans";
import reducer from "./store/reducer";
import watcher from "./store/watcher";
import { pageLayout } from "./view/Layout";

const Hotel = lazy(() => import("./view/Hotel"));
const PaymentDetail = lazy(() => import("./view/components/BookingDetail"));
const Booking = lazy(() => import("../BookedManager/view/Booking"));
const DebtManager = lazy(() => import("../DebtMangager/view"));

export function init(saga: any[], reducers: any, route, en, vi) {
    reducers['hotels'] = reducer;
    saga.push(...watcher);
    route.push({
        path: 'hotels',
        Component: pageLayout,
        children: [
            {
                path: '',
                Component: Hotel,
                exact: true,
            },
            {
                path: 'detail/:id',
                Component: PaymentDetail,
                exact: true,
            },
            {
                path: 'bookings',
                Component: Booking,
                exact: true,
            },
            {
                path: 'debt-manager',
                Component: DebtManager,
                exact: true,
            }
        ]
    });
    vi['hotel'] = viTrans;
    en['hotel'] = enTrans;
}