/** @format */

import React, { createContext, memo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { areEqual } from 'utils/libs';
import { Layout } from 'antd';
import Header from './Header';
import { useCommon } from 'hooks';
export const LayoutContext = createContext({} as any);

const pageLayout = () => {
  const { isSmallScreen } = useCommon();
  const [layoutProps, setLayoutProps] = useState({
    isShow: true,
    handleBack: () => location.replace('/'),
  });
  return (
    <LayoutContext.Provider value={{ layoutProps, setLayoutProps }}>
      <Layout
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {layoutProps?.isShow && (
          <Layout.Header
            style={{
              width: '100%',
              padding: 0,
              backgroundColor: '#ffff',
              borderBottom: '1px solid #E5E5E5',
              height: isSmallScreen ? 48 : 70,
              zIndex: 9999,
              overflow: 'hidden',
              position: 'sticky',
              top: 0,
            }}
          >
            <Header />
          </Layout.Header>
        )}
        <Layout.Content style={{ height: '100%', overflow: 'hidden' }}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </LayoutContext.Provider>
  );
};

export default memo(pageLayout, areEqual);
