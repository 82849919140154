import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputRef, Modal } from "antd";
import { areEqual } from "utils/libs";
import hook from "../hook";
import { TextCus } from "components";
import { theme } from "theme";
import { t } from "i18next";
import Icons from "assets/icons";
import { useNotify } from "hooks";

const LoginPopup = ({ open, onClose }: any) => {
    const { login, isLoading } = hook()
    const { success } = useNotify()
    const [form] = Form.useForm()
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const inputRef = useRef<InputRef>(null)

    const handleFinish = useCallback((value) => {
        login(
            value,
            () => {
                success({
                    title: '',
                    message: t('base.login_success'),
                })
                onClose()
            },
            () => setError(true))
    }, [login])

    const handleCancel = useCallback(() => {
        inputRef.current?.blur()
        onClose()
    }, [onClose, inputRef.current])

    useEffect(() => {
        if (open) {
            inputRef.current?.focus()
        }
        else {
            inputRef.current?.blur()
        }
    }, [ open])


    return (
        <Modal
            open={open}
            onCancel={handleCancel}
            width={500}
            centered
            style={{ borderRadius: 10, padding: 32 }}
            title={
                <TextCus
                    style={{
                        color: theme.text.secondary,
                        fontSize: 20,
                        fontWeight: 700,
                        textAlign: 'center',
                    }}
                >
                    base.login
                </TextCus>
            }
            closeIcon={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Form
                id='login-form'
                form={form}
                onFinish={handleFinish}
            >
                <Form.Item
                    name='username'
                    rules={[{ required: true, message: t('base.username_required') }]}
                >
                    <Input
                        ref={inputRef}
                        style={{ borderRadius: 5, height: 44 }}
                        type='text'
                        suffix={<Icons.userIcon />}
                        placeholder={t('base.username')}
                        onFocus={() => setError(false)}
                    />
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[{ required: true, message: t('base.password_required') }]}
                >
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        style={{ borderRadius: 5, height: 44 }}
                        placeholder={t('base.password')}
                        onFocus={() => setError(false)}
                        suffix={
                            showPassword ?
                                <Icons.eyesIcon onClick={() => setShowPassword(false)} />
                                :
                                <Icons.eyesSlashIcon onClick={() => setShowPassword(true)} />
                        }
                        onPressEnter={() => form.submit()}
                    />
                </Form.Item>
                <TextCus
                    type="danger"
                >
                    {error ? t('base.login_failed') : ''}
                </TextCus>
                <Button
                    onClick={() => form.submit()}
                    type='primary'
                    htmlType='submit'
                    loading={isLoading}
                    style={{
                        borderRadius: 5,
                        width: '100%',
                        height: 44,
                    }}
                >
                    {t('base.login')}
                </Button>
            </Form>
        </Modal>
    )
}

export default memo(LoginPopup, areEqual)