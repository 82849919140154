import React, { FC, Fragment, memo, useCallback, useContext, useMemo } from "react";
import { Button, Col, Divider, Row } from "antd";
import { TextCus } from "components";
import { t } from "i18next";
import { theme } from "theme";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { stepContext } from "../../Hotel";
import { STEP } from "pages/Hotel/types";
import Hook from "pages/Hotel/hook";
import Icons from "assets/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface IProps {
    form: any
    total: number
}

const Summary: FC<IProps> = ({ form, total }) => {
    const styles = createStyles();
    const {i18n} = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])
    const { isLoadingBooking } = Hook()
    const { handleDataStep } = useContext(stepContext) as any
    const hotelName = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'data')?.hotelName, [handleDataStep])
    const roomtype = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'roomtype'), [handleDataStep])
    const bookingDate = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'bookingDate'), [handleDataStep])
    const bookingCode = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'bookingCode'), [handleDataStep])
    const expiredRequest = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'expiredRequest'), [handleDataStep])
    const method = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'method'), [handleDataStep])
    
    const roomNightNumber = useMemo(() => {
        const checkInDT = handleDataStep('GET', STEP.DETAIL, 'checkindt')
        const checkOutDT = handleDataStep('GET', STEP.DETAIL, 'checkoutdt')
        const numberOfDays = dayjs(checkOutDT).diff(dayjs(checkInDT), 'day')
        return numberOfDays * roomtype?.occupancy?.Count;
    }, [roomtype, handleDataStep])

    const handleSubmit = useCallback(() => {
        form.submit()
    }, [method])

    return (
        <>
            <TextCus style={styles.title}>{t('hotel.bill')}</TextCus>
            <TextCus
                style={{ ...styles.hotelName, fontSize: 18 }}
            >
                {hotelName}
            </TextCus>
            <Row justify='space-between' style={{ width: '100%' }}>
                <Col span={12}>
                    <TextCus style={styles.billInfoLeft}>{t('hotel.bookingDate')}</TextCus>
                </Col>
                <Col span={12}>
                    <TextCus style={styles.billInfoRight}>{bookingDate}</TextCus>
                </Col>
                <Col span={12}>
                    <TextCus style={styles.billInfoLeft}>{t('hotel.bookingCode')}</TextCus>
                </Col>
                <Col span={12}>
                    <TextCus style={styles.billInfoRight}>{bookingCode}</TextCus>
                </Col>
                <Col span={15}>
                    <TextCus style={styles.billInfoLeft}>{t('hotel.expiredRequest')}</TextCus>
                </Col>
                <Col span={9}>
                    <TextCus style={{ ...styles.billInfoRight, color: theme.text.blue_600 }}>{expiredRequest}</TextCus>
                </Col>
                <Col span={24}>
                    <TextCus style={styles.billInfoLeft}>{t('hotel.afterBookingDate')}</TextCus>
                </Col>
            </Row>
            <Divider dashed style={{ margin: 0 }} />
            <Row justify='space-between' style={{ width: '100%' }}>
                <Col span={4}>
                    <TextCus style={styles.billInfoLeft}>{t('hotel.roomTitle')}</TextCus>
                </Col>
                <Col span={19}>
                    <TextCus style={{
                        ...styles.billInfoRight,
                        fontSize: 16,
                        fontWeight: 600,
                        color: theme.text.black_900
                    }}>
                        {roomtype.translatedRoomName && isVietnamese ? roomtype.translatedRoomName : roomtype.roomName}
                    </TextCus>
                </Col>
                <Col span={12}>
                    <TextCus style={styles.billInfoLeft}>{t('hotel.occupancy')}</TextCus>
                </Col>
                <Col span={12}>
                    <TextCus style={styles.billInfoRight}>{t('hotel.occupancyDetail', { num: roomtype?.maxOccupancyPerRoom })}</TextCus>
                </Col>
                <Col span={14}>
                    <TextCus style={styles.billInfoLeft}>{t('hotel.priceBill')}</TextCus>
                    {/* <TextCus style={styles.billInfoLeft}>{t('hotel.priceBillinfo')}</TextCus> */}
                </Col>
                <Col span={10}>
                    <TextCus style={{ ...styles.billInfoRight, color: theme.text.blue_600 }}>{`${roomNightNumber} x ${(roomtype?.rate?.totalPrice )?.toLocaleString()} ${roomtype?.rate?.currency}`}</TextCus>
                </Col>
                {/* <Divider dashed style={{ margin: 8 }} /> */}
                {roomtype?.surcharges?.filter(i => i.charge === 'Mandatory')?.map(surcharge => (
                    <>
                        <Col span={16}>
                            <TextCus style={styles.billInfoLeft}>{surcharge?.name}</TextCus>
                        </Col>
                        <Col span={8}>
                            <TextCus style={{ ...styles.billInfoRight, color: theme.text.blue_600 }}>{`${surcharge?.rate?.inclusive?.toLocaleString()} ${surcharge?.rate?.currency}`}</TextCus>
                        </Col>
                    </>
                ))}
            </Row>
            <Row justify='end' gutter={[12, 12]}>
                <Col
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: 'fit-content'
                    }}
                >
                    <div style={roomtype?.enumRoomtypeStatus === 1 ? styles.roomStatusOnRequest : styles.roomStatusInstant}>
                        {roomtype?.enumRoomtypeStatus === 1 ? (
                            <>
                                <Icons.hourGlassIcon />
                                <span>On request</span>
                            </>

                        ) : (
                            <>
                                <Icons.flashIcon />
                                <span>Instant</span>
                            </>
                        )}
                    </div>
                </Col>
                {/* <Col
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: 'fit-content'
                    }}
                >
                    <div style={styles.roomCompare}>-{roomtype?.ratePerDays?.[0]?.promoCompare}% so với Agoda</div>
                </Col> */}
            </Row>

            <Divider dashed style={{ margin: 0 }} />
            <Row justify='space-between' style={{ width: '100%' }}>
                <Col>
                    <TextCus style={styles.totalLabel}>{t('hotel.totalBill')}</TextCus>
                    <br />
                    <TextCus style={{ ...styles.totalLabel, fontWeight: 400 }}>{t('hotel.priceBillinfo')}</TextCus>
                </Col>
                <TextCus style={styles.totalValue}>{`${total?.toLocaleString()} ${roomtype?.rate?.currency}`}</TextCus>
            </Row>
            <Button
                type='primary'
                size='large'
                block
                loading={isLoadingBooking}
                onClick={handleSubmit}
            >
                {t('hotel.payment')}
            </Button>
        </>
    );
};
export default memo(Summary, areEqual);