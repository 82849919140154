import React, { FC, memo, useCallback, useContext, useMemo } from "react";
import { areEqual } from "utils/libs";
import { Tabs } from "antd";
import { keyTabs, tabsData } from "./data";
import Overview from "./Overview";
import Facilities from "./Facilities";
import Roomtypes from "./Roomtypes";
import Policies from "./Policies";
import { stepContext } from "pages/Hotel/view/Hotel";
import { STEP } from "pages/Hotel/types";
import { t } from "i18next";


const Content: FC = () => {
    const { handleDataStep } = useContext(stepContext) as any

    const activeKey = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'activeKey'), [handleDataStep])

    const TabContent = {
        [keyTabs.OVERVIEW]: <Overview />,
        [keyTabs.FACILITIES]: <Facilities />,
        [keyTabs.ROOMTYPES]: <Roomtypes />,
        [keyTabs.POLICIES]: <Policies />,
    }

    const handleChangeTab = useCallback((key: string) => {
        handleDataStep('SET', STEP.DETAIL, 'activeKey', key)
    }, [handleDataStep])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: 'initial', height: '50px' }}>
                <Tabs
                    activeKey={activeKey}
                    items={tabsData?.map(item => ({ label: t(item.label), key: item.key }))}
                    onChange={handleChangeTab}
                />
            </div>
            <div style={{ flex: 'auto' }}>
                {TabContent[activeKey]}
            </div>
        </div>
    );
}

export default memo(Content, areEqual);