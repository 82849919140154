import { Col, Form, FormInstance, Input, InputNumber, Row, Switch } from "antd";
import { AutoCompleteInput, Combobox, DatePickerCus, TextCus } from "components";
import { t } from "i18next";
import React, { FC, memo } from "react";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import Icons from "assets/icons";
import { theme } from "theme";
import hookBase from "pages/base/hook";
import './styles.css'
import dayjs from "dayjs";
import { useWatch } from "antd/es/form/Form";

interface IProps {
    index: number;
    field: any;
    remove: any;
    form: FormInstance;
    name: string;
}

const CustomerItem: FC<IProps> = ({ index, field, form, name }) => {
    const styles = createStyles();
    const { getCountries } = hookBase()
    const phoneCode = useWatch([name, field.name, 'phoneCode'], form)
    return (
        <Row align='middle' gutter={[24, 12]}>
            <Col span={24}>
                <TextCus style={{ fontSize: 16, fontWeight: 600 }}>{t('hotel.customer_index', { index: index + 1 })}</TextCus>
                <br />
                <TextCus style={{ fontSize: 14, fontWeight: 400 }}>Là người liên lạc chính</TextCus>
                <Form.Item name={[field.name, 'isMainContact']} noStyle valuePropName="checked">
                    <Switch style={{ marginLeft: 8 }} />
                </Form.Item>
            </Col>
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
            >
                <Form.Item
                    name={[field.name, 'fullName']}
                    label={<>
                        <span>{t('hotel.fullName')}</span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                    }
                    required
                    rules={[{ required: true, message: t('hotel.requiredField') }]}
                    style={{ marginBottom: 0, height: 96 }}
                >
                    <AutoCompleteInput
                        style={{ width: '100%', height: 'fit-content' }}
                        keyData='customerInfo'
                        keyField='fullName'
                        placeholder='Nhập họ tên'
                        childrenProps={
                            {
                                style: styles.input,
                            }
                        }
                        onSelect={(_, option) => {
                            form.setFieldValue([name, field.name, 'pin'], option.pin)
                            form.setFieldValue([name, field.name, 'nationalityIso2'], option.nationalityIso2)
                            form.setFieldValue([name, field.name, 'dob'], dayjs(option.dob))
                            form.setFieldValue([name, field.name, 'phoneNumber'], option.phoneNumber)
                            form.setFieldValue([name, field.name, 'phoneCode'], option.phoneCode)
                            form.setFieldValue([name, field.name, 'email'], option.email)
                        }}
                    />
                    {/* <Input
                        style={styles.input}
                    /> */}
                </Form.Item>
            </Col>
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
            >
                <Form.Item
                    name={[field.name, 'nationalityIso2']}
                    label={
                        <>
                            <span>{t('hotel.nationalityIso2')}</span>
                            <span style={{ color: 'red' }}>*</span>
                        </>
                    }
                    required
                    rules={[{ required: true, message: t('hotel.requiredField') }]}
                    style={{ marginBottom: 0, height: 96 }}
                >
                    <Combobox
                        suffixIcon={<Icons.dropDownIcon fill={theme.background.black} />}
                        getData={getCountries}
                        placeholder='Tìm & Chọn'
                        valueField="countryIso2"
                        displayField="countryTranslated"
                        style={styles.select}
                        handleSelect={
                            (_, option) => {
                                form.setFieldValue([name, field.name, 'phoneCode'], option.phoneCode)
                                form.setFieldValue([name, field.name, 'nationalityIso2'], option.countryIso2)
                            }
                        }

                    />
                </Form.Item>
            </Col>
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
            >
                <Form.Item
                    name={[field.name, 'pin']}
                    label={<>
                        <span>{t('hotel.pin')}</span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                    }
                    required
                    rules={[{ required: true, message: t('hotel.requiredField') }]}
                    style={{ marginBottom: 0, height: 96 }}
                >
                    <Input
                        placeholder='Nhập'
                        style={styles.input}
                    />
                </Form.Item>
            </Col>
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
            >
                <Form.Item
                    name={[field.name, 'dob']}
                    label={
                        <>
                            <span>{t('hotel.dob')}</span>
                        </>
                    }
                    required
                    style={{ marginBottom: 0, height: 96 }}
                    trigger="onChangeDate"
                >
                    <DatePickerCus
                        format="DD/MM/YYYY"
                        placeholder='dd/mm/yyyy'
                        style={styles.input}
                        suffixIcon={<Icons.calendarIcon />}
                        onChangeDate={(date) => {
                            form.setFieldValue([name, field.name, 'dob'], date)
                        }}
                    />
                </Form.Item>
            </Col>
            <Form.Item
                name={[field.name, 'phoneCode']}
                style={{ display: 'none' }}
                noStyle
            />
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
            >
                <Form.Item
                    name={[field.name, 'phoneNumber']}
                    label={<>
                        <span>{t('hotel.phoneNumber')}</span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                    }
                    required
                    rules={[
                        { required: true, message: t('hotel.requiredField') },
                        {
                            pattern: new RegExp(`\\d{9}$`),
                            message: t('hotel.phoneInvalid'),
                            validateTrigger: 'onSubmit'
                        }
                    ]}
                    style={{ marginBottom: 0, height: 96 }}
                >
                    <InputNumber
                        addonBefore={
                            <Combobox
                                style={{ width: 100, height: 44 }}
                                suffixIcon={<Icons.dropDownIcon fill={theme.background.black} />}
                                getData={getCountries}
                                valueField="phoneCode"
                                displayField="phoneCode"
                                value={phoneCode}
                                handleSelect={
                                    (value) => {
                                        form.setFieldValue([name, field.name, 'phoneCode'], value)
                                    }
                                }
                                showSearch={false}
                            />
                        }
                        className="inputNumber"
                        controls={false}
                        placeholder='---- ---'
                        style={styles.input}
                        parser={(value) => {
                            if (!value) return '';
                            return value.replace(/\D/g, '');
                        }}
                        maxLength={9}
                        type="tel"
                    />
                </Form.Item>
            </Col>
            <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
            >
                <Form.Item
                    name={[field.name, 'email']}
                    label={<>
                        <span>{t('hotel.email')}</span>
                        <span style={{ color: 'red' }}>*</span>
                    </>
                    }
                    required
                    rules={[
                        {
                            type: 'email',
                            message: t('hotel.emailInvalid'),
                        },
                        {
                            required: true,
                            message: t('hotel.requiredField'),
                        }
                    ]}
                    style={{ marginBottom: 0, height: 96 }}
                >
                    <Input
                        placeholder='Nhập Email'
                        style={styles.input}
                        inputMode='email'
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};
export default memo(CustomerItem, areEqual);