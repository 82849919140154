import { ThemeConfig } from "antd";
import theme from "./default";

const configTheme: ThemeConfig = {
  token: {
    colorPrimary: theme.background.primary,
    // colorTextBase: theme.text.primary,
    // colorBorder: theme.background.secondary,
    colorTextSecondary: theme.text.secondary,
    // colorTextPlaceholder: theme.text.third,
    fontFamily: theme.fontFamily,
    fontSize:14,
  },
  components: {
    Segmented: {
      itemSelectedColor: theme.background.primary,
      itemColor: theme.background.black,
    },
    Slider:{
      handleColor: theme.background.primary,
      handleActiveColor: theme.background.primary,
      trackBg: theme.background.primary,
    },
    Table:{
      footerBg: theme.background.third,
      headerBg: theme.background.third,
    }
  }
}

export {
  theme,
  configTheme
}