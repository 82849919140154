import React, { FC, memo, useContext, useEffect } from "react";
import { areEqual } from "utils/libs";
import { Content, Header } from "./Components";
import { Button, Card } from "antd";
import Icons from "assets/icons";
import { stepContext } from "../../Hotel";
import { STEP } from "pages/Hotel/types";
import { useCommon } from "hooks";
import { LayoutContext } from "../../Layout/Layout";

const Detail: FC = () => {
    const { setStep } = useContext(stepContext) as any
    const { isSmallScreen } = useCommon()
    const { setLayoutProps } = useContext(LayoutContext) as any
    useEffect(() => {
        setLayoutProps({
            isShow: true,
            handleBack: () =>isSmallScreen ? setStep(STEP.LIST) : location.replace('/'),
        })
    }, [isSmallScreen])
    return (
        <Card
            extra={
                <Button
                    type='text'
                    onClick={() => setStep(STEP.LIST)}>
                    <Icons.closeIcon
                    />
                </Button>
            }
            {...(isSmallScreen && { title: null, extra: null })}
            style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}
            bodyStyle={{
                padding: '0 12px',
                height: '100%',
                overflow: 'auto'
            }}
        >
            <Header />
            <Content />
        </Card>
    )
}
export default memo(Detail, areEqual)