import axios from "axios";
import { localStorageCons } from "./constants";
import dayjs from "dayjs";
import { useUser } from "hooks";


const instance = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});
const { logout } = useUser();
const refreshToken = async () => {
    const refreshTokenOld = JSON.parse(localStorage.getItem(localStorageCons.REFRESH_TOKEN) as string)?.token
    const { data } = await axios.post(`${process.env.REACT_APP_TOKEN_BASE_URL}/token/refresh-token`, { refreshToken: refreshTokenOld });
    if (data?.result) {
        const {
            accessToken,
            expired,
            refreshToken,
            id,
            name,
            lastname,
            username,
        } = data.result;

        const userInfo = {
            id,
            name,
            lastname,
            username,
        };

        localStorage.setItem(localStorageCons.USER_INFO, JSON.stringify(userInfo));
        localStorage.setItem(localStorageCons.ACCESS_TOKEN, JSON.stringify({ accessToken, expired }));
        localStorage.setItem(localStorageCons.REFRESH_TOKEN, JSON.stringify(refreshToken));
        return accessToken;
    }
    else logout();
    return null;
}
const getSiteKey = async () => {
    const url = window.location.hostname;
   const res = await axios.get(`${process.env.REACT_APP_AGENCY_BASE_URL}/sites/domain/${url}`)
    if (res.data?.result) {
        localStorage.setItem(localStorageCons.SITE_KEY, res.data.result.siteKey);
        localStorage.setItem(localStorageCons.SITE_ID, res.data.result.id);
        return res.data.result.siteKey;
    }
    return null;
}

instance.interceptors.request.use(
    async (req: any) => {
        const accessToken = JSON.parse(localStorage.getItem(localStorageCons.ACCESS_TOKEN) as string);
        const isExpired = dayjs(new Date(accessToken?.expired)).isBefore(dayjs());
        const SITEKEY = localStorage.getItem(localStorageCons.SITE_KEY) ?? '';
        if (!SITEKEY) {
            const newSiteKey = await getSiteKey();
            // nếu site key không tồn tại thì không gửi request
            if (!newSiteKey) return;
            req.headers.siteKey = newSiteKey;
        } else {
            req.headers.siteKey = SITEKEY;
        }

        let token = accessToken?.accessToken;

        if (isExpired) {
            token = await refreshToken();
        }

        if (token) {
            req.headers.Authorization = `Bearer ${token}`;
        }
        return req;
    },
    err => Promise.reject(err)
);

instance.interceptors.response.use(
    res => Promise.resolve(res),
    async err => {
        const originalRequest = err.config;
        if (err && err.response && err.response.status === 401 && !err.config.__isRetryRequest) {
            const accessToken = await refreshToken();
            originalRequest.headers = {
                ...originalRequest.headers,
                authorization: `Bearer ${accessToken}`,
            };

            originalRequest.__isRetryRequest = true;

            return instance(originalRequest);
        }
        return Promise.resolve(err.response);
    }
);
export default instance;