import reducer from "./store/reducer";
import watcher from "./store/watcher";
import { enTrans, viTrans } from "./trans";


export function init(saga: any[], reducers: any, route, en, vi) {
    reducers['base'] = reducer;
    saga.push(...watcher);
    vi['base'] = viTrans;
    en['base'] = enTrans;
}