import { Button, Col, Form, Input, Modal, Radio, Row } from "antd";
import { t } from "i18next";
import { STEP } from "pages/Hotel/types";
import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { areEqual } from "utils/libs";
import { stepContext } from "../../Hotel";
import { theme } from "theme";
import { TextCus } from "components";
import createStyle from "./styles";
import './styles.css'
import Hook from "pages/base/hook";
import Icons from "assets/icons";

interface IProps {
    open: boolean
    onClose: () => void
    onOk?: () => void
    total?: number
    currency?: string
}
const PaymentMethod: FC<IProps> = ({ open, onClose, total = 0, onOk, currency }) => {
    const [form] = Form.useForm()
    const styles = createStyle()
    const { getBalance, balanceInfo } = Hook()
    const [selected, setSelected] = useState('PAYNOW');
    const { handleDataStep } = useContext(stepContext) as any
    const isNotEnough = useMemo(() => balanceInfo?.amount < total, [balanceInfo, total])
    const roomtype = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'roomtype'), [handleDataStep])

    const handleOk = useCallback(() => {
        handleDataStep('SET', STEP.PAYMENT, 'method', selected)
        onClose()
        onOk && onOk()
    }, [handleDataStep, onClose, selected, onOk])

    useEffect(() => {
        getBalance({})
    }, [])

    return (
        <>
            <Modal
                title={t('hotel.payment_method_choose')}
                open={open}
                onCancel={onClose}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText={t('hotel.confirm')}
                onOk={handleOk}
                okButtonProps={{ disabled: isNotEnough && selected === 'PAYNOW' }}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleOk}
                >
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <Form.Item name='paymentMethod' noStyle>
                                <Radio.Group
                                    onChange={(e) => setSelected(e.target.value)}
                                    defaultValue={selected}
                                >
                                    <Row gutter={[12, 12]}>

                                        <Col span={roomtype.freeCancellation ? 12 : 24} style={{ height: '100%' }}>
                                            <Radio value={"PAYNOW"}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '12px 12px',
                                                    borderRadius: 12,
                                                    border: `1px solid ${selected === 'PAYNOW' ? theme.background.secondary : '#E5E5E5'}`,
                                                    backgroundColor: selected === 'PAYNOW' ? theme.background.third : '#FFFFFF',
                                                }}
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <TextCus style={styles.payTitle}>{t('hotel.pay_now')}</TextCus>
                                                    </Col>
                                                    <Col span={24}>
                                                        <TextCus style={styles.payDesc}>{t('hotel.pay_now_desc')}</TextCus>
                                                    </Col>
                                                    <Col span={24}>
                                                        <TextCus style={styles.balance}>{t('hotel.balance', { balance: balanceInfo?.amount?.toLocaleString() })}</TextCus>
                                                    </Col>
                                                </Row>
                                            </Radio>
                                        </Col>
                                        {roomtype.freeCancellation
                                            && roomtype?.isCanPayLater
                                            && <Col span={12} style={{ height: '100%' }}>
                                                <Radio value={"PAYLATER"}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'start',
                                                        justifyContent: 'space-between',
                                                        padding: '12px 12px',
                                                        borderRadius: 12,
                                                        border: `1px solid ${selected === 'PAYLATER' ? theme.background.secondary : '#E5E5E5'}`,
                                                        backgroundColor: selected === 'PAYLATER' ? theme.background.third : '#FFFFFF',
                                                    }}
                                                >
                                                    <Row>
                                                        <Col span={24}>
                                                            <TextCus style={styles.payTitle}>{t('hotel.booking')}</TextCus>
                                                        </Col>
                                                        <Col span={24}>
                                                            <TextCus style={styles.payDesc}>{t('hotel.pay_later_desc')}</TextCus>
                                                        </Col>
                                                    </Row>
                                                </Radio>
                                            </Col>
                                        }
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {isNotEnough && selected === 'PAYNOW' &&
                            <Col span={24}>
                                <Row gutter={[12, 12]}>
                                    <Col span={2}>
                                        <Icons.infoIcon color={theme.text.red_700} />
                                    </Col>
                                    <Col span={22}>
                                        <TextCus style={styles.balanceWarning}>{t('hotel.balanceWarning')}</TextCus>
                                    </Col>
                                </Row>
                            </Col>}
                        <Col span={24} style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
                            <Button
                                type='primary'
                                style={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: 8 }}
                            >{t('hotel.charge')}</Button>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='voucher'
                                label={<>
                                    <span>{t('hotel.voucher')}</span>
                                </>}
                            >
                                <Input
                                    placeholder='Nhập mã'
                                    style={styles.input} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col style={{ width: 'fit-content' }}>
                                    <TextCus style={styles.totalLabel}>{t('hotel.totalBill')}</TextCus>
                                </Col>
                                <Col>
                                    <TextCus style={styles.totalValue}>{`${total?.toLocaleString()} ${currency}`}</TextCus>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </>
    );
}
export default memo(PaymentMethod, areEqual);