const localStorageCons = {
    USER_INFO: 'userProfile',
    REFRESH_TOKEN: 'ApiTokenKey',
    ACCESS_TOKEN: 'ApiTokenInfo',
    USER_INPUT: 'USER_SITE_USER_INPUT',
    SITE_KEY: 'SITE_KEY',
    AUTO_COMPLETE_DATA: 'AUTO_COMPLETE_DATA',
    SITE_ID: 'SITE_ID',
    THEME: 'THEME',
    LANGUAGE: 'LANGUAGE',
    CURRENCY: 'CURRENCY',
}

const languageCons = {
    vi: 'vi-vn',
    en: 'en-us',
}

const languageTour = {
    vi: 'vi',
    en: 'en',
}
export {
    localStorageCons,
    languageCons,
    languageTour
}