
export const theme = {
    background: {
        primary: "#FB5607",
        secondary: "#FC6D35",
        third: "#FFF3F2",
        white: "#FFFFFF",
        black: "#545454",
        pink: "#FF1493",
        pink_200: "#E0218A",
        black_900: '#161616',
        blue_50: '#E4F2FF',
        blue_600: '#3A86FF',
        orange_100: '#FFCBB8'
    },
    text: {
        primary: "#FFFFFF",
        secondary: "#FB5607",
        third: "#FC6D35",
        black: '#676767',
        black_900: '#161616',
        black_800: '#363636',
        black_500: '#8F8F8F',
        black_200: '#E5E5E5',
        blue: '#3A86FF',
        pink: "#FF1493",
        yellow: '#FBCE07',
        green: '#00AA6C',
        blue_600: '#3A86FF',
        red_700: '#E9002E',
    },
    fontFamily: "Montserrat, sans-serif"
};

export const configThemeCus = (data) => {
    const primaryColor = data?.find((item) => item.key === 'PRIMARY_COLOR')?.value;
    const secondaryColor = data?.find((item) => item.key === 'SECONDARY_COLOR')?.value;
    const contrastColor = data?.find((item) => item.key === 'CONTRAST_COLOR')?.value;
    theme.background.primary = primaryColor || theme.background.primary;
    theme.background.secondary = primaryColor || theme.background.secondary;
    theme.background.third = secondaryColor || theme.background.third;
    theme.text.primary = contrastColor || theme.text.primary;
    theme.text.secondary = primaryColor || theme.text.secondary;
    theme.text.third = secondaryColor || theme.text.third;
    return theme;
}

export default theme;