import React, { createContext, FC, memo, useEffect, useMemo, useState } from 'react';
import Hook from 'pages/base/hook';
import { Outlet } from 'react-router-dom';
import { areEqual } from 'utils/libs';
import { ConfigProvider, Skeleton, ThemeConfig } from 'antd';
import { configThemeCus } from 'theme/default';
import { useCommon } from 'hooks';
import { i18n } from 'startup';
import { localStorageCons } from 'utils/constants';

export const ConfigColor = createContext({})

interface IProps {
    children: any
}

const Config: FC<IProps> = ({children}) => {
    const { getPusblishConfig, publishedConfigData, isLoadingConfig, getNews } = Hook()
    const { siteId } = useCommon()
    const [configTheme, setConfigTheme] = useState<ThemeConfig>({})

    const FAVICON = useMemo(() => publishedConfigData?.find((item: any) => item.key === 'FAVICON')?.value, [publishedConfigData])

    const setThemeConfig = (theme: any) => {
        setConfigTheme({
            token: {
                colorPrimary: theme.background.primary,
                colorTextSecondary: theme.text.secondary,
                fontFamily: theme.fontFamily,
                fontSize: 14,
            },
            components: {
                Segmented: {
                    itemSelectedColor: theme.background.primary,
                    itemColor: theme.background.black,
                },
                Slider: {
                    handleColor: theme.background.primary,
                    handleActiveColor: theme.background.primary,
                    trackBg: theme.background.primary,
                }
            }
        })

    }

    useEffect(() => {
        getPusblishConfig(
            {
                keyNames: [
                    'LOGO',
                    "CONTENT",
                    "NUMBER_PHONE",
                    "EMAIL",
                    "FAVICON",
                    "ENVIRONMENT",
                    "ADDRESS",
                    "COMPANY_NAME",
                    "PRIMARY_COLOR",
                    "SECONDARY_COLOR",
                    "CONSTRAST_COLOR",
                    "GOOGLE_MAP_API_KEY",
                    "LOGO_SMALL",
                    "STEP_TO_URL"
                ]
            }
        )
        getNews({
            siteId: siteId,
            keywords: 'LINK_FOOTER',
            Page: 1,
            PageSize: 10,
            IsMenu: false,
        })
        getNews({
            siteId: siteId,
            keywords: 'TERM',
            Page: 1,
            PageSize: 10,
            IsMenu: false,
        })
        getNews({
            siteId: siteId,
            keywords: 'SOCIAL',
            Page: 1,
            PageSize: 10,
            IsMenu: false,
        })
    }, [])

    useEffect(() => {
       i18n.changeLanguage(localStorage.getItem(localStorageCons.LANGUAGE) || 'vi')
    }, [])

    useEffect(() => {
        if (FAVICON) {
            let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = FAVICON;
        }
    }, [FAVICON])

    useEffect(() => {
        if (publishedConfigData) {
            const theme = configThemeCus(publishedConfigData)
            setThemeConfig(theme)
        }
    }, [publishedConfigData])

    return (
        <>
            {!isLoadingConfig && <Skeleton loading={isLoadingConfig} active />}
            {!isLoadingConfig &&
                <ConfigProvider theme={configTheme}>
                    <Outlet />
                    {children}
                </ConfigProvider>}
        </>
    )
}

export default memo(Config, areEqual)