import { call, fork, take, cancel } from 'redux-saga/effects';
import requestSuccess from './request-success';
import requestFailure from './request-failure';

// interface IRequest {
//     type: string;
//     service: any;
//     params: any;
//     redirect: any;
//     callback: (((data: any) => void) | null);
//     onSuccess: (((data: any) => void) | null);
//     onFailure: (((data: any) => void) | null);
//     normalizer: (((data: any) => void) | null);
// }
function* request({
    service,
    params = {},
    successAction,
    failureAction,
    callback = null,
    onSuccess = null,
    onFailure = null,
    normalizer = null,

}: any) {
    const { extraParams = "", ...props } = params;
    const response = yield call(service, props);

    if (!response) {
        // TODO: Handle server communication error
    } else {
        const data = response?.data;
        if (data?.isOK) {
            const normalizedData = normalizer ? normalizer(data?.result) : data?.result;
            yield call(requestSuccess, {
                data: normalizedData,
                extraParams,
                // redirect,
                callback,
                onSuccess,
                successAction
            });
        } else if (data?.errorMessages?.length) {
            yield call(requestFailure, {
                data: { errorMessages: data?.errorMessages },
                status: 200,
                onFailure,
                callback,
                failureAction
            });
        } else yield call(requestFailure, {
            data,
            status: 200,
            onFailure,
            failureAction,
            callback
        });
    }
    // TODO: Handle any loader ends
}

/**
 * The request function can be used with redux saga to make async API calls.
 *
 * By passing strategical params, the redux action will contain
 * valuable information to oost the redux/saga process.
 *
 * @type          Action type (Eg. FETCH_DATA).
 * @method        A strig with the type of method used to call the API (GET, POST, PUT, DELETE)
 * @endpoint      Tha actual enpoint called with the API
 * @api           An Axios instace containing the specific API information
 * @params        (optional) An object containing the data that will be sent to the endpoint
 * @path          (optional) A react-router to redirect after the request is succesfull
 * @callback      (optional) A callback function to be called after the request is succesfull with the data processed
 * @onSuccess     (optional) A custom generator function passed to redux saga if the request is successful
 * @onFailure     (optional) A custom generator function passed to redux saga if the request fails
 * @normalizer    (optional) A custom reducer to process API response data before reaching the reducer
 * @cancelId      (optional) A unique id that can be used with cancelable requests. If a cancelId is passed, the request will be canceled next time an identical id is recieved.
 */
function* cancelableRequest({ cancelId, ...rest }) {
    const myRequest = yield fork(request, rest);
    if (cancelId) {
        yield take(`${rest.type}_${cancelId}`);
        yield cancel(myRequest);
    }
}

export default cancelableRequest;