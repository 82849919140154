/** @format */

import { createSlice } from '@reduxjs/toolkit';

interface ITourState {
  loading: boolean;
  error: string | null;
  message: string | null;
  tours: any[];
  tourDetail: any;
  bookingLoading?: boolean;
  newstypeData?: any;
  faquestion?: any;
}
const initialState: ITourState = {
  loading: false,
  error: null,
  message: null,
  tours: [],
  tourDetail: null,
  bookingLoading: false,
  newstypeData: null,
  faquestion: null,
};

const toursSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    requestSuccess: state => ({
      ...state,
      loading: false,
    }),
    requestFailed: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload?.errorMessages,
    }),

    // segment
    getSegmentsRequest: state => ({
      ...state,
      loadingSegment: true,
    }),
    getSegmentsSuccess: (state, payload) => ({
      ...state,
      loadingSegment: false,
      segments: payload.payload,
    }),
    getSegmentsFailed: state => ({
      ...state,
      loadingSegment: false,
      segments: null,
    }),

    // search tour
    getSearchTourRequest: state => ({
      ...state,
      loading: true,
    }),
    getSearchTourSuccess: (state, action) => ({
      ...state,
      loading: false,
      tours: action.payload?.items,
      totalItems: action.payload?.pagingInfo?.totalItems,
      pageSize: action.payload?.pagingInfo?.pageSize,
      page: action.payload?.pagingInfo?.page,
    }),
    getSearchTourFailed: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload?.errorMessages,
    }),

    // tour detail
    getTourDetailRequest: state => ({
      ...state,
      loading: true,
    }),
    getTourDetailSuccess: (state, action) => ({
      ...state,
      loading: false,
      tourDetail: action.payload,
    }),
    getTourDetailFailed: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload?.errorMessages,
    }),

    // tour booking
    getTourBookingRequest: state => ({
      ...state,
      bookingLoading: true,
    }),
    getTourBookingSuccess: (state, action) => ({
      ...state,
      bookingLoading: false,
      tourBookingData: action.payload,
    }),
    getTourBookingFailed: (state, action) => ({
      ...state,
      bookingLoading: false,
      error: action.payload?.errorMessages,
    }),

    // booking detail
    getBookingDetailRequest: state => ({
      ...state,
      loading: true,
    }),
    getBookingDetailSuccess: (state, action) => ({
      ...state,
      loading: false,
      bookingDetail: action.payload,
    }),
    getBookingDetailFailed: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload?.errorMessages,
    }),

    // newstype
    getNewstypeRequest: state => ({
      ...state,
      loading: true,
    }),
    getNewstypeSuccess: (state, action) => ({
      ...state,
      loading: false,
      newstypeData: action.payload,
    }),
    getNewstypeFailed: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload?.errorMessages,
    }),

    // all questions
    getAllQuestionsRequest: state => ({
      ...state,
      loading: true,
    }),
    getAllQuestionsSuccess: (state, action) => ({
      ...state,
      loading: false,
      faqData: action.payload,
    }),
    getAllQuestionsFailed: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload?.errorMessages,
    }),
  },
});
const { actions, reducer } = toursSlice;

export const {
  requestSuccess,
  requestFailed,
  getSegmentsRequest,
  getSegmentsSuccess,
  getSegmentsFailed,
  getSearchTourRequest,
  getSearchTourSuccess,
  getSearchTourFailed,
  getTourDetailRequest,
  getTourDetailSuccess,
  getTourDetailFailed,
  getTourBookingRequest,
  getTourBookingSuccess,
  getTourBookingFailed,
  getBookingDetailRequest,
  getBookingDetailSuccess,
  getBookingDetailFailed,
  getNewstypeRequest,
  getNewstypeSuccess,
  getNewstypeFailed,
  getAllQuestionsRequest,
  getAllQuestionsSuccess,
  getAllQuestionsFailed,
} = actions;

export default reducer;
export type { ITourState };
