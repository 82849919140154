import React, { FC, memo, useCallback, useMemo, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import { areEqual } from 'utils/libs'
import createStyles from '../styles'
import { TextCus } from 'components'
import DetailRoom from "./DetailRoom";
import BlockItem from './BlockItem'
import { useTranslation } from 'react-i18next'

interface IProps {
    roomtype: any
    index: number
    selectedRoom?: any
    onSelected?: (data) => void
}

const RoomItem: FC<IProps> = ({ index, roomtype, onSelected, selectedRoom }) => {
    const styles = createStyles();
    const {i18n} = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])
    const [detailModal, setDetailModal] = useState(false)
    const [hover, setHover] = useState(false);
    const handleDetailRoom = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation();
        setDetailModal(true);
    };

    const main = useMemo(() => {
        // roomtype?.rateBenefitInfos lấy item có totalPayment nhỏ nhất
        return roomtype?.rateBenefitInfos?.reduce((prev, current) => (prev?.totalPayment?.inclusive < current?.totalPayment?.inclusive) ? prev : current)
    }, [roomtype]);

    const handleParentSelected = useCallback(() => {
        onSelected?.(old =>
            old?.roomId === roomtype?.roomId
                ? null
                : {
                    roomId: roomtype?.roomId,
                    blockId: roomtype?.rateBenefitInfos?.[0]?.blockId,
                    totalPayment: roomtype?.rateBenefitInfos?.[0]?.totalPayment.totalPrice,
                    currency: roomtype?.rateBenefitInfos?.[0]?.rate.currency
                });
    }, [onSelected, roomtype]);

    const handleSelected = useCallback((data) => {
        onSelected?.({ ...data, roomId: roomtype?.roomId })
    }, [onSelected, roomtype]);


    return (
        <>
            <div
                key={index}
                onClick={handleParentSelected}
                style={{
                    ...styles.roomTypeItem,
                    border: hover || (selectedRoom && selectedRoom.roomId === roomtype?.roomId) ? '1px solid #FB5607' : '1px solid #E5E5E5',
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div style={{ ...styles.categoryName, margin: '12px 24px', fontSize: 20 }}>
                    {roomtype.translatedRoomName && isVietnamese ? roomtype.translatedRoomName : roomtype.roomName}
                </div>
                <Row style={{ margin: 12 }} justify={'end'} gutter={[24, 24]}>
                    <Col
                        sm={24}
                        xs={24}
                        md={12}
                        lg={12}
                        xl={8}
                        xxl={8}
                    >
                        <img src={roomtype?.roomPictureURL} alt="hotelRoomtypePicture" style={{ ...styles.imgReview, cursor: 'pointer' }} onClick={handleDetailRoom} />
                        <div style={{ marginBottom: 6 }}>{roomtype.bedType}</div>
                        {/* {roomtype?.extraBeds > 0 && <div style={{ marginBottom: 6 }}><TextCus replace={{ 0: roomtype?.extraBeds }}>hotel.detail.extraBeds</TextCus></div>} */}
                        <div style={{ marginBottom: 6 }}><TextCus replace={{ 0: roomtype?.maxOccupancyPerRoom }}>hotel.detail.maxOccupancyPerRoom</TextCus></div>
                        {roomtype?.sizeOfRoom > 0 && <div style={{ marginBottom: 6 }}><TextCus replace={{ 0: roomtype?.sizeOfRoom }}>hotel.detail.sizeOfRoom</TextCus></div>}
                        {/* {roomtype?.freeCancellation && <div style={{ marginBottom: 6 }}>Miễn phí hủy đến: {dayjs(roomtype?.freeCancellationDate).format('DD/MM/YYYY')}</div>} */}
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={12}
                        lg={12}
                        xl={16}
                        xxl={16}
                        style={{ paddingRight: 0 }}
                    >
                        {/* <div style={{ ...styles.categoryName, marginBottom: 6 }}>Thông tin</div> */}
                        {/* <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                            <div style={{ marginTop: 5 }}><Icons.checkFillIcon /></div>
                            <span>{t('hotel.detail.freeCancellation')} {roomtype.freeCancellation ? `${t('base.yes')}` : `${t('base.no')}`}</span>
                        </div> */}
                        {/* <div style={{ marginBottom: 6 }}>
                            {roomtype?.benefits?.map((benefit: IBenefit) => (
                                <div key={benefit.id} style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                    <div style={{ marginTop: 5 }}><Icons.checkFillIcon /></div>
                                    <span>{benefit?.translatedBenefitName}</span>
                                </div>
                            ))}
                        </div> */}
                        {
                            roomtype?.rateBenefitInfos?.map((block, idx) => (
                                <>
                                    <BlockItem
                                        key={block.blockId}
                                        blockData={block}
                                        selectedRoom={selectedRoom}
                                        onSelected={handleSelected}
                                        main={main}
                                    />

                                    {roomtype?.rateBenefitInfos?.length - 1 !== idx &&
                                        <Divider dashed type='horizontal' style={{ borderWidth: '1px 0 0', margin: '12px 0' }} />
                                    }
                                </>
                            ))
                        }
                    </Col>
                    {/* <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={24}
                        xl={7}
                        xxl={7}
                    >
                        <Row justify='end' align='middle' gutter={[12, 12]}>
                            <Col
                                span={24}
                                style={{ padding: 0 }}
                            >
                                <Row justify={'end'} align={'middle'} >
                                    <TextCus
                                        replace={{
                                            0: roomtype?.remainingRooms
                                        }}
                                        style={{ ...styles.categoryName }}>
                                        hotel.remainingRooms
                                    </TextCus>
                                </Row>
                            </Col>
                            <Col
                                span={24}
                                style={{ textAlign: 'right', padding: 0 }}
                            >
                                <div style={{ ...styles.roomPrice, textAlign: 'right' }}>{`${roomtype?.rate?.inclusive?.toLocaleString()} ${roomtype?.rate?.currency}`}</div>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end', padding: 0
                                }}
                            >
                                <div style={roomtype?.enumRoomtypeStatus === 1 ? styles.roomStatusOnRequest : styles.roomStatusInstant}>
                                    {roomtype?.enumRoomtypeStatus === 1 ? (
                                        <>
                                            <Icons.hourGlassIcon />
                                            <span>On request</span>
                                        </>

                                    ) : (
                                        <>
                                            <Icons.flashIcon />
                                            <span>Instant</span>
                                        </>
                                    )}
                                </div>
                            </Col> */}
                    {/* <Col
                            span={24}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <div style={styles.roomCompare}>-{roomtype?.ratePerDays?.[0]?.promoCompare}% so với Agoda</div>
                        </Col> */}
                    {/* </Row> */}
                    {/* </Col> */}
                </Row>
            </div >
            <DetailRoom
                open={detailModal}
                onClose={() => setDetailModal(false)}
                selectedRoom={{
                    ...roomtype,
                    ...roomtype?.rateBenefitInfos?.[0]
                }}
            />
        </>
    )
}
export default memo(RoomItem, areEqual)