import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getNewsTypeRequest, requestSuccess, requestFailed } from '../reducer'
import { getNewsType } from '../../services';

export const getNewsTypeNotifier = (params: any = {},onSuccess, onFailure) => {
    return {
        type: getNewsTypeRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getNewsTypeAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: getNewsType,
        params: payload,
        cancelId: type,
        failureAction: requestFailed,
        successAction: requestSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}