import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Dropdown, Row, Select } from "antd";
import { createStyles } from "./styles";
import { theme } from "theme";
import USFlag from 'assets/images/US.png';
import VNFlag from 'assets/images/VN.png';
import Avatar from "assets/images/Avatar.png";
import Icons from "assets/icons";
import { NotiContext, TextCus } from "components";
import { useConfig, useUser } from "hooks";
import { t } from "i18next";
import LoginPopup from "pages/base/view/loginPopup";
import Hook from "pages/base/hook";
import './styles.css'
import { useNavigate } from "react-router-dom";

const UserHeader = () => {
    const styles = createStyles();
    const { isUserLogin, userInfo, logout } = useUser();
    const { language, currency, setCurrentCurrency, setCurrentLanguage } = useConfig()
    const { noti } = useContext(NotiContext) as any
    const [loginPopup, setLoginPopup] = useState(false)
    const { balanceInfo, getBalance } = Hook()
    const navigate = useNavigate()
    const items = useMemo(() => [
        // {
        //     key: 'profile',
        //     label:
        //         <Row align='middle'>
        //             <Icons.settingIcon />
        //             <TextCus style={{ marginLeft: 10 }}>base.profile</TextCus>
        //         </Row>
        // },
        // {
        //     key: 'bookings',
        //     label:
        //         <Row align='middle'>
        //             <Icons.calendarIcon />
        //             <TextCus style={{ marginLeft: 10 }}>base.bookingsList</TextCus>
        //         </Row>
        // },
        {
            key: 'balance',
            label:
                <Row align='middle'>
                    <Icons.moneyIcon color={theme.background.primary} />
                    <TextCus style={{ marginLeft: 10 }}>base.balance</TextCus>
                    :&nbsp;
                    <TextCus style={{ color: theme.background.blue_600 }}>{`${balanceInfo?.amount?.toLocaleString() ?? 0} đ`}</TextCus>
                </Row>
        },
        {
            key: 'logout',
            label:
                <Row align='middle'>
                    <Icons.logoutIcon />
                    <TextCus style={{ marginLeft: 10 }}>base.logout</TextCus>
                </Row>
        }
    ], [balanceInfo?.amount])

    const handleClick = useCallback(({ key }) => {
        switch (key) {
            case 'profile':
                location.replace('/profile')
                break;
            case 'balance':
                navigate('debt-manager')
                break;
            case 'bookings':
                navigate('bookings')
                break;
            case 'logout':
                logout()
                break;
            default:
                break;
        }
    }, [logout, location])

    useEffect(() => {
        if (isUserLogin) {
            getBalance({})
        }
    }, [isUserLogin])


    return (
        <>
            {isUserLogin ?
                <Col
                    lg={8}
                    xl={7}
                    xxl={6}
                >
                    <Row justify='end' gutter={[10, 10]} style={{ marginRight: 50 }}>
                        <Col
                            style={styles.col}
                        >
                            <Badge
                                count={noti?.length || 0}
                                size='small'
                                color={theme.background.third}
                                styles={styles.badge}
                                showZero
                            >
                                <Icons.bellFillIcon />
                            </Badge>
                        </Col>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={3}
                            style={styles.col}
                        >
                            <Select
                                variant="borderless"
                                style={{ width: 'fit-content' }}
                                dropdownStyle={{ width: 'fit-content', zIndex: 9999 }}
                                defaultValue={currency ?? 'VND'}
                                options={[
                                    {
                                        label: 'VND',
                                        value: 'VND',
                                    },
                                    {
                                        label: 'USD',
                                        value: 'USD',
                                    }
                                ]}
                                suffixIcon={<Icons.dropDownIcon />}
                                onSelect={(value) => setCurrentCurrency(value)}
                            />
                        </Col>
                        <Col
                            lg={4}
                            xl={4}
                            xxl={3}
                            style={styles.col}
                        >
                            <Select
                                variant="borderless"
                                className="select-language"
                                dropdownStyle={{ width: 'fit-content', zIndex: 9999 }}
                                defaultValue={language ?? 'vi'}
                                options={[
                                    {
                                        label: <img src={USFlag} style={{ width: 24, height: 16 }} />,
                                        value: 'en',
                                    },
                                    {
                                        label: <img src={VNFlag} style={{ width: 24, height: 16 }} />,
                                        value: 'vi',
                                    }
                                ]}
                                suffixIcon={<Icons.dropDownIcon />}
                                onChange={(value) => setCurrentLanguage(value)}
                            />
                        </Col>
                        <Dropdown
                            placement='bottom'
                            overlayStyle={{
                                zIndex: 9999,
                                width: 'fit-content',
                            }}
                            menu={{
                                items,
                                onClick: handleClick,
                            }}
                        >
                            <Col
                                style={styles.col}
                            >
                                <Row align='middle' >
                                    <Badge
                                        dot
                                        size="small"
                                        color="green"
                                        offset={[-5, 30]}
                                        styles={{
                                            root: {
                                                display: 'flex',
                                            }
                                        }}
                                    >
                                        <img src={userInfo?.avatar ?? Avatar} style={{ width: 30, height: 30, borderRadius: 50 }} />
                                    </Badge>
                                    <TextCus
                                        style={{ marginLeft: 10 }}
                                    >
                                        {`${userInfo?.lastName ?? ''} ${userInfo?.name ?? ''}`}
                                    </TextCus>
                                    <Icons.dropDownIcon />
                                </Row>
                            </Col>
                        </Dropdown>
                    </Row>
                </Col>
                :
                <Col
                    span={8}
                    style={{ height: 'fit-content' }}
                >
                    <Row justify={'end'} style={{ height: 'fit-content' }}>
                        <Button
                            type='primary'
                            onClick={() => setLoginPopup(true)}
                        >
                            {t('base.login')}
                        </Button>
                        <Button
                            style={{ marginLeft: 10, marginRight: 10 }}
                            onClick={() => location.replace('/#register')}
                        >
                            {t('base.register')}
                        </Button>
                    </Row>
                </Col>
            }
            <LoginPopup
                open={loginPopup}
                onClose={() => setLoginPopup(false)}
            />
        </>
    );
};

export default memo(UserHeader);