import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getBookingDetailRequest, getBookingDetailSuccess, getBookingDetailFailed } from '../reducer'
import { getBookingDetail } from '../../services';

export const getBookingDetailNotifier = (params: any = {},onSuccess, onFailure) => {
    return {
        type: getBookingDetailRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getBookingDetailAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: getBookingDetail,
        params: payload,
        cancelId: type,
        failureAction: getBookingDetailFailed,
        successAction: getBookingDetailSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}