/** @format */

import instance from 'utils/axios';
import { ISearchTour, ISegment, ITour } from '../types';

const getSegments = async (params: ISegment) => {
  const stringifyParams = Object.entries(params)
    .map(([key, value]) => (value ? `${key}=${value}` : ''))
    .filter(i => i)
    .join('&');
  return await instance.get(
    `${process.env.REACT_APP_BOOKING_BASE_URL}/segments?${stringifyParams}`,
  );
};

const searchTour = async (params: ISearchTour) => {
  return await instance.post(`${process.env.REACT_APP_BOOKING_BASE_URL}/tours/search`, params);
};

const getTourDetail = async (params: ITour) => {
  return await instance.post(
    `${process.env.REACT_APP_BOOKING_BASE_URL}/tours/search/${params?.guid}`,
    params,
  );
};

const tourBooking = async (params: ITour) => {
  return await instance.post(`${process.env.REACT_APP_BOOKING_BASE_URL}/tours/bookings`, params);
};

const getBookingDetail = async params => {
  console.log('trigger getBookingDetail with guid: ', params.guid);
  return await instance.get(
    `${process.env.REACT_APP_BOOKING_BASE_URL}/tours/bookings/${params.guid}`,
  );
};

// Get Frequency questions
const getNewsType = async params => {
  return await instance.get(
    `${process.env.REACT_APP_NEWS_BASE_URL}/newstype/${params.newsTypeName}/news-type-name`,
  );
};

const getAllQuestion = async params => {
  return await instance.post(`${process.env.REACT_APP_NEWS_BASE_URL}/news/getall`, params);
};

export {
  searchTour,
  getSegments,
  getTourDetail,
  tourBooking,
  getBookingDetail,
  getAllQuestion,
  getNewsType,
};
