import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { areEqual } from "utils/libs";
import createStyles from "../styles";
import { Button, Col, DatePicker, Divider, Row, Spin } from "antd";
import Hook from "pages/Hotel/hook";
import { t } from "i18next";
import { theme } from "theme";
import OffersPopup from "./OffersPopup";
import { stepContext } from "pages/Hotel/view/Hotel";
import { STEP } from "pages/Hotel/types";
import dayjs from "dayjs";
import 'dayjs/locale/vi'
import locale from "antd/es/date-picker/locale/vi_VN";
import RoomItem from "./RoomItem";
import { useConfig, useUser } from "hooks";
import LoginPopup from "pages/base/view/loginPopup";
import Icons from "assets/icons";
import { TextCus } from "components";
import { languageCons } from "utils/constants/local";
import PersonFilter from "../../List/PersonFilter";
dayjs.locale('vi')


const { RangePicker } = DatePicker;

const Roomtypes: FC = () => {
    const styles = createStyles()
    const { isUserLogin } = useUser()
    const { currency, language } = useConfig()
    const { isLoadingdetail, getRoomtypes, roomtypesData } = Hook()
    const [showOffers, setShowOffers] = useState(false);
    const [isLoginPopup, setIsLoginPopup] = useState(false);
    const { handleDataStep, setStep } = useContext(stepContext) as any
    const guid = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'guidView'), [handleDataStep])
    // const overview = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'overview'), [handleDataStep])
    const checkindt = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'checkindt'), [handleDataStep])
    const checkoutdt = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'checkoutdt'), [handleDataStep])
    const searchParams = useMemo(() => handleDataStep('GET', STEP.LIST, 'searchParams'), [handleDataStep])
    const setSearchParams = useCallback((value) => handleDataStep('SET', STEP.LIST, 'searchParams', value), [handleDataStep])
    const numberOfRoom = useMemo(() => searchParams?.Occupancy?.count, [searchParams])
    const selectedRoom = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'selectedRoom'), [handleDataStep])
    const totalPayment = useMemo(() => selectedRoom?.totalPayment, [selectedRoom])
    const setSelectedRoom = useCallback((data) => handleDataStep('SET', STEP.DETAIL, 'selectedRoom', data), [handleDataStep])

    const handleBooking = useCallback(() => {
        if (!isUserLogin) {
            setIsLoginPopup(true)
            return
        }
        setStep(STEP.PAYMENT)
        const room = roomtypesData?.property?.rooms?.find(_room => _room?.roomId === selectedRoom?.roomId)
        const block = room?.rateBenefitInfos?.find(_block => _block?.blockId === selectedRoom?.blockId)
        handleDataStep('SET', STEP.PAYMENT, undefined, old => ({
            ...old,
            method: null,
            roomtypeSelected: selectedRoom,
            roomtype: {
                ...room,
                ...block,
                searchId: roomtypesData?.searchId,
                occupancy: searchParams?.Occupancy,
            }
        }))
    }, [handleDataStep, selectedRoom, setStep, roomtypesData, isUserLogin, setIsLoginPopup, searchParams])

    const handleAddToCart = useCallback(() => {
        if (!isUserLogin) {
            setIsLoginPopup(true)
            return
        }
        setShowOffers(true)
    }, [setShowOffers, isUserLogin])
    const getDataRoomType = useCallback(() => {
        getRoomtypes({
            guid,
            checkindt: dayjs(checkindt).format('YYYY-MM-DD'),
            checkoutdt: dayjs(checkoutdt).format('YYYY-MM-DD'),
            currency,
            language: languageCons[language],
            occupancy: {
                count: searchParams?.Occupancy?.Count,
                adults: searchParams?.Occupancy?.Adults,
                children: searchParams?.Occupancy?.Children,
                childAges: searchParams?.Occupancy?.childAges
            },
            nationalityIso2: searchParams?.NationalityIso2,
            markup: searchParams?.markup,
        })
    }, [checkindt, checkoutdt, currency, language, searchParams, getRoomtypes])

    useEffect(() => {
        guid && roomtypesData?.guid !== guid && getDataRoomType()
    }, [guid])

    useEffect(() => {
        return () => {
            setSelectedRoom(null);
            handleDataStep('SET', STEP.DETAIL, 'selectedRoomData', null);
        };
    }, []);

    const roomTypes = useMemo(() => {
        const data: any[] = []
        const parentParams = {
            searchId: roomtypesData?.searchId,
            hotelId: roomtypesData?.property?.propertyId,
            hotelGuid: roomtypesData?.hotelGuid,
            currency: searchParams?.currency,
            occupancy: {
                count: searchParams?.Occupancy?.Count,
                adults: searchParams?.Occupancy?.Adults,
                children: searchParams?.Occupancy?.Children,
                childAges: searchParams?.Occupancy?.childAges
            },
            nationalityIso2: searchParams?.NationalityIso2,
        }
        roomtypesData?.property?.rooms?.forEach((room) => {
            const common = {
                bedType: room?.bedType,
                providerName: room?.providerName,
                guid: room?.guid,
                maxOccupancyPerRoom: room?.maxOccupancyPerRoom,
                hotelRoomtypeId: room?.roomId,
                roomName: room?.roomName,
                roomPictureURL: room?.roomPictureURL,
                sizeOfRoom: room?.sizeOfRoom,
                translatedRoomName: room?.translatedRoomName,
                numberOfRoom,
            }
            data.push({
                ...room,
                ...parentParams,
                ...common,
            })
        })
        return data
    }, [JSON.stringify(roomtypesData), numberOfRoom, JSON.stringify(searchParams)])

    return (
        <>
            <Row justify='start' align='middle' gutter={[12,12]} style={{ marginBottom: 12, marginTop: 12 }}>
                <Col
                    sm={24}
                    xs={24}
                    md={24}
                    lg={24}
                    xl={11}
                    xxl={10}
                >
                    <RangePicker
                        separator="-"
                        locale={locale}
                        size='small'
                        suffixIcon={<Icons.calendarIcon />}
                        style={styles.datepicker}
                        value={[dayjs(searchParams.CheckinDT), dayjs(searchParams.CheckoutDT)]}
                        format={'ddd, DD/MM/YY'}
                        clearIcon={false}
                        order={false}
                        disabledDate={(current, { from }) => {
                            if (from) {
                                return current.isSame(from, 'day') || current.isBefore(from, 'day');
                            }
                            return current.isBefore(dayjs().subtract(1, 'day'), 'day');
                        }}

                        onChange={(value) => {
                            const convertDate = (date) => dayjs(dayjs(date).format('DD/MM/YYYY'), 'DD/MM/YYYY').format()
                            setSearchParams(old => ({
                                ...old,
                                CheckinDT: value?.[0] ? convertDate(value[0]) : old.CheckinDT,
                                CheckoutDT: value?.[1] ? convertDate(value[1]) : old.CheckoutDT
                            }))
                        }}
                    />
                </Col>
                <Col
                    sm={24}
                    xs={24}
                    md={24}
                    lg={12}
                    xl={8}
                    xxl={8}
                >
                    <PersonFilter
                        value={searchParams?.Occupancy}
                        setValue={(func) => setSearchParams(old => ({ ...old, Occupancy: func(old.Occupancy) }))}
                    />
                </Col>
                <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={5}
                        xxl={5}
                    >
                        <Button
                            block
                            type='primary'
                            style={styles.btn_Search}
                            loading={isLoadingdetail}
                            onClick={getDataRoomType}
                        >
                            {t('hotel.search')}
                        </Button>
                    </Col>
            </Row>
            <Spin spinning={isLoadingdetail}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 12, margin: '12px 0' }}>
                    {roomTypes?.map((roomtype, idx) => (
                        <RoomItem
                            key={idx}
                            index={idx}
                            roomtype={roomtype}
                            selectedRoom={selectedRoom}
                            onSelected={setSelectedRoom}
                        />
                    ))}
                    {roomTypes?.length === 0 ?
                        <div style={{ padding: 6 }}>
                            <TextCus>hotel.detail.emptyData</TextCus>
                        </div> : <div style={{ position: 'sticky', bottom: 0, background: 'white' }}>
                            <Divider />
                            <div style={{ ...styles.titleSecondary, textAlign: 'right' }}>{t('hotel.totalPrice', { price: totalPayment?.toLocaleString() ? totalPayment?.toLocaleString() : 0, currency })}</div>
                            <div style={{ ...styles.titleSecondary, textAlign: 'right', fontWeight: 400, fontSize: 16 }}>{t('hotel.totalPriceSub')}</div>
                            <Row justify='end' align='middle' gutter={[12, 12]} style={{ paddingBottom: 6 }}>
                                <Col
                                    span={12}
                                >
                                    <Button
                                        block
                                        type='primary'
                                        disabled={!selectedRoom}
                                        onClick={handleBooking}
                                    >
                                        {t('hotel.bookNow')}
                                    </Button>
                                </Col>
                                <Col
                                    span={12}
                                >
                                    <Button
                                        block
                                        style={{
                                            color: theme.text.secondary,
                                            border: `1px solid ${theme.text.secondary}`,
                                        }}
                                        onClick={handleAddToCart}
                                    >
                                        {t('hotel.addToCart')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>}
                </div>
            </Spin>
            <OffersPopup
                open={showOffers}
                onClose={() => setShowOffers(false)}
            />
            <LoginPopup
                open={isLoginPopup}
                onClose={() => setIsLoginPopup(false)}
            />
        </>
    );

}
export default memo(Roomtypes, areEqual)