import React, { FC, memo, useCallback, useMemo } from "react";
import { Col, Input, Popover, Row } from "antd";
import Icons from 'assets/icons';
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { t } from "i18next";
import { InputNumberCus, TextCus } from "components";

interface IProps {
    value?: any
    setValue?: any
}

const PersonFilter: FC<IProps> = ({
    value,
    setValue
}) => {
    const styles = createStyles();

    const handleChangeChildAge = useCallback((idx) => (_val) => {
        setValue(old => {
            const childAges = old?.childAges?.map((item, index) => index === idx ? _val : item)
            return {
                ...old,
                childAges
            }
        })
    }, [setValue])

    const handleChangeChild = useCallback((_val) => {
        setValue(old => ({
            ...old,
            Children: _val,
            childAges: old?.childAges?.length < _val ? [...old?.childAges ?? [], 1] : old?.childAges?.slice(0, _val)
        }))
    }, [setValue])

    const content = useMemo(() =>
        <>
            <div
                style={styles.personFilterContainer as any}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 12,
                        height: 'fit-content'
                    }}
                >
                    {/* <TextCus
                        style={styles.titleRoom}
                    >
                        {t('hotel.roomCount', { 0: val?.Count })}
                    </TextCus> */}
                    <Row
                        justify={'space-between'}
                        align={'middle'}
                        style={{ gap: 6 }}
                        gutter={[12, 12]}

                    >
                        <Col>
                            <Row
                                align={'middle'}
                                style={{ gap: 6 }}>
                                <Icons.bedIcon />
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <TextCus
                                        style={styles.titlePerson}
                                    >
                                        hotel.rooms
                                    </TextCus>
                                </Col>
                            </Row>
                        </Col>
                        <InputNumberCus
                            button
                            defaultValue={value?.Count}
                            min={1}
                            onChange={(_val) => setValue(old => {
                                const Adults = old.Adults < (_val as number) ? _val as number : old.Adults
                                return {
                                    ...old,
                                    Count: _val as number,
                                    Adults
                                }
                            })}
                        />
                    </Row>
                    <Row
                        justify={'space-between'}
                        align={'middle'}
                        style={{ gap: 6 }}
                        gutter={[12, 12]}

                    >
                        <Col>
                            <Row
                                align={'middle'}
                                style={{ gap: 6 }}>
                                <Icons.adultIcon />
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <TextCus
                                        style={styles.titlePerson}
                                    >
                                        {t('hotel.adult')}
                                    </TextCus>
                                </Col>
                            </Row>
                        </Col>
                        <InputNumberCus
                            button
                            defaultValue={value?.Adults}
                            min={value?.Count}
                            onChange={(_val) => setValue(old => ({ ...old, Adults: _val as number }))}
                        />
                    </Row>
                    <Row
                        justify={'space-between'}
                        align={'middle'}
                        style={{ gap: 6 }}
                        gutter={[12, 12]}

                    >
                        <Col>
                            <Row
                                align={'middle'}
                                style={{ gap: 6 }}>
                                <Icons.childIcon />
                                <Col
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <TextCus
                                        style={styles.titlePerson}
                                    >
                                        {t('hotel.child')}
                                    </TextCus>
                                </Col>
                            </Row>
                        </Col>
                        <InputNumberCus
                            button
                            defaultValue={value?.Children}
                            onChange={handleChangeChild}
                        />
                    </Row>
                    {Array.from(Array(value?.Children).keys()).map((idx) => (
                        <Row
                            key={idx}
                            justify={'space-between'}
                            align={'middle'}
                            style={{ gap: 6 }}
                            gutter={[12, 12]}

                        >
                            <Col>
                                <TextCus
                                    style={styles.titlePerson}
                                >
                                    {t('hotel.ageOfchild', { child: idx + 1 })}
                                </TextCus>
                            </Col>
                            <InputNumberCus
                                button
                                defaultValue={0}
                                max={17}
                                min={0}
                                // onChange={(val) => setVal(old => old.map((item) => item?.Rooms === it?.Rooms ? { ...item, childAges:[] } : item))}
                                onChange={handleChangeChildAge(idx)}
                            />
                        </Row>
                    ))}
                </div>
            </div>
        </>
        , [JSON.stringify(value), setValue])

    return (
        <Popover
            arrow={false}
            content={content}
            trigger={'click'}
            placement='bottom'
        >
            <Input
                suffix={<Icons.userIcon />}
                style={{ ...styles.input, cursor: 'pointer' }}
                readOnly
                value={t('hotel.person', { 0: value?.Adults + value?.Children, 1: value?.Count })}
            />
        </Popover>
    )
}
export default memo(PersonFilter, areEqual);