import React, { FC, memo, useContext } from 'react';
import { areEqual } from 'utils/libs';
import Filter from './Filter';
//import Result from './Result';
import { useCommon } from 'hooks';

import { STEP } from 'pages/Hotel/types';
import { stepContext } from '../../Hotel';

const List: FC = () => {
    const { step } = useContext(stepContext) as any
    const { isSmallScreen } = useCommon()

    
    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        flex: 'initial',
                        transition: 'all 0.3s ease-in-out',
                        visibility: (step !== STEP.DETAIL || isSmallScreen) ? 'visible' : 'hidden',
                        height: (step !== STEP.DETAIL || isSmallScreen) ? 'unset' : '0',
                        width: (step !== STEP.DETAIL || isSmallScreen) ? 'unset' : '0',
                    }} >
                    <Filter />
                </div>
                {/* <Result /> */}
            </div>
        </>
    );
}
export default memo(List, areEqual);