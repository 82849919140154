import { createSlice } from '@reduxjs/toolkit';

interface IHotelState {
    loading: boolean;
    error: string | null;
    message: string | null;
    data: any[];
    total: number;
    locationLoading: boolean;
    loadingDetail: boolean;
    overview: any
    facilities: any
    roomTypes: any
    policies: any
    bookingLoading?: boolean
    cancelBookingLoading?: boolean
    addCartLoading?: boolean
    bookingDetail?: any
    accommodation?: any[]
    property?: any[]
    chainBrands?: any[]
}
const initialState: IHotelState = {
    loading: false,
    error: null,
    message: null,
    data: [],
    total: 0,
    locationLoading: false,
    loadingDetail: false,
    overview: null,
    facilities: null,
    roomTypes: null,
    policies: null,
    bookingLoading: false,
    cancelBookingLoading: false,
    addCartLoading: false,
    bookingDetail: null,
    accommodation: [],
    property: [],
    chainBrands: [],
}

const hotelsSlice = createSlice({
    name: 'hotel',
    initialState,
    reducers: {
        requestSuccess: (state) => ({
            ...state,
            loading: false,
            loadingDetail: false,
        }),
        requestFailed: (state, action) => ({
            ...state,
            loading: false,
            loadingDetail: false,
            error: action.payload?.errorMessages,
        }),
        getRequest: (state, action) => ({
            ...state,
            loading: true,
            data: action.payload.extraParams.clearPage ? [] : state?.data,
        }),
        getSuccess: (state, action) => ({
            ...state,
            loading: false,
            data: action.payload.extraParams.clearPage
                ? action.payload?.items : [...state.data, ...(action.payload?.items || [])],
            total: action.payload?.pagingInfo?.totalItems,
        }),
        getFailed: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload?.errorMessages,
        }),
        getLocationsRequest: (state) => ({
            ...state,
            locationLoading: true,
        }),
        getLocationsSuccess: (state) => ({
            ...state,
            locationLoading: false,
        }),
        getLocationsFailed: (state) => ({
            ...state,
            locationLoading: false,
        }),
        getOverviewRequest: (state) => ({
            ...state,
            loadingDetail: true,
            overview: null
        }),
        getOverviewSuccess: (state, action) => ({
            ...state,
            loadingDetail: false,
            overview: action.payload
        }),
        getOverviewFailed: (state) => ({
            ...state,
            loadingDetail: false,
            overview: null
        }),
        getFacilitiesRequest: (state) => ({
            ...state,
            loadingDetail: true,
            facilities: null
        }),
        getFacilitiesSuccess: (state, action) => ({
            ...state,
            loadingDetail: false,
            facilities: action.payload
        }),
        getFacilitiesFailed: (state) => ({
            ...state,
            loadingDetail: false,
            facilities: null
        }),
        getRoomtypesRequest: (state) => ({
            ...state,
            roomTypes: null,
            loadingDetail: true,
        }),
        getRoomtypesSuccess: (state, action) => ({
            ...state,
            roomTypes: action.payload,
            loadingDetail: false,
        }),
        getRoomtypesFailed: (state) => ({
            ...state,
            roomTypes: null,
            loadingDetail: false,
        }),
        getPoliciesRequest: (state) => ({
            ...state,
            loadingDetail: true,
        }),
        getPoliciesSuccess: (state, payload) => ({
            ...state,
            loadingDetail: false,
            policies: payload.payload,
        }),
        getPoliciesFailed: (state) => ({
            ...state,
            loadingDetail: false,
            policies: null
        }),
        bookingRequest: (state) => ({
            ...state,
            bookingLoading: true,
        }),
        bookingSuccess: (state) => ({
            ...state,
            bookingLoading: false,
        }),
        bookingFailed: (state) => ({
            ...state,
            bookingLoading: false,
        }),
        cancelBookingRequest: (state) => ({
            ...state,
            cancelBookingLoading: true,
        }),
        cancelBookingSuccess: (state) => ({
            ...state,
            cancelBookingLoading: false,
        }),
        cancelBookingFailed: (state) => ({
            ...state,
            cancelBookingLoading: false,
        }),
        getBookingDetailRequest: (state,action) => ({
            ...state,
            loadingDetail: action?.payload?.extraParams?.refresh ? false : true,
        }),
        getBookingDetailSuccess: (state, payload) => ({
            ...state,
            loadingDetail: false,
            bookingDetail: payload.payload,
        }),
        getBookingDetailFailed: (state) => ({
            ...state,
            loadingDetail: false,
            bookingDetail: null
        }),
        getAccommodationRequest: (state) => ({
            ...state,
        }),
        getAccommodationSuccess: (state, payload) => {
            return ({
                ...state,
                accommodation: payload.payload,
            })
        },
        getAccommodationFailed: (state) => ({
            ...state,
            accommodation: [],
        }),
        getPropertyRequest: (state) => ({
            ...state,
        }),
        getPropertySuccess: (state, payload) => ({
            ...state,
            property: payload.payload,
        }),
        getPropertyFailed: (state) => ({
            ...state,
            property: [],
        }),
        getChainBrandsRequest: (state) => ({
            ...state,
            loadingDetail: true,
        }),
        getChainBrandsSuccess: (state, payload) => ({
            ...state,
            chainBrands: payload.payload,
            loadingDetail: false,
        }),
        getChainBrandsFailed: (state) => ({
            ...state,
            chainBrands: [],
            loadingDetail: false,
        }),
        addCartRequest: (state) => ({
            ...state,
            addCartLoading: true,
        }),
        addCartSuccess: (state) => ({
            ...state,
            addCartLoading: false,
        }),
        addCartFailed: (state) => ({
            ...state,
            addCartLoading: false,
        }),
        paymentBookingRequest: (state) => ({
            ...state,
            bookingLoading: true,
        }),
        paymentBookingSuccess: (state) => ({
            ...state,
            bookingLoading: false,
        }),
        paymentBookingFailed: (state) => ({
            ...state,
            bookingLoading: false,
        }),
    }
});
const { actions, reducer } = hotelsSlice;

export const {
    requestSuccess,
    requestFailed,
    getRequest,
    getSuccess,
    getFailed,
    getLocationsRequest,
    getLocationsSuccess,
    getLocationsFailed,
    getOverviewRequest,
    getOverviewSuccess,
    getOverviewFailed,
    getFacilitiesRequest,
    getFacilitiesSuccess,
    getFacilitiesFailed,
    getRoomtypesRequest,
    getRoomtypesSuccess,
    getRoomtypesFailed,
    getPoliciesRequest,
    getPoliciesSuccess,
    getPoliciesFailed,
    bookingRequest,
    bookingSuccess,
    bookingFailed,
    cancelBookingRequest,
    cancelBookingSuccess,
    cancelBookingFailed,
    getBookingDetailRequest,
    getBookingDetailSuccess,
    getBookingDetailFailed,
    getAccommodationRequest,
    getAccommodationSuccess,
    getAccommodationFailed,
    getPropertyRequest,
    getPropertySuccess,
    getPropertyFailed,
    getChainBrandsRequest,
    getChainBrandsSuccess,
    getChainBrandsFailed,
    addCartRequest,
    addCartSuccess,
    addCartFailed,
    paymentBookingRequest,
    paymentBookingSuccess,
    paymentBookingFailed
} = actions;

export default reducer;
export type {
    IHotelState,
}
