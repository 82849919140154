import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "antd";
import { t } from "i18next";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { TextCus } from "components";
import RoomInfo from "./RoomInfo";
import HotelInfo from "./HotelInfo";
import CustomerInfo from "./CustomerInfo";
import TimeInfo from "./TimeInfo";
import PolicyInfo from "./PolicyInfo";
import Icons from "assets/icons";
import { stepContext } from "../../Hotel";
import { STEP } from "pages/Hotel/types";
import Summary from "./Summary";
import dayjs from "dayjs";
import Hook from "pages/Hotel/hook";
import { useNotify } from "hooks";
// import ModalSucces from "./ModalSucces";
import { LayoutContext } from "../../Layout/Layout";
import PaymentMethod from "./PaymentMethod";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { theme } from "theme";

const PaymentForm: FC = () => {
    const styles = createStyles();
    const navigate = useNavigate()
    const [methodPopup, setMethodPopup] = useState(false)
    const { success, error, warning } = useNotify()
    const { booking } = Hook()
    const [form] = Form.useForm()
    const { handleDataStep, setStep } = useContext(stepContext) as any
    const { setLayoutProps } = useContext(LayoutContext) as any
    const roomtype = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'roomtype'), [handleDataStep])
    const checkInDT = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'checkindt'), [handleDataStep])
    const checkOutDT = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'checkoutdt'), [handleDataStep])
    const paymentHotelType = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'method'), [handleDataStep])

    const handleBack = useCallback(() => {
        setStep(STEP.DETAIL)
    }, [setStep])

    useEffect(() => {
        setLayoutProps({
            isShow: true,
            handleBack,
        })
    }, [handleBack])

    const handleSubmit = useCallback((values) => {
        if (!paymentHotelType) {
            setMethodPopup(true)
            return
        }
        const payload = {
            paymentHotelType,
            blockId: roomtype.blockId,
            searchId: roomtype.searchId,
            stayingCutomers: values.customerInfo.map((customer) => ({
                ...customer,
                phoneNumber: `${customer.phoneCode}${customer.phoneNumber}`,
                dob: customer.dob.format('YYYY-MM-DD'),
            })),
        }
        booking(payload, (res) => {
            navigate(`/hotels/detail/${res?.bookingGuid}`)
        },
            (res) => {
                if (res?.errorMessages?.[0]?.errorCode === 'ERRCH01') {
                    warning({
                        message: t(`base.notification.${res?.errorMessages?.[0]?.errorCode}`)
                    })
                    return
                }
                error({
                    title: 'Đặt phòng thất bại',
                    message: res?.errorMessages[0]?.errorMessage
                })
            }
        )
    }, [JSON.stringify(roomtype), paymentHotelType, booking, success, error, setMethodPopup])



    return (
        <>
            <div
                style={{
                    display: 'flex',
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                    padding: '12px',
                    justifyContent: 'center',
                }}>
                <Row justify='center' style={{ height: '100%', width: '100%', maxWidth: '1366px', gap: 12 }}>
                    <Col span={24} style={styles.col as any} >
                        <TextCus style={styles.title}>{t('hotel.payment_fill_form')}</TextCus>
                        <RoomInfo />
                        <HotelInfo />
                        <Form
                            form={form}
                            scrollToFirstError={{behavior: 'smooth', block: 'center', inline: 'center'}}
                            initialValues={{
                                checkInDT: dayjs(checkInDT),
                                checkOutDT: dayjs(checkOutDT),
                                customerInfo: [{
                                    isMainContact: true,
                                    fullName: undefined,
                                    pin: '',
                                    dob: '',
                                    phoneNumber: '',
                                    email: ''
                                }]
                            }}
                            layout='vertical'
                            requiredMark={false}
                            onFinish={handleSubmit}
                            style={{ width: '100%', padding: 0 }}
                        >
                            <CustomerInfo
                                key={''}
                                index={''}
                                form={form}
                            />
                            <TimeInfo />
                            <PolicyInfo />
                        </Form>
                        <div
                            style={styles.attention}
                        >
                            <Row align='middle'>
                                <Col span={24}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icons.infoIcon /> <TextCus style={styles.attentionTitile}>{t('hotel.attention')}</TextCus>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div style={styles.attentionContent}>
                                        <TextCus style={{ color: `${theme.text.blue}` }}>
                                            {t('hotel.attention_content_date', { freeCancellationDate: roomtype.freeCancellationDate })}
                                        </TextCus>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div style={styles.attentionContent}>{t('hotel.attention_content_1')}</div>
                                </Col>
                            </Row>
                        </div>
                        <Summary form={form} total={roomtype?.totalPayment?.totalPrice} />
                    </Col>
                    <Col span={24}>
                        <Footer />
                    </Col>
                </Row>
            </div>
            <PaymentMethod open={methodPopup} onClose={() => setMethodPopup(false)} onOk={() => form.submit()} total={roomtype?.rate?.totalPrice} />
        </>
    );
};
export default memo(PaymentForm, areEqual);