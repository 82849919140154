import { useDispatch, useSelector } from "react-redux";
import {
    bookingNotifier,
    getAccommodationNotifier,
    getBookingDetailNotifier,
    getChainBrandsNotifier,
    getFacilitiesNotifier,
    getLocationsNotifier,
    getNotifier,
    getOverviewNotifier,
    getPropertyNotifier,
    getRoomtypesNotifier,
    addCartNotifier,
    cancelBookingNotifier,
    paymentBookingNotifier
} from "./store/actions";
import { accommodation, bookingDetail, chainBrands, data, facilities, loading, loadingBooking, loadingDetail, locLoading, overview, policies, property, roomtypes, total, addCarts, loadingAddCart, loadingCancelBooking } from "./store/selector";
import { getPoliciesNotifier } from "./store/actions/getPolicies";
import HookBase from "pages/base/hook";


export default function Hook() {
    const dispatch = useDispatch();
    const { getBalance } = HookBase();
    const isLoading = useSelector(loading) as boolean;
    const list = useSelector(data) as any[];
    const totalList = useSelector(total);
    const isLocationLoading = useSelector(locLoading);
    const isLoadingdetail = useSelector(loadingDetail);
    const isLoadingBooking = useSelector(loadingBooking);
    const isLoadingCancelBooking = useSelector(loadingCancelBooking);
    const overviewData = useSelector(overview) as any;
    const facilitiesData = useSelector(facilities) as any;
    const roomtypesData = useSelector(roomtypes) as any;
    const policiesData = useSelector(policies) as any;
    const addCartData = useSelector(addCarts) as any;
    const isLoadingAddCart = useSelector(loadingAddCart);

    const getHotelList = (params, onSuccess?, onFailure?) => {
        dispatch(
            getNotifier(params, onSuccess, onFailure))
    }
    const getLocations = (params, onSuccess?, onFailure?) => {
        dispatch(
            getLocationsNotifier(params, onSuccess, onFailure))
    }
    const getOverview = (params, onSuccess?, onFailure?) => {
        dispatch(
            getOverviewNotifier(params, onSuccess, onFailure))
    }
    const getFacilities = (params, onSuccess?, onFailure?) => {
        dispatch(
            getFacilitiesNotifier({ hotelId: params }, onSuccess, onFailure))
    }

    const getRoomtypes = (params, onSuccess?, onFailure?) => {
        dispatch(
            getRoomtypesNotifier(params, onSuccess, onFailure))
    }
    const getPolicies = (params, onSuccess?, onFailure?) => {
        dispatch(
            getPoliciesNotifier({ hotelId: params }, onSuccess, onFailure))
    }

    const booking = (params, onSuccess?, onFailure?) => {
        dispatch(
            bookingNotifier({ ...params, isAsync: true }, (res) => {
                getBalance({});
                onSuccess && onSuccess(res);
            }, onFailure))
    }
    const cancelBooking = (params, onSuccess?, onFailure?) => {
        dispatch(
            cancelBookingNotifier(params, (res) => {
                getBalance({});
                onSuccess && onSuccess(res);
            }, onFailure))
    }

    const getBookingDetail = (params, onSuccess?, onFailure?) => {
        dispatch(
            getBookingDetailNotifier(params, onSuccess, onFailure))
    }
    const bookingDetailData = useSelector(bookingDetail) as any;

    const getAccommodation = (params?, onSuccess?, onFailure?) => {
        dispatch(
            getAccommodationNotifier(params, onSuccess, onFailure))
    }
    const accommodationData = useSelector(accommodation) as any[];

    const getChainBrands = (params?, onSuccess?, onFailure?) => {
        dispatch(
            getChainBrandsNotifier(params, onSuccess, onFailure))
    }
    const chainBrandsData = useSelector(chainBrands) as any;

    const getProperty = (params?, onSuccess?, onFailure?) => {
        dispatch(
            getPropertyNotifier(params, onSuccess, onFailure))
    }
    const propertyData = useSelector(property) as any;

    const addCart = (params, onSuccess?, onFailure?) => {
        dispatch(
            addCartNotifier(params, onSuccess, onFailure))
    }
    const paymentBooking = (params, onSuccess?, onFailure?) => {
        dispatch(
            paymentBookingNotifier(params,
                (res) => {
                    getBookingDetail({ bookingId: res?.bookingGuid, extraParams: { refresh: true } })
                    onSuccess && onSuccess(res);
                }, onFailure))
    }

    return {
        isLoading,
        getHotelList,
        list,
        totalList,
        isLocationLoading,
        getLocations,
        getOverview,
        isLoadingdetail,
        overviewData,
        getFacilities,
        facilitiesData,
        getRoomtypes,
        roomtypesData,
        getPolicies,
        policiesData,
        booking,
        isLoadingBooking,
        cancelBooking,
        isLoadingCancelBooking,
        getBookingDetail,
        bookingDetailData,
        getAccommodation,
        accommodationData,
        getProperty,
        propertyData,
        getChainBrands,
        chainBrandsData,
        addCart,
        addCartData,
        isLoadingAddCart,
        paymentBooking
    }
}