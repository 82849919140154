import React from "react"
import { theme } from "theme"
import Icons from "assets/icons"

const STEP = {
    LIST: 'LIST',
    DETAIL: 'DETAIL',
    PAYMENT: 'PAYMENT',
    COMMON: 'COMMON',
    PAYMENTDETAIL: 'PAYMENTDETAIL',
    CART: 'CART',
}

interface ISearchHotel {
    EnumOperationName?: 0 | 1 | 2 | 3;
    EnumPlaceType?: 0 | 1 | 2 | 3 | undefined;
    ObjectGuid?: string;
    RadiusFromTheCenter?: 0 | 1 | 2 | 3;
    CheckinDT?: string;
    CheckoutDT?: string;
    NationalityIso2?: string;
    countryIso2Search?: string;
    Markup?: number;
    Occupancy: {
        Adults?: number;
        Children?: number;
        Count?: number;
        childAges?: number[];
    },
    Filter?: {
        SearchHotelSortBy: 0 | 1 | 2 | 3;
        PriceFrom?: number;
        PriceTo?: number;
        RatingAverage?: 0 | 1 | 2 | 3 | null;
        StarRatings?: number[];
        AccommodationTypeGuids?: string[];
        Offers?: number[];
        PropertyGroupGuids?: string[];
        Sustainabilities?: number[];
        BrandGuids?: string[];
        price: number[];
    },
    currency?: string;
    PageSize: number;
    Page: number;
    extraParams?: {
        clearPage?: boolean;
    }
}
interface IViewDetailState {
    guid?: string;
    data?: IHotel;
    checkindt?: string;
    checkoutdt?: string;
}

interface IHotel {
    guid: string;
    hotelId?: number;
    hotelName?: string;
    starRating?: number;
    cityId?: number;
    longitude: number;
    latitude: number;
    pictureUrl?: string;
    popularityScore?: number;
    ratingAverage?: number;
    numberOfReviews?: number;
    nationalityRestrictions?: string;
    distance?: number;
    distanceUnit?: string;
    price?: number;
    facilities?: {
        guid: string;
        propertyGroupDescription: string;
        propertyGroupDescriptionTranslated: string;
        urlIcon: string;
    }[];
    image?: string;
    offer?: (keyof typeof Key2Icon)[];
    addressLine1?: string;

}

interface IHotelDetailOverview {
    hotelName: string;
    starRating: number;
    popularityScore: number;
    numberOfReviews: number;
    ratingAverage: number;
    longitude: number;
    latitude: number;
    addresses: string;
    overview: string;
    urLs: string[];
    rankInCity: number;
    cityTranslated: string;
}


interface IBenefit {
    id: number;
    benefitName: string;
    translatedBenefitName: string;
}

export const offerContent =
{
    rate: 'Khách sạn 2 sao',
    'internet access': 'Wifi miễn phí',
    airConditioner: 'Điều hòa nhiệt độ',
    pet: 'Chấp nhận vật nuôi',
    'Dining, drinking, and snacking': 'Nhà hàng',
    kitchen: 'Có bếp nấu ăn',
    taxi: 'Xe đưa đón sân bay',
    wash: 'Dịch vụ giặt là',
    child: 'Phù hợp trẻ em',
}

export type {
    ISearchHotel,
    IHotel,
    IHotelDetailOverview,
    IViewDetailState,
    IBenefit
}

export interface IProperties {
    guid: string;
    propertyName?: string;
    propertyTranslatedName?: string;
    urlIcon: string;
}[]

export interface IPropertyGroups {
    guid: string;
    propertyGroupDescription: string;
    propertyGroupDescriptionTranslated: string;
    urlIcon: string;
    properties: IProperties[],
}[]

export interface IConvenientPlaces {
    categoryName: string;
    distance: number;
    distanceUnit: string;
    name: string;
}[]

export interface ILocalPlaces {
    localPlaceType: number;
    distance: number;
    distanceUnit: string;
    name: string;
}[]

export interface IHotelDetailFacilities {
    guid: string;
    convenientPlaces: IConvenientPlaces,
    localPlaces: ILocalPlaces,
    propertyGroups: IPropertyGroups,
}


export const Key2Icon = {
    rate: <Icons.bedIcon color={theme.text.black} width={16} height={17} />,
    'internet access': <Icons.wifiIcon />,
    airConditioner: <Icons.snowflakeIcon />,
    pet: <Icons.pawIcon />,
    'Dining, drinking, and snacking': <Icons.lunchIcon />,
    kitchen: <Icons.frigdeIcon />,
    taxi: <Icons.taxiFillIcon />,
    wash: <Icons.washFillIcon />,
    child: <Icons.strollerIcon />,
}

export {
    STEP
}