import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { Select, SelectProps, Tooltip } from "antd";
import { areEqual, debounce } from "utils/libs";

type IProps = SelectProps & {
    getData?: any
    data?: any[]
    displaySelect?: any
    valueField?: string
    displayField?: string
    paramsGet?: any
    handleSelect?: (value: any,option?:any) => void,
    searchField?: string
    tooltip?: string
}

const Combobox: FC<IProps> = ({
    getData,
    paramsGet,
    data,
    valueField = 'value',
    displayField = 'label',
    displaySelect = displayField,
    searchField = 'keywords',
    handleSelect,
    tooltip="",
    showSearch = true,
    ...props
}) => {
    const [options, setOptions] = useState<any[]>([])

    const handleSearch = useCallback((value) => {
        if (getData) {
            getData({
                ...paramsGet,
                [searchField]: value
            }, (res) => setOptions(
                res.map((item: any, index) => ({
                    key:index,
                    value: item[valueField],
                    label: item[displayField],
                    ...item
                }))
            ))
        }
    }, [getData, paramsGet, searchField, valueField, displayField])

    const optionRender = useCallback((item) => {
        if (typeof displaySelect === 'function') {
            return displaySelect(item?.data)
        }
        return item?.data?.[displaySelect]
    }, [displaySelect])


    useEffect(() => {
        if (data?.length) {
            setOptions(data.map((item: any, index) => ({
                key:index,
                value: item[valueField],
                label: item[displayField],
                ...item
            })))
        }
        if (getData) {
            getData(paramsGet, (res) => setOptions(
                res.map((item: any, index) => ({
                    key: index,
                    value: item[valueField],
                    label: item[displayField],
                    ...item
                }))
            ))
        }
    }, [JSON.stringify(data), JSON.stringify(getData), JSON.stringify(paramsGet), valueField, displayField])



    return (
        <Tooltip title={tooltip}>
            <Select
                {...props}
                showSearch={showSearch}
                options={[...options]}
                {...getData && { filterOption: false }}
                onSearch={debounce(handleSearch)}
                onFocus={() => handleSearch('')}
                onSelect={handleSelect}
                optionRender={optionRender}
            />
        </Tooltip>
    );
}
export default memo(Combobox, areEqual);