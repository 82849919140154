/** @format */

import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getNewstypeRequest, getNewstypeSuccess, getNewstypeFailed } from '../reducer';
import { getNewsType } from '../../services';

export const getNewstypeNotifier = (params: any = {}, onSuccess: any, onFailure: any) => {
  return {
    type: getNewstypeRequest.type,
    loader: true,
    payload: params,
    onSuccess,
    onFailure,
  };
};

export function* getNewstypeAction({ payload, type, ...props }: PayloadAction<any>) {
  yield call(request, {
    service: getNewsType,
    params: payload,
    cancelId: type,
    failureAction: getNewstypeFailed,
    successAction: getNewstypeSuccess,
    onSuccess: props['onSuccess'],
    onFailure: props['onFailure'],
  });
}
