import React, { FC, useState, MouseEvent } from 'react'
import { Col, Modal, Row, Spin } from 'antd'
import { TextCus } from 'components'
import { t } from 'i18next'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css';
import { Navigation, Thumbs } from 'swiper/modules'
import Hook from 'pages/Hotel/hook';
import createStyles from "../styles";
import './styles.scss';
import Icons from 'assets/icons';
import { theme } from 'theme';

interface IProps {
    open?: boolean
    onClose?: any
    selectedRoom?: any
}

const DetailRoom: FC<IProps> = ({ open, onClose, selectedRoom }) => {
    const styles = createStyles()
    const { isLoadingdetail, overviewData } = Hook()
    const [activeThumb, setActiveThumb] = useState<any>(null);

    const handleModal = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    // const discountedPrice = selectedRoom?.perRoomPerNightRate.inclusive || 0;
    // const discountPercentage = 39;
    // const priceBeforeSale = (discountedPrice / (1 - discountPercentage / 100)).toLocaleString('en-US', { maximumFractionDigits: 0 });
    // const currencyBeforeSale = selectedRoom?.perRoomPerNightRate?.currency === 'VND' ? 'VND' : 'USD';

    return (
        <Modal
            open={open}
            width={1100}
            title={<span style={{ fontSize: 20 }}>{t('hotel.detailRoom')}</span>}
            onCancel={onClose}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            // style={{ overflow: 'auto' }}
            className='detailModal'
        >
            <Spin spinning={isLoadingdetail} style={{ width: '100%', height: '100%' }}>
                <Row gutter={[24, 24]} onClick={handleModal} style={{ height: 'fit-content' }}>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16} style={{ height: 'fit-content' }}>
                        <Swiper
                            loop={true}
                            spaceBetween={10}
                            navigation={true}
                            modules={[Navigation, Thumbs]}
                            grabCursor={true}
                            thumbs={{ swiper: activeThumb }}
                            className='hotel-url-slider'
                        >
                            {overviewData?.urLs.map((img, index) =>
                                <SwiperSlide key={index}>
                                    <img src={img} width={700} height={400} alt='detail room images' />
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <Swiper
                            onSwiper={setActiveThumb}
                            loop={true}
                            spaceBetween={12}
                            slidesPerView={4}
                            modules={[Navigation, Thumbs]}
                            className='hotel-url-slider-thumbs'
                        >
                            {overviewData?.urLs.map((img, index) =>
                                <SwiperSlide key={index} >
                                    <div className='hotel-url-slider-thumbs-wrapper' >
                                        <img src={img} width={118} height={80} />
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '400px' }}>
                            <Row align='middle' style={{ margin: '6px 0' }} >
                                <TextCus style={styles.hotelName} >{overviewData?.hotelName}</TextCus>
                            </Row>
                            <Row align='middle' style={{ gap: 10, margin: '6px 0' }}>
                                <TextCus style={styles.comment} replace={{ 0: overviewData?.ratingAverage, 1: overviewData?.numberOfReviews as any }}>hotel.detail.comment</TextCus>
                                <TextCus style={styles.subName} replace={{ 0: overviewData?.rankInCity as any, 2: overviewData?.cityTranslated as string }}>hotel.detail.popularityScore</TextCus>
                                <TextCus style={styles.address}>{overviewData?.addresses}</TextCus>
                            </Row>
                            <Row style={{ padding: '12px 0' }}>
                                <TextCus style={{ ...styles.hotelName, fontSize: 16 }}>hotel.detail.speciality</TextCus>
                            </Row>
                            {selectedRoom?.benefits.map(benefit => (
                                <Row key={benefit.id} align='middle' gutter={[12, 12]}>
                                    <Col span={3}>
                                        <Icons.cubeIcon />
                                    </Col>
                                    <Col span={21}>
                                        {benefit?.translatedBenefitName}
                                    </Col>
                                </Row>
                            ))}
                            {selectedRoom?.surcharges?.length > 0 &&
                                <>
                                    <Row style={{ padding: '12px 0' }}>
                                        <TextCus style={{ ...styles.hotelName, fontSize: 16 }}>hotel.detail.surcharges</TextCus>
                                    </Row>
                                    {selectedRoom?.surcharges?.filter(i => i.charge !== 'Mandatory')?.map(surcharge => (
                                        <Row key={surcharge.id} align='middle' gutter={[12, 12]}>
                                            <Col span={16}>
                                                {surcharge?.name}
                                            </Col>
                                            <Col span={8}>
                                                <span style={{ color: theme.text.blue_600 }}>
                                                    {`${surcharge?.rate?.inclusive?.toLocaleString()} ${surcharge?.rate?.currency}`}
                                                </span>
                                            </Col>
                                        </Row>
                                    ))}
                                    <div
                                        style={styles.attention}
                                    >
                                        <Row align='middle'>
                                            <Col span={24}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icons.infoIcon /> <TextCus style={styles.attentionTitile}>{t('hotel.attention')}</TextCus>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <TextCus style={styles.attentionContent}>{t('hotel.detail.surchargeContent')}</TextCus>
                                            </Col>
                                        </Row>

                                    </div>
                                </>
                            }
                        </div>
                        {/* <Row style={{ justifyContent: 'space-between', paddingTop: 6 }} align={'middle'} >
                            <Col style={styles.promotionPrice}>
                                <TextCus replace={{ 0: 39 as any }} style={{ color: '#FB5607' }}>hotel.detail.promotionPrice</TextCus>
                            </Col>
                            <Col>{t('hotel.detail.from')}</Col>
                        </Row> */}
                        {/* <Row style={{ justifyContent: 'end' }} align={'middle'}>
                            <Col style={{ color: '#676767', textDecoration: 'line-through' }}>{priceBeforeSale} {currencyBeforeSale}</Col>
                        </Row> */}
                        <Row style={{ justifyContent: 'end', paddingBottom: 10, display: 'flex', alignItems: 'end' }} align={'middle'}>
                            {/* <Col span={4} style={{ color: '#676767' }}>{t('hotel.detail.perNight')}</Col> */}
                            <Col style={styles.roomPrice}>
                                {`${selectedRoom?.rate?.totalPrice?.toLocaleString()} ${selectedRoom?.perRoomPerNightRate?.currency}`}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}

export default DetailRoom