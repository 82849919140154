import React, { FC, memo, useCallback, useContext, useEffect, useMemo } from "react";
import { Col, Form, Modal, Row, Select } from "antd";
import Icons from "assets/icons";
import { Combobox, TextCus } from "components";
import { t } from "i18next";
import { areEqual } from "utils/libs";
import hookBase from "pages/base/hook";
import { useWatch } from "antd/es/form/Form";
import createStyles from "./styles";
import './styles.css'
import { stepContext } from "../../Hotel";
import { STEP } from "pages/Hotel/types";
import { useTranslation } from "react-i18next";


interface IProps {
    onClose?: () => void
    open?: boolean
}

const SearchSetting: FC<IProps> = ({ onClose, open }) => {
    const styles = createStyles();
    const {i18n} = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])
    const { getCountries } = hookBase()
    const [form] = Form.useForm();
    const starRating = useWatch('StarRatings', form)
    const markup = useWatch('Markup', form)

    const { handleDataStep } = useContext(stepContext) as any

    const starRatingAll = useMemo(() =>
        starRating &&
        starRating?.includes(-1)
        , [starRating])

    useEffect(() => {
        form.setFieldValue('StarRatings', starRatingAll ? [-1] : starRating)
    }, [starRatingAll])

    const updateSearchSetting = useCallback((value) => {
        localStorage.setItem('searchSetting', JSON.stringify(value))
        handleDataStep('SET', STEP.LIST, "searchParams", old => ({
            ...old,
            Markup: value?.Markup,
            NationalityIso2: value?.NationalityIso2,
            Currency: value?.currency,
            Filter: {
                ...old.Filter,
                StarRatings: value?.StarRatings.find(i => i === -1) ? [-1] : value?.StarRatings,
            }
        }))
    }, [handleDataStep])

    useEffect(() => {
        localStorage.getItem('searchSetting')
            ? form.setFieldsValue(JSON.parse(localStorage.getItem('searchSetting') as string))
            : form.setFieldsValue({
                NationalityIso2: 'VN',
                StarRating: [-1],
                Markup: 0,
            })
    }, []);

    const closeSearchSetting = useCallback(() => {
        form.submit()
        onClose && onClose()
    }, [form, onClose])

    return (
        <Modal
            title={
                <Row align={'middle'} style={{ gap: 6 }}>
                    <Icons.settingIcon />
                    <TextCus style={styles.titleFilterPopup}>
                        hotel.searchSetting
                    </TextCus>
                </Row>
            }
            centered
            width={'fit-content'}
            onCancel={onClose}
            open={open}
            okText={t('hotel.save')}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={closeSearchSetting}
            closeIcon={null}
        >
            <Form
                form={form}
                className="search-setting-form"
                layout='inline'
                style={{ marginLeft: 16, width: 400 }}
                onFinish={updateSearchSetting}
                initialValues={{
                    starRating: [-1]
                }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item label={t('hotel.nationalityIso2')} name="NationalityIso2" labelAlign="left" >
                            <Combobox
                                key="country"
                                variant="borderless"
                                getData={getCountries}
                                valueField="countryIso2"
                                displayField={isVietnamese ? 'countryTranslated' : 'countryName'}
                                suffixIcon={<Icons.downMiniIcon />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="StarRatings" label={t('hotel.hotelStar')} labelAlign="left">
                            <Select
                                variant="borderless"
                                suffixIcon={<Icons.downMiniIcon />}
                                value={starRating}
                                mode="multiple"
                                options={[
                                    {
                                        label: t('hotel.rank.all'),
                                        value: -1
                                    },
                                    {
                                        label: t('hotel.rank.2s'),
                                        value: 0,
                                        disabled: starRatingAll
                                    },
                                    {
                                        label: t('hotel.rank.3s'),
                                        value: 1,
                                        disabled: starRatingAll
                                    },
                                    {
                                        label: t('hotel.rank.4s'),
                                        value: 2,
                                        disabled: starRatingAll
                                    },
                                    {
                                        label: t('hotel.rank.5s'),
                                        value: 3,
                                        disabled: starRatingAll
                                    },
                                    // {
                                    //     label: t('hotel.rank.6s'),
                                    //     value: 4,
                                    //     disabled: starRatingAll
                                    // },
                                    // {
                                    //     label: t('hotel.rank.7s'),
                                    //     value: 5,
                                    //     disabled: starRatingAll
                                    // }
                                ]}
                            // onSelect={(value) => { form.setFieldsValue({ StarRating: value }) }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="Markup" label={t('hotel.markup.default')} labelAlign="left">
                            <Select
                                variant="borderless"
                                suffixIcon={<Icons.downMiniIcon />}
                                value={markup}
                                options={[
                                    {
                                        label: '0%',
                                        value: 0
                                    },
                                    ...Array.from(Array(50).keys()).map((item) => ({
                                        label: `${item + 1}%`,
                                        value: item + 1
                                    }))
                                ]}
                                onSelect={(value) => { form.setFieldsValue({ markup: value }) }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default memo(SearchSetting, areEqual);
