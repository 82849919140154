/** @format */

import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getAllQuestionsRequest, getAllQuestionsSuccess, getAllQuestionsFailed } from '../reducer';
import { getAllQuestion } from '../../services';

export const getAllQuestionNotifier = (params: any = {}, onSuccess: any, onFailure: any) => {
  return {
    type: getAllQuestionsRequest.type,
    loader: true,
    payload: params,
    onSuccess,
    onFailure,
  };
};

export function* getAllQuestionAction({ payload, type, ...props }: PayloadAction<any>) {
  yield call(request, {
    service: getAllQuestion,
    params: payload,
    cancelId: type,
    failureAction: getAllQuestionsFailed,
    successAction: getAllQuestionsSuccess,
    onSuccess: props['onSuccess'],
    onFailure: props['onFailure'],
  });
}
