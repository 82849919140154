import instance from "utils/axios";
import { parseObjectToQueryString } from "utils/libs";

const login = async (params) => {
    return await instance.post(`${process.env.REACT_APP_TOKEN_BASE_URL}/token`, params);
}

const getUserInfo = async () => {
    return await instance.get(`${process.env.REACT_APP_USER_BASE_URL}/accounts/logined/profile`);
}

const getCountries = async ({ keywords = "" }: any) => {
    return await instance.get(`${process.env.REACT_APP_TRAVEL_BASE_URL}/Addresses/countries?keywords=${keywords}`);
}

const getBalance = async () => {
    return await instance.get(`${process.env.REACT_APP_BALANCE_BASE_URL}/balances/me`);
}

const getPusblishConfig = async (params: { keyNames: string[] }) => {
    const paramsStr = parseObjectToQueryString(params)
    return await instance.get(`${process.env.REACT_APP_SETTING_BASE_URL}/settings/published/sites?${paramsStr}`);
}

const getNewsType = async (params: { Page: number, PageSize: number, siteId: number, keywords: string, IsMenu: boolean }) => {
    const paramsStr = parseObjectToQueryString(params)
    return await instance.get(`${process.env.REACT_APP_NEWS_BASE_URL}/newstype/Site?${paramsStr}`);
}
const getNews = async (params: { Page: number, PageSize: number, SiteId: number, NewsTypeId: string }) => {
    const paramsStr = parseObjectToQueryString(params)
    return await instance.get(`${process.env.REACT_APP_NEWS_BASE_URL}/news/Site?${paramsStr}`);
}

const getLandingPage = async () => {
    return await instance.get(`${process.env.REACT_APP_NEWS_BASE_URL}/landing-page`);
}

const getPaymentFee = async ({amount}) => {
    const paramsStr = parseObjectToQueryString({amount});
    return await instance.get(`${process.env.REACT_APP_BALANCE_BASE_URL}/payments/payment-fees?${paramsStr}`);
}
const createPayments = async (params) => {
    return await instance.post(`${process.env.REACT_APP_BALANCE_BASE_URL}/payments`, params);
}
const getPaymentDetail = async ({transactionId}) => {
    return await instance.get(`${process.env.REACT_APP_BALANCE_BASE_URL}/payments/${transactionId}`);
}

export {
    login,
    getUserInfo,
    getCountries,
    getBalance,
    getPusblishConfig,
    getNews,
    getNewsType,
    getPaymentFee,
    createPayments,
    getPaymentDetail,
    getLandingPage
}