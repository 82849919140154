import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, DatePicker, Flex, Row, Select } from "antd";
import Icons from "assets/icons";
import { t } from "i18next";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { theme } from "theme";
import PersonFilter from "./PersonFilter";
import dayjs from "dayjs";
import FilterPopup from "./FilterPopup";
import { STEP } from "pages/Hotel/types";
import { optionsRadiusFromTheCenter } from "./data";
import { Combobox } from "components";
import hookBase from "pages/base/hook";
import Hook from "pages/Hotel/hook";
import SearchSetting from "./SearchSetting";
import SearchKeywords from "./SearchKeywords";
import 'dayjs/locale/vi'
import locale from "antd/es/date-picker/locale/vi_VN";
import { useConfig, useNotify } from "hooks";
import { useTranslation } from "react-i18next";
import { languageCons } from "utils/constants/local";
import { stepContext } from "../../Hotel";
//import HookBase from "pages/base/hook";
dayjs.locale('vi')


const { RangePicker } = DatePicker;


const Filter: FC = () => {
    const styles = createStyles();
    const { i18n } = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])
    const { getCountries } = hookBase()
    const { getHotelList, isLoading, totalList } = Hook()
    const { error } = useNotify()
    const { currency, language } = useConfig()
    const [errorSearchKeywords, setErrorSearchKeywords] = useState(false)
    const { handleDataStep } = useContext(stepContext) as any
    const [filterPopup, setFilterPopup] = useState(false)
    const [openSetting, setOpenSetting] = useState(false)

    const isBottom = useMemo(() => handleDataStep('GET', STEP.LIST, 'isBottom') ?? false, [handleDataStep])
    const searchParams = useMemo(() => handleDataStep('GET', STEP.LIST, 'searchParams') ?? {}, [handleDataStep])
    console.log(searchParams);
    
    const setSearchParams = useCallback((value) => handleDataStep('SET', STEP.LIST, 'searchParams', value), [handleDataStep])

    //const { publishedConfigData } = HookBase()
    //const PATH_LIST = useMemo(() => publishedConfigData?.find(i => i.key === "STEP_TO_URL")?.value, [publishedConfigData])
    const root = document.getElementById('widget-search');
    const wh = root?.getAttribute('wh');

    const handleSearch = useCallback((value) => {
        if (!value.ObjectGuid && !value.EnumOperationName) {
            setErrorSearchKeywords(true)
            return
        }
        const payload = {
            enumOperationName: value.EnumOperationName,
            enumPlaceType: value.EnumPlaceType,
            objectGuid: value.ObjectGuid,
            radiusFromTheCenter: value.RadiusFromTheCenter,
            checkinDT: dayjs(value.CheckinDT).format('YYYY-MM-DD'),
            checkoutDT: dayjs(value.CheckoutDT).format('YYYY-MM-DD'),
            nationalityIso2: value.NationalityIso2,
            latitude: value.latitude,
            longitude: value.longitude,
            occupancy: {
                adults: value.Occupancy?.Adults,
                children: value.Occupancy?.Children,
                count: value.Occupancy?.Count,
                childAges: value.Occupancy?.childAges?.length ? value.Occupancy?.childAges : []
            },
            filter: {
                searchHotelSortBy: value.Filter.SearchHotelSortBy,
                priceFrom: value.Filter.price[0],
                priceTo: value.Filter.price[1],
                starRatings: value.Filter.StarRatings.length === 0 || value.Filter.StarRatings.includes(-1) ? null : value.Filter.StarRatings,
                ratingAverage: value.Filter.RatingAverage,
                accommodationTypeGuids: value.Filter.AccommodationTypeGuids?.length ? value.Filter.AccommodationTypeGuids : null,
                offers: value.Filter.offers?.length ? value.Filter.offers : null,
                propertyGroupGuids: value.Filter.PropertyGroupGuids?.length ? value.Filter.PropertyGroupGuids : null,
                brandGuids: value.Filter.BrandGuids?.length ? value.Filter.BrandGuids : null,
            },
            currency,
            language: languageCons[language],
            markup: value.Markup,
            pageSize: value.PageSize,
            page: value.Page,
            extraParams: {
                clearPage: true,
                ...value?.extraParams
            }
        }
        localStorage.setItem('searchParam', JSON.stringify(payload))
        location.replace(wh ? wh : '')
    }, [getHotelList, error, currency, language])

    const setLastSearchParams = useCallback((value) => localStorage.setItem('lastSeachParams', JSON.stringify(value)), [])

    useEffect(() => {
        isBottom && setSearchParams(old => {
            const isLastPage = searchParams.Page >= Math.ceil(totalList / searchParams.PageSize)
            if (isLastPage) return old
            const payload = {
                ...old,
                Page: old.Page + 1,
            }
            handleSearch({ ...payload, extraParams: { clearPage: false } })
            return payload
        })
    }, [isBottom])

    useEffect(() => {
        !isLoading && handleDataStep('SET', STEP.LIST, 'isBottom', false)
    }, [isLoading])

    useEffect(() => {
        handleDataStep('SET', STEP.DETAIL, 'checkindt', searchParams.CheckinDT)
        handleDataStep('SET', STEP.DETAIL, 'checkoutdt', searchParams.CheckoutDT)
    }, [JSON.stringify(searchParams)])

    return (
        <>
            <Flex
                vertical
                flex='initial'
                style={{
                    width: '100%',
                    boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
                    paddingBottom: 12
                }}
            >
                <Row
                    gutter={[12, 12]}
                    style={{
                        margin: '12px 12px'
                    }}
                >
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={8}
                    >
                        <SearchKeywords
                            placeholder={t('hotel.searchPlaceholder')}
                            suffixIcon={<Icons.searchIcon />}
                            style={styles.select}
                            onFocus={() => setErrorSearchKeywords(false)}
                            error={errorSearchKeywords}
                            valueSearch={searchParams?.nameSearch}
                            value={searchParams?.ObjectGuid}
                            handleSelect={(op) => setSearchParams(old => ({ ...old, EnumOperationName: op?.enumOperationName, ObjectGuid: op?.guid, EnumPlaceType: op?.enumPlaceType, nameSearch: op.label }))}
                        />
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={8}
                    >
                        <Select
                            suffixIcon={<Icons.dropDownIcon fill={theme.background.black} />}
                            value={searchParams.RadiusFromTheCenter}
                            options={
                                optionsRadiusFromTheCenter.map((item) => ({
                                    ...item,
                                    label: t(item.label),
                                }))
                            }
                            style={styles.select}
                            onSelect={(value) => setSearchParams(old => ({ ...old, RadiusFromTheCenter: value }))}
                        />
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={24}
                        xl={12}
                        xxl={8}
                    >
                        <RangePicker
                            separator="-"
                            locale={locale}
                            size='small'
                            suffixIcon={<Icons.calendarIcon />}
                            style={styles.datepicker}
                            value={[dayjs(searchParams.CheckinDT), dayjs(searchParams.CheckoutDT)]}
                            format={'ddd, DD/MM/YY'}
                            clearIcon={false}
                            order={false}
                            disabledDate={(current, { from }) => {
                                if (from) {
                                    return current.isSame(from, 'day') || current.isBefore(from, 'day');
                                }
                                return current.isBefore(dayjs().subtract(1, 'day'), 'day');
                            }}

                            onChange={(value) => {
                                const convertDate = (date) => dayjs(dayjs(date).format('DD/MM/YYYY'), 'DD/MM/YYYY').format()
                                setSearchParams(old => ({
                                    ...old,
                                    CheckinDT: value?.[0] ? convertDate(value[0]) : old.CheckinDT,
                                    CheckoutDT: value?.[1] ? convertDate(value[1]) : old.CheckoutDT
                                }))
                            }}
                        />
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={8}
                    >
                        <Combobox
                            suffixIcon={<Icons.dropDownIcon fill={theme.background.black} />}
                            getData={getCountries}
                            valueField="countryIso2"
                            displayField={isVietnamese ? 'countryTranslated' : 'countryName'}
                            value={searchParams.NationalityIso2}
                            style={styles.select}
                            handleSelect={(value) => setSearchParams(old => ({ ...old, NationalityIso2: value }))}
                        />
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={8}
                    >
                        <PersonFilter
                            value={searchParams?.Occupancy}
                            setValue={(func) => setSearchParams(old => ({ ...old, Occupancy: func(old.Occupancy) }))}
                        />
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={6}
                        xxl={4}
                    >
                        <Select
                            suffixIcon={<Icons.dropDownIcon fill={theme.background.black} />}
                            value={searchParams?.Markup ?? 0}
                            options={[
                                {
                                    label: t('hotel.markup.default'),
                                    value: 0,
                                },
                                ...Array.from(Array(50).keys()).map((item) => ({
                                    label: `${item + 1}%`,
                                    value: item + 1
                                }))
                            ]}
                            onSelect={(value) => setSearchParams(old => ({ ...old, Markup: value }))}
                            style={styles.select}
                        />
                    </Col>
                    <Col
                        sm={24}
                        xs={24}
                        md={24}
                        lg={12}
                        xl={6}
                        xxl={4}
                    >
                        <Button
                            block
                            type='primary'
                            style={styles.btn_Search}
                            loading={isLoading}
                            onClick={() => {
                                setLastSearchParams(searchParams)
                                setSearchParams(old => {
                                    const payload = {
                                        ...old,
                                        Page: 1,
                                    }
                                    handleSearch({ ...payload, extraParams: { clearPage: true } })
                                    return payload
                                })
                            }}
                        >
                            {t('hotel.search')}
                        </Button>
                    </Col>
                </Row>
                <Flex
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        icon={<Icons.filterIcon />}
                        style={styles.btn_Filter}
                        onClick={() => setFilterPopup(true)}
                    >
                        {t('hotel.filter')}
                    </Button>
                    <Button
                        block
                        icon={<Icons.settingIcon />}
                        style={styles.btn_Filter}
                        onClick={() => setOpenSetting(true)}
                    >
                        {t('hotel.searchSetting')}
                    </Button>
                </Flex>
                <SearchSetting onClose={() => setOpenSetting(false)} open={openSetting} />
            </Flex>
            <FilterPopup
                open={filterPopup}
                onClose={() => setFilterPopup(false)}
                value={searchParams.Filter}
                setValue={(value) => setSearchParams(old => ({ ...old, Filter: value }))}
            />
            {/* <ModalNotify
                open={errorSearchKeywords}
                onOk={() => setErrorSearchKeywords(false)}
                title='hotel.searchError.title'
                description='hotel.searchError.message'
            /> */}
        </>
    );
}
export default memo(Filter, areEqual);