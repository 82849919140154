/** @format */

import { Tabs, TabsProps } from 'antd';
import React, { memo } from 'react';
import { areEqual } from 'utils/libs';
import { HomeOutlined, CloudOutlined } from '@ant-design/icons';
import { Hotel } from 'pages/Hotel/view';
import Flight from 'pages/Flight/view/Flight';

const BoxSearchWidget = () => {
  const root = document.getElementById('widget-search');
  const wf = root?.getAttribute('wf');
  const wh = root?.getAttribute('wh');
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: wf ? 'Máy bay' : null,
      children: wf ? <Flight /> : null,
      icon: wf ? <CloudOutlined /> : null,
    },
    {
      key: '2',
      label: wh ? 'Khách sạn' : null,
      children: wh ? <Hotel /> : null,
      icon: wh ? <HomeOutlined /> : null,
    },
  ];

  return (
    <Tabs
      defaultActiveKey={wf ? '1' : '2'}
      centered
      items={items}
      onChange={onChange}
      style={{
        // width:'1124px',
        padding: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 8px 3px',
        borderRadius: '16px',
      }}
      // items={[HomeOutlined, CloudOutlined].map((Icon, i) => {
      //     const id = String(i + 1);
      //     return {
      //         label: `Tab ${id}`,
      //         key: id,
      //         children: `Content of Tab Pane ${id}`,
      //         icon: <Icon />,
      //     };
      // })}
    />
  );
};
export default memo(BoxSearchWidget, areEqual);
