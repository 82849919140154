import { Col, Row } from "antd";
import { TextCus } from "components";
import { t } from "i18next";
import React, { memo, useContext, useMemo } from "react";
import { theme } from "theme";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { stepContext } from "../../Hotel";
import { STEP } from "pages/Hotel/types";
import Icons from "assets/icons";

const HotelInfo = () => {
    const styles = createStyles();
    const { handleDataStep } = useContext(stepContext) as any
    const hotelName = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'data')?.hotelName, [handleDataStep])
    const starRating = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'data')?.starRating, [handleDataStep])
    const hotelAddress = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'data')?.addressLine1, [handleDataStep])
    return (
        <>
            <Row align='middle' gutter={[12, 12]} style={{ marginTop: 24 }}>
                <div style={{
                    width: 16,
                    height: 16,
                    position: 'relative',
                    background: theme.background.primary,
                    borderRadius: 100
                }}
                />
                <TextCus style={styles.roomInfo}>{t('hotel.hotel_info')}</TextCus>
            </Row>
            <Row gutter={[8, 8]}>
                <TextCus
                    style={styles.hotelName}
                >
                    {hotelName}
                </TextCus>
                <Row align='middle' >
                    {Array.from({ length: starRating }).map((_, index) => (
                        Icons.starFillIcon({ key: index })))}
                </Row>
                <Col span={24}>
                    <TextCus style={styles.hotelAddress}>{hotelAddress}</TextCus>
                </Col>
            </Row>
        </>
    );
}
export default memo(HotelInfo, areEqual);