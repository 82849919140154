import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getPusblishConfigRequest, getPusblishConfigSuccess, getPusblishConfigFailed } from '../reducer'
import { getPusblishConfig } from '../../services';

export const getPusblishConfigNotifier = (params: any = {},onSuccess, onFailure) => {
    return {
        type: getPusblishConfigRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getPusblishConfigAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: getPusblishConfig,
        params: payload,
        cancelId: type,
        failureAction: getPusblishConfigFailed,
        successAction: getPusblishConfigSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}