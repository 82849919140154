import { RadioButton } from "components";
import React, { FC, memo, useCallback } from "react";
import { areEqual } from "utils/libs";

interface IProps {
    value?: 0 | 1 | 2 | 3 | null;
    setValue: (val: any) => void;
}
const RatingAverage: FC<IProps> = ({ value, setValue }) => {
    const handleChange = useCallback((key) => (val) => {
        val && setValue(key)
    }, [setValue])
    return (
        <div style={{display:'flex', gap:10}}>
            <RadioButton label="hotel.all" active={value === 0} onChange={handleChange(0)} />
            <RadioButton label="7+" iconName="tripadVisorIcon" active={value === 1} onChange={handleChange(1)} />
            <RadioButton label="8+" iconName="tripadVisorIcon" active={value === 2} onChange={handleChange(2)} />
            <RadioButton label="9+" iconName="tripadVisorIcon" active={value === 3} onChange={handleChange(3)} />
        </div>
    );
}
export default memo(RatingAverage, areEqual);