import { CSSProperties } from "react"
import { theme } from "theme"


export default (active?) => {
    return {
        input: {
            height: '44px',
            width: '100%',
            padding: '5px 5px',
            alignItems: 'center',
            gap: '10px',
        },
        datepicker: {
            height: '44px',
            width: '100%',
            padding: '5px 10px',
            alignItems: 'center',
            // gap: '10px',
        },
        select: {
            height: '44px',
            width: '100%',
            alignItems: 'center',
            gap: '10px',
        },
        btn_Search: {
            width: '100%',
            height: '44px',
            borderRadius: '8px',
        },
        btn_Filter: {
            borderColor: 'transparent',
            boxShadow: 'none',
            display: 'flex',
            color: theme.text.secondary,
            height: '28px',
            width: 'fit-content',
        },
        filter: {
            height: '28px'
        },
        filterBar: {
            height: '54px',
            width: 'calc(100% - 24px)',
            overflowX: 'auto',
            padding: '12px 0px',
            '-ms-overflow-style': 'none',  /* Internet Explorer 10+ */
            'scrollbar-width': 'none',  /* Firefox */
            '::-webkit-scrollbar': {
                display: 'none'
            }
        },
        itemReview: {
            width: '100%',
            height: '220px',
            marginBottom: 24,
            borderRadius: 12,
            border: active ? `1px solid ${theme.background.secondary}` : `1px solid ${theme.background.black}`,
            boxShadow: active ? `0px 4px 12px ${theme.background.secondary} ` : 'none',
        },
        imgReview: {
            width: '35%',
            height: '100%',
            borderRadius: '12px 0px 0px 12px'
        },
        contentReview: {
            padding: '18px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },
        titleItem: {
            color: '#161616',
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: '20px'
        },
        subTitle: {
            color: theme.text.black,
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '158%'
        },
        divider: {
            height: '12px', strokeWidth: '1px',
            stroke: theme.text.black_200
        },
        btn_View: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
            width: '120px',
            height: '40px',
            borderRadius: '8px'

        },
        btn_compare: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            height: '44px',
            color: theme.text.secondary,
            border: `1px solid ${theme.text.secondary}`,
            borderRadius: '8px'
        },
        btn_person_footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        titleRoom: {
            fontSize: 20,
            fontWeight: 600,
            color: theme.text.blue,
        },
        titleRoomMobile: {
            fontSize: 16,
            fontWeight: 600,
            color: theme.text.black_900,
            letterSpacing: '0.32px',
        },
        priceMobile: {
            fontSize: 16,
            fontWeight: 700,
            color: theme.text.blue
        },
        textNumReviewMobile: {
            fontSize: 16,
            fontWeight: 400,
            color: theme.text.black
        },
        rateMobile: {
            fontSize: 16,
            fontWeight: 400,
            color: theme.text.green,
        },
        rateTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: theme.text.green,
        },
        rankDesMobile: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.text.black
        },
        titlePerson: {
            fontSize: 14,
            fontWeight: 600,
            color: theme.text.black_900
        },
        btn_delRoom: {
            backgroundColor: theme.background.third,
            color: theme.text.secondary,
        },
        personFilterContainer: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 304,
            maxHeight: 408,
            overflowY: 'auto',
            padding: '0px 12px'
        },
        personFilterFooter: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 12px',
        },
        titleFilterPopup: {
            fontSize: 20,
            fontWeight: 600,
            color: theme.text.secondary,
        },
        resultFilterPopup: {
            fontSize: 16,
            fontWeight: 600,
            color: theme.text.black_500,
        },
        filterContent: {
            padding: '24px 0px 24px 24px',
        },
        filterContainer: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 24,
            height: window.innerHeight - 350,
            overflowY: 'auto',
        },
        labelFilter: {
            fontSize: 16,
            fontWeight: 600,
            color: theme.text.blue,
        },
        radioGroup: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 12,
        },
        textFilter: {
            fontSize: 14,
            fontWeight: 400,
            color: theme.text.black_900,
        },
        checkboxGroup: {
            marginTop: 16,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 12
        },
        rate: {
            color: theme.background.secondary
        }
    } as { [key: string]: CSSProperties }
}

