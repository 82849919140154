import { localStorageCons } from "utils/constants";

const useUser = () => {
    const token = JSON.parse(localStorage.getItem(localStorageCons.ACCESS_TOKEN) as string);
    const rfToken = JSON.parse(localStorage.getItem(localStorageCons.REFRESH_TOKEN) as string);
    const userInfo = JSON.parse(localStorage.getItem(localStorageCons.USER_INFO) as string);


    const isUserLogin = (token && rfToken && userInfo) ? true : false

    const logout = () => {
        localStorage.removeItem(localStorageCons.ACCESS_TOKEN);
        localStorage.removeItem(localStorageCons.REFRESH_TOKEN);
        localStorage.removeItem(localStorageCons.USER_INFO);
        window.location.href = "/";
    }
    return {
        isUserLogin,
        userInfo,
        rfToken,
        token,
        logout
    }
}

export default useUser;