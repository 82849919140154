import instance from "utils/axios";
import { ISearchHotel } from "../types";
import { parseObjectToQueryString } from "utils/libs";

const getHotelList = async (params: ISearchHotel) => {
    return await instance.post(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/hotels`, params);
}

const getLocations = async ({ keywords }) => {
    return await instance.get(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/locations?keywords=${keywords}`);
}

const getOverview = async ({ hotelId }) => {
    return await instance.get(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/${hotelId}/overviews`);
}

const getFacilities = async ({ hotelId }) => {
    return await instance.get(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/${hotelId}/facilities`);
}

const getRoomtypes = async ({ guid, ...params }) => {
    return await instance.post(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/${guid}/roomtypes`, params);
}

const getPolicies = async ({ hotelId }) => {
    return await instance.get(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/${hotelId}/policies`);
}

const booking = async (params ) => {
    return await instance.post(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/bookings`, params);
}

const cancelBooking = async ({ bookingGuid,...params }) => {
    return await instance.post(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/bookings/${bookingGuid}/cancel`, params);
}

const getBookingDetail = async ({ bookingId }) => {
    return await instance.get(`${process.env.REACT_APP_TRAVEL_BASE_URL}/hotels/bookings/${bookingId}`);
}

const getAccommodation = async () => {
    return await instance.get(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/filter/accommodation-types`);
}
const getProperty = async () => {
    return await instance.get(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/filter/property-groups`);
}
const getChainBrands = async (params) => {
    return await instance.get(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/filter/brands?${parseObjectToQueryString(params)}`);
}

const addCart = async ({ hotelId, ...params}) => {
    return await instance.post(`${process.env.REACT_APP_TRAVEL_BASE_URL}/hotels/${hotelId}/rooms/cart`, params);
}

const paymentBooking = async (params) => {
    return await instance.post(`${process.env.REACT_APP_HOTEL_CORE_SERVICE_URL}/hotels/bookings/${params?.bookingGuid}/payment`, params);
}

export {
    getHotelList,
    getLocations,
    getOverview,
    getFacilities,
    getRoomtypes,
    getPolicies,
    booking,
    getBookingDetail,
    getAccommodation,
    getProperty,
    getChainBrands,
    addCart,
    cancelBooking,
    paymentBooking
}
