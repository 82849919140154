import { createSlice } from '@reduxjs/toolkit';

interface IFlightState {
    loading: boolean;
    error: string | null;
    message: string | null;
    data: any[];
    total: number;
}

const initialState: IFlightState = {
    loading: false,
    error: null,
    message: null,
    data: [],
    total: 0,
}

const FlightsSlice = createSlice({
    name: 'flight',
    initialState,
    reducers: {
        requestSuccess: (state) => ({
            ...state,
            loading: false,
        }),
        requestFailed: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload?.errorMessages,
        }),
        getRequest: (state) => ({
            ...state,
            loading: true,
            data: state?.data,
        }),
        getSuccess: (state, action) => ({
            ...state,
            loading: false,
            data: action.payload.extraParams.clearPage
                ? action.payload?.items : [...state.data, ...(action.payload?.items || [])],
            total: action.payload?.pagingInfo?.totalItems,
            pageSize: action.payload?.pagingInfo?.pageSize,
            page: action.payload?.pagingInfo?.page,
        }),
        getFailed: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload?.errorMessages,
        }),
    }
})

const { actions, reducer } = FlightsSlice;

export const {
    requestSuccess,
    requestFailed,
    getRequest,
    getSuccess,
    getFailed,
} = actions;
export default reducer;
export type {
    IFlightState,
}
