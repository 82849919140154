import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { localStorageCons } from 'utils/constants';

interface IBaseState {
    loading: boolean;
    error: string | null;
    message: string | null;
    data: any;
    balance: any;
    countries?: [];
    publishedConfig?: any[];
    loadingConfig?: boolean;
    currency?: any;
    language?: any;
    paymentFee?: any;
    payment?: any;
    loadingPayment?: boolean;
    landingPageData: any;
    loadingLandingPage: boolean;
}

const initialState: IBaseState = {
    loading: false,
    error: null,
    message: null,
    data: null,
    balance: null,
    countries: [],
    publishedConfig: [],
    loadingConfig: false,
    currency: localStorage.getItem(localStorageCons.CURRENCY) || 'VND',
    language: localStorage.getItem(localStorageCons.LANGUAGE) || 'vi',
    paymentFee: null,
    payment: null,
    loadingPayment: false,
    landingPageData: null,
    loadingLandingPage: false,
}

const baseSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        loginRequest: (state) => ({
            ...state,
            loading: true,
        }),
        loginSuccess: (state, action) => {
            const accessToken = {
                accessToken: action?.payload?.accessToken,
                expired: action?.payload?.expired
            }
            const refreshToken = action?.payload?.refreshToken;

            localStorage.setItem(localStorageCons.ACCESS_TOKEN, JSON.stringify(accessToken));
            localStorage.setItem(localStorageCons.REFRESH_TOKEN, JSON.stringify(refreshToken));

            return ({
                ...state,
                loading: false,
                data: action.payload,
            })
        },
        loginFail: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        getUserInfoRequest: (state) => ({
            ...state,
            loading: true,
        }),
        getUserInfoSuccess: (state, action) => {

            const userInfo = action?.payload
            localStorage.setItem(localStorageCons.USER_INFO, JSON.stringify(userInfo));

            return ({
                ...state,
                loading: false,
                data: action.payload,
            })
        },
        getUserInfoFail: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        getCountriesRequest: (state) => ({
            ...state,
            loading: true,
        }),
        getCountriesSuccess: (state, action) => ({
            ...state,
            loading: false,
            countries: action.payload.countries,
        }),
        getCountriesFailed: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        requestSuccess: (state) => ({
            ...state,
            loading: false,
            loadingConfig: false,
        }),
        requestFailed: (state, action) => ({
            ...state,
            loading: false,
            loadingConfig: false,
            error: action.payload?.errorMessages,
        }),
        getBalanceRequest: (state) => ({
            ...state,
            loading: true,
        }),
        getBalanceSuccess: (state, action) => ({
            ...state,
            loading: false,
            balance: action.payload,
        }),
        getBalanceFail: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        getLandingPageRequest: (state) => ({
            ...state,
            loadingLandingPage: true,
        }),
        getLandingPageSuccess: (state, action) => ({
            ...state,
            loadingLandingPage: false,
            landingPage: action.payload,
        }),
        getLandingPageFail: (state, action) => ({
            ...state,
            loadingLandingPage: false,
            error: action.payload,
        }),
        getPusblishConfigRequest: (state) => ({
            ...state,
            loadingConfig: true,
        }),
        getPusblishConfigSuccess: (state, action) => ({
            ...state,
            loadingConfig: false,
            publishedConfig: action.payload,
        }),
        getPusblishConfigFailed: (state, action) => ({
            ...state,
            loadingConfig: false,
            error: action.payload,
        }),
        getNewsRequest: (state) => ({
            ...state,
            loadingConfig: true,
        }),
        getNewsSuccess: (state, action) => {
            const { extraParams, ...rest } = action.payload
            return ({
                ...state,
                loadingConfig: false,
                [extraParams.id]: rest?.items,
            })
        },
        getNewsFailed: (state, action) => ({
            ...state,
            loadingConfig: false,
            error: action.payload,
        }),
        getNewsTypeRequest: (state) => ({
            ...state,
            loadingConfig: true,
        }),
        setLanguage: (state, action) => {
            localStorage.setItem(localStorageCons.LANGUAGE, action.payload);
            i18next.changeLanguage(action.payload);
            return ({
                ...state,
                language: action.payload,
            })
        },
        setCurrency: (state, action) => {
            localStorage.setItem(localStorageCons.CURRENCY, action.payload);
            return ({
                ...state,
                currency: action.payload,
            })
        },
        getPaymentFeeRequest: (state) => ({
            ...state,
            loading: true,
        }),
        getPaymentFeeSuccess: (state, action) => ({
            ...state,
            loading: false,
            paymentFee: action.payload?.paymentFees,
        }),
        getPaymentFeeFail: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        createPaymentsRequest: (state) => ({
            ...state,
            loadingPayment: true,
        }),
        createPaymentsSuccess: (state, action) => ({
            ...state,
            loadingPayment: false,
            payment: action.payload,
        }),
        createPaymentsFail: (state, action) => ({
            ...state,
            loadingPayment: false,
            error: action.payload,
        }),
        getPaymentDetailRequest: (state) => ({
            ...state,
            loading: true,
        }),
        getPaymentDetailSuccess: (state, action) => ({
            ...state,
            loading: false,
            payment: action.payload,
        }),
        getPaymentDetailFail: (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),


    }
});
const { actions, reducer } = baseSlice;

export const {
    loginRequest,
    loginSuccess,
    loginFail,
    getUserInfoRequest,
    getUserInfoSuccess,
    getUserInfoFail,
    getCountriesRequest,
    requestSuccess,
    requestFailed,
    getBalanceRequest,
    getBalanceSuccess,
    getBalanceFail,
    getLandingPageRequest,
    getLandingPageSuccess,
    getLandingPageFail,
    getCountriesSuccess,
    getCountriesFailed,
    getPusblishConfigRequest,
    getPusblishConfigSuccess,
    getPusblishConfigFailed,
    getNewsRequest,
    getNewsSuccess,
    getNewsFailed,
    getNewsTypeRequest,
    setLanguage,
    setCurrency,
    getPaymentFeeRequest,
    getPaymentFeeSuccess,
    getPaymentFeeFail,
    createPaymentsRequest,
    createPaymentsSuccess,
    createPaymentsFail,
    getPaymentDetailRequest,
    getPaymentDetailSuccess,
    getPaymentDetailFail,
} = actions;

export default reducer;
export type {
    IBaseState
}
