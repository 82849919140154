import React, { FC, memo } from "react";
import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import { t } from "i18next";
import { areEqual } from "utils/libs";

const { Text } = Typography

type IProps = TextProps & {
    useI18n?: boolean;
    replace?: { [key: string]: string };
}

const TextCus: FC<IProps> = ({
    useI18n = true,
    children,
    replace = {},
    ...props
}) => {

    return (
        <Text
            {...props}
        >
            {
                useI18n && children ? t(children?.toString(), { ...replace }) : children
            }
        </Text>
    )
}

export default memo(TextCus, areEqual);