import dayjs from "dayjs";
import { ISearchHotel } from "pages/Hotel/types";

export const optionsSort = [
    {
        label: 'hotel.sortBy.closest',
        value: 0
    },
    {
        label: 'hotel.sortBy.minDistance',
        value: 4
    },
    {
        label: 'hotel.sortBy.lowestPrice',
        value: 1
    },
    {
        label: 'hotel.sortBy.higheststar',
        value: 2
    },
    {
        label: 'hotel.sortBy.highestReview',
        value: 3
    }
]

export const optionsType = [
    {
        label: 'hotel.types.hotel',
        value: 'hotel'
    },
    {
        label: 'hotel.types.spaHotel',
        value: 'spaHotel'
    },
    {
        label: 'hotel.types.hotelWithBreakfast',
        value: 'hotelWithBreakfast'
    },
    {
        label: 'hotel.types.motel',
        value: 'motel'
    }
]

export const optionsPromotion = [
    {
        label: 'hotel.promotion.freeCancel',
        value: 0
    },
    {
        label: 'hotel.promotion.orther',
        value: 1
    }
]

export const optionsSustainable = [
    {
        label: 'hotel.sustainable.eco',
        value: 0
    },
    {
        label: 'hotel.sustainable.abc',
        value: 1
    }
]

export const optionsRadiusFromTheCenter = [
    {
        value: 0,
        label: 'hotel.radiusFromTheCenter.0'
    },
    {
        value: 1,
        label: 'hotel.radiusFromTheCenter.1'
    },
    {
        value: 2,
        label: 'hotel.radiusFromTheCenter.2'
    },
    {
        value: 3,
        label: 'hotel.radiusFromTheCenter.3'
    }
]

export const Convenients2Key = {
    0: 'wifi',
    1: 'parking',
    2: 'swim',
    3: 'spa',
    4: 'airCondition',
    5: 'child',
}
export const Key2Convenients = {
    wifi: 0,
    parking: 1,
    swim: 2,
    spa: 3,
    airCondition: 4,
    child: 5,
}

const searchSetting = JSON.parse(localStorage.getItem('searchSetting') ?? '{}');
const lastSearch = JSON.parse(localStorage.getItem('lastSeachParams') ?? '{}') as ISearchHotel;
export const filterInit: ISearchHotel = {
    EnumOperationName: 0,
    EnumPlaceType: 0,
    ObjectGuid: undefined,
    RadiusFromTheCenter: 1,
    countryIso2Search: searchSetting?.NationalityIso2 ?? 'VN',
    ...lastSearch,
    CheckinDT: dayjs().format(),
    CheckoutDT: dayjs().add(1, 'day').format(),
    Markup: searchSetting?.Markup ?? 0,
    NationalityIso2: searchSetting?.NationalityIso2 ?? 'VN',
    Occupancy: {
        Adults: 1,
        Children: 0,
        Count: 1,
        childAges: [],
    },
    Filter: {
        SearchHotelSortBy: 0,
        price: [0, 10000000],
        RatingAverage: 0,
        StarRatings: (!searchSetting?.StarRatings || searchSetting?.StarRatings?.some(i => i === -1)) ? [] : searchSetting?.StarRatings,
    },
    PageSize: 10,
    Page: 1,
    extraParams: {
        clearPage: true
    }
}