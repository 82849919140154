import { theme } from "theme";

export default () => ({
    title: {
        color: theme.text.black_900,
        fontSize: 28,
        fontWeight: 600,
    },
    distance: {
        color: theme.text.black_900,
        fontSize: 16,
        fontWeight: 400,
    },
    hotelName: {
        color: theme.text.black_900,
        fontSize: 20,
        fontWeight: 600,
    },
    subName: {
        color: theme.text.black_900,
        fontSize: 14,
        fontWeight: 400,
    },
    comment: {
        color: theme.text.green,
        fontSize: 14,
        fontWeight: 600,
    },
    address: {
        color: theme.text.black_900,
        fontSize: 16,
        fontWeight: 500,
    },
    showInMap: {
        color: theme.text.blue,
        fontSize: 16,
        fontWeight: 600,
        cursor: 'pointer',
    },
    overview: {
        color: theme.text.black_900,
        fontSize: 14,
        fontWeight: 400,
    },
    prominentLocation: {
        color: theme.text.secondary,
        fontSize: 20,
        fontWeight: 600,
    },
    propertyGroupDescription: {
        color: theme.text.secondary,
        fontSize: 16,
        fontWeight: 500,
    },
    categoryName: {
        color: theme.text.black_900,
        fontSize: 14,
        fontWeight: 600,
    },
    roomTypeItem: {
        border: '1px solid #E5E5E5',
        borderRadius: '12px',
        paddingLeft: 0,
        paddingRight: 12,
        cursor: 'pointer',
    },
    roomPrice: {
        color: theme.text.blue,
        fontSize: 20,
        fontWeight: 600,
        display: 'inline-block',
    },
    promotionPrice: {
        borderRadius: 4,
        border: `1px solid ${theme.text.secondary}`,
        backgroundColor: theme.background.third,
        padding: '2px 8px'
    },
    roomStatusInstant: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '5px 8px',
        marginRight: 12,
        width: 'fit-content',
        color: theme.text.green,
        backgroundColor: '#E9FFE9',
        border: `1px solid ${theme.text.green}`,
    },
    roomStatusOnRequest: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        padding: '5px 8px',
        marginRight: 12,
        width: 'fit-content',
        color: '#8338EC',
        backgroundColor: '#EFE4FC',
        border: '1px solid #8338EC',
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        padding: '5px 8px',
        marginRight: 12,
        width: 'fit-content',
    },
    blockSeleted: {
        border: `1.5px solid ${theme.background.primary}`,
        backgroundColor: theme.background.third,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    roomCompare: {
        color: theme.text.secondary,
        backgroundColor: theme.background.third,
        border: `1px solid ${theme.text.secondary}`,
        borderRadius: 4,
        padding: '5px 8px',
        display: 'inline-block'
    },
    imgReview: {
        width: '100%',
        borderRadius: '12px'
    },
    titleSecondary: {
        color: theme.text.secondary,
        fontSize: 20,
        fontWeight: 600,
    },
    roomTypeItemSelected: {
        border: `1px solid ${theme.background.primary}`,
        backgroundColor: theme.background.third,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    attention: {
        display: 'flex',
        marginTop: 12,
        padding: 12,
        gap: 10,
        fexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: 6,
        border: `1px solid ${theme.background.blue_600}`,
        background: theme.background.blue_50,
    },
    attentionContent: {
        color: theme.text.blue_600,
        fontSize: 12,
        fontWeight: 400,
        // marginLeft: 24,
    },
    attentionTitile: {
        color: theme.text.blue_600,
        fontSize: 14,
        fontWeight: 600,
    },
    datepicker: {
        height: '44px',
        width: '100%',
        padding: '5px 10px',
        alignItems: 'center',
        // gap: '10px',
    },
    btn_Search: {
        width: '100%',
        height: '44px',
        borderRadius: '8px',
    },
})