/** @format */

import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getTourDetailRequest, getTourDetailSuccess, getTourDetailFailed } from '../reducer';
import { getTourDetail } from '../../services';

export const getTourDetailNotifier = (params: any = {}, onSuccess: any, onFailure: any) => {
  return {
    type: getTourDetailRequest.type,
    loader: true,
    payload: params,
    onSuccess,
    onFailure,
  };
};

export function* getTourDetailAction({ payload, type, ...props }: PayloadAction<any>) {
  yield call(request, {
    service: getTourDetail,
    params: payload,
    cancelId: type,
    failureAction: getTourDetailFailed,
    successAction: getTourDetailSuccess,
    onSuccess: props['onSuccess'],
    onFailure: props['onFailure'],
  });
}
