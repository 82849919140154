import { TextCus } from "components";
import React, { FC, memo, useContext, useEffect, useMemo, useState } from "react";
import { IConvenientPlaces, ILocalPlaces, IProperties, IPropertyGroups } from "pages/Hotel/types";
import { areEqual } from "utils/libs";
import createStyles from "../styles";
import { Col, Divider, Row, Spin } from "antd";
import Hook from "pages/Hotel/hook";
import { useCommon } from "hooks";
import { IconProp, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { stepContext } from "pages/Hotel/view/Hotel";
import Icons from "assets/icons";
import { theme } from "theme";
import { useTranslation } from "react-i18next";

library.add(fab, fas)

const Facilities: FC = () => {
    const styles = createStyles()
    const { isLoadingdetail, getFacilities, facilitiesData } = Hook()
    const { handleDataStep } = useContext(stepContext) as any
    const { isSmallScreen } = useCommon();
    const [readMorePropertyGroups, setReadMorePropertyGroups] = useState(false);
    const [hover, setHover] = useState(false);
    const guid = useMemo(() => handleDataStep('GET', 'DETAIL', 'guidView'), [handleDataStep])
    const { i18n } = useTranslation()
    const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language])

    useEffect(() => {
        guid && facilitiesData?.guid !== guid && getFacilities(guid)
    }, [guid])

    if (facilitiesData?.propertyGroups.length === 0 && facilitiesData?.convenientPlaces.length === 0 && facilitiesData?.localPlaces.length === 0) {
        return (
            <div style={{ padding: 6, margin: '12px 0' }}>
                <TextCus>hotel.detail.emptyData</TextCus>
            </div>
        );
    }

    return (
        <Spin spinning={isLoadingdetail}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {facilitiesData?.propertyGroups.length > 0 && <Row align='middle' style={{ margin: '6px 0' }} >
                    <TextCus style={styles.prominentLocation}>hotel.detail.propertyGroups</TextCus>
                </Row>}
                {facilitiesData?.propertyGroups?.slice(0, 2).map((group: IPropertyGroups) => (
                    <div key={group.guid}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 6, margin: '12px 0' }}>
                            <FontAwesomeIcon icon={group.urlIcon as IconProp} size='2x' style={{ color: theme.text.secondary }} />
                            <TextCus style={styles.propertyGroupDescription}>{group.propertyGroupDescriptionTranslated && isVietnamese ? group.propertyGroupDescriptionTranslated : group.propertyGroupDescription}</TextCus>
                        </div>
                        <Row>
                            {group.properties?.map((property: IProperties) => (
                                <React.Fragment key={property.guid}>
                                    {property.propertyName &&
                                        <>
                                            <Col xs={3} sm={2} md={2} lg={1} xl={1} xxl={1}>
                                                <Icons.checkIcon />
                                            </Col>
                                            <Col xs={20} sm={21} md={21} lg={11} xl={5} xxl={5} >
                                                {property.propertyTranslatedName && isVietnamese ? property.propertyTranslatedName : property.propertyName}
                                            </Col>
                                        </>}
                                </React.Fragment>
                            ))}
                        </Row>
                    </div>
                ))}
                {readMorePropertyGroups && (
                    <>
                        {facilitiesData?.propertyGroups?.slice(2).map((group: IPropertyGroups) => (
                            <div key={group.guid}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 6, margin: '12px 0' }}>
                                    <FontAwesomeIcon icon={group.urlIcon as IconProp} size='2x' style={{ color: theme.text.secondary }} />
                                    <TextCus style={styles.propertyGroupDescription}>{group.propertyGroupDescriptionTranslated && isVietnamese ? group.propertyGroupDescriptionTranslated : group.propertyGroupDescription}</TextCus>
                                </div>
                                <Row>
                                    {group.properties?.map((property: IProperties) => (
                                        <React.Fragment key={property.guid}>
                                            {property.propertyName &&
                                                <>
                                                    <Col xs={3} sm={2} md={2} lg={1} xl={1} xxl={1}>
                                                        <Icons.checkIcon />
                                                    </Col>
                                                    <Col xs={20} sm={21} md={21} lg={11} xl={5} xxl={5}>
                                                        {property.propertyTranslatedName && isVietnamese? property.propertyTranslatedName : property.propertyName}
                                                    </Col>
                                                </>}
                                        </React.Fragment>
                                    ))}
                                </Row>
                            </div>
                        ))}
                    </>
                )}
                {facilitiesData?.propertyGroups.length > 0 && (
                    <div style={{ width: '100%', display: 'flex', cursor: 'pointer', justifyContent: 'flex-end', padding: 12 }}
                        onClick={() => setReadMorePropertyGroups(!readMorePropertyGroups)}>
                        {readMorePropertyGroups ? <>
                            <TextCus
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                style={{
                                    color: hover ? theme.text.secondary : '',
                                    transition: 'color 0.3s ease'
                                }}>
                                hotel.detail.showLess
                            </TextCus>
                            <Icons.upIcon />
                        </> :
                            <>
                                <TextCus
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                    style={{
                                        color: hover ? theme.text.secondary : '',
                                        transition: 'color 0.3s ease'
                                    }}>
                                    hotel.detail.showMore
                                </TextCus>
                                <Icons.downIcon />
                            </>
                        }
                    </div>
                )}
                {facilitiesData?.convenientPlaces.length > 0 && <Row align='middle' style={{ margin: '6px 0' }} >
                    <TextCus style={styles.prominentLocation}>hotel.detail.convenientPlaces</TextCus>
                </Row>}
                {facilitiesData?.convenientPlaces.length > 0 &&
                    <Row style={{ justifyContent: 'flex-start', backgroundColor: '#F8F8F8', padding: 24, width: isSmallScreen ? '100%' : '60%' }} align={'top'} gutter={[12, 12]}>
                        {facilitiesData?.convenientPlaces?.map((place: IConvenientPlaces, index: number) => (
                            <React.Fragment key={index}>
                                <Col span={16} style={styles.categoryName} >
                                    {place.categoryName}:
                                </Col>
                                <Col span={8} >
                                    {place.distance}{place.distanceUnit}
                                </Col>
                            </React.Fragment>
                        ))}
                    </Row>}

                {facilitiesData?.localPlaces.length > 0 && <Row align='middle' style={{ margin: '6px 0' }} >
                    <TextCus style={styles.prominentLocation}>hotel.detail.localPlaces0</TextCus>
                </Row>}
                {facilitiesData?.localPlaces.length > 0 && <Row style={{ justifyContent: 'flex-start', backgroundColor: '#F8F8F8', padding: 24, width: '100%' }} align={'top'} gutter={[12, 12]}>
                    {facilitiesData?.localPlaces?.filter((place: ILocalPlaces) => place.localPlaceType === 0)?.map((place: ILocalPlaces, index: number) => (
                        <React.Fragment key={index}>
                            <Col span={16} style={styles.categoryName} >
                                {place.name}:
                            </Col>
                            <Col span={8} >
                                {place.distance}{place.distanceUnit}
                            </Col>
                        </React.Fragment>
                    ))}
                </Row>}
                {facilitiesData?.localPlaces.length > 0 && <Row align='middle' style={{ margin: '6px 0' }} >
                    <TextCus style={styles.prominentLocation}>hotel.detail.localPlaces1</TextCus>
                </Row>}
                {facilitiesData?.localPlaces.length > 0 &&
                    <Row style={{ justifyContent: 'flex-start', backgroundColor: '#F8F8F8', padding: 24, width: '100%' }} align={'top'} gutter={[12, 12]}>
                        {facilitiesData?.localPlaces?.filter((place: ILocalPlaces) => place.localPlaceType === 1)?.map((place: ILocalPlaces, index: number) => (
                            <React.Fragment key={index}>
                                <Col span={16} style={styles.categoryName} >
                                    {place.name}:
                                </Col>
                                <Col span={8} >
                                    {place.distance}{place.distanceUnit}
                                </Col>
                            </React.Fragment>
                        ))}
                    </Row>}
                <Divider />
            </div >
        </Spin>
    )
}
export default memo(Facilities, areEqual)