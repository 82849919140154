import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getChainBrandsRequest, getChainBrandsSuccess, getChainBrandsFailed } from '../reducer'
import { getChainBrands } from '../../services';

export const getChainBrandsNotifier = (params: any = {},onSuccess, onFailure) => {
    return {
        type: getChainBrandsRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getChainBrandsAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: getChainBrands,
        params: payload,
        cancelId: type,
        failureAction: getChainBrandsFailed,
        successAction: getChainBrandsSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}