import React, { FC, memo, useContext, useMemo } from "react";
import { Col, Row } from "antd";
import { areEqual } from "utils/libs";
import Icons from "assets/icons";
import { TextCus } from "components";
import createStyle from "../styles";
import { stepContext } from "pages/Hotel/view/Hotel";
import { STEP } from "pages/Hotel/types";


const Header: FC = () => {
    const { handleDataStep } = useContext(stepContext) as any

    const viewDetail = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'data'), [handleDataStep])

    const styles = createStyle();

    return (
        <Row
            justify='start'
        >
            <Col span={24}>
                <TextCus style={styles.title}>{viewDetail?.hotelName}</TextCus>
            </Col>
            <Col span={24}>
                <Icons.destinationFillIcon style={{ marginRight: 4 }} />
                <TextCus style={styles.distance} replace={{ 0: viewDetail?.distance as any, 1: viewDetail?.distanceUnit as any }}>hotel.distance</TextCus>
            </Col>
        </Row>
    )
}
export default memo(Header, areEqual)