import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Row } from "antd";
import { t } from "i18next";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { TextCus } from "components";
import RoomInfo from "./RoomInfo";
import HotelInfo from "./HotelInfo";
import CustomerInfo from "./CustomerInfo";
import TimeInfo from "./TimeInfo";
import PolicyInfo from "./PolicyInfo";
import Icons from "assets/icons";
import { stepContext } from "../../Hotel";
import { STEP } from "pages/Hotel/types";
import Summary from "./Summary";
import dayjs from "dayjs";
import Hook from "pages/Hotel/hook";
import { useAutoCompleteInput, useCommon, useNotify } from "hooks";
// import ModalSucces from "./ModalSucces";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { theme } from "theme";
import Payment from "../../../../../components/Payment";

const PaymentForm: FC = () => {
    const { saveInformationAutoComplete } = useAutoCompleteInput()
    const styles = createStyles();
    const navigate = useNavigate()
    const contentRef = useRef<HTMLDivElement>(null)
    const summarRef = useRef<HTMLDivElement>(null)
    const bodyRef = useRef<HTMLDivElement>(null)
    const [summaryHeight, setSummaryHeight] = useState(0)
    const [bodyHeight, setBodyHeight] = useState(0)
    const [scroll, setScroll] = useState(0)
    const [methodPopup, setMethodPopup] = useState(false)
    const { success, error, warning } = useNotify()
    const { booking } = Hook()
    const { windowHeight } = useCommon()
    const [form] = Form.useForm()
    const { handleDataStep } = useContext(stepContext) as any
    const roomtype = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'roomtype'), [handleDataStep])
    const checkInDT = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'checkindt'), [handleDataStep])
    const checkOutDT = useMemo(() => handleDataStep('GET', STEP.DETAIL, 'checkoutdt'), [handleDataStep])
    const paymentHotelType = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'method'), [handleDataStep])
    const voucherCode = useMemo(() => handleDataStep('GET', STEP.PAYMENT, 'transactionId'), [handleDataStep])
    const paymentMethod = useMemo(() => {
        const method = ['PAYNOW', 'BANK', 'CREDIT']
        if (roomtype.freeCancellation && roomtype?.isCanPayLater) {
            method.push('PAYLATER')
        }
        return method
    }, [roomtype])
    const handleSubmit = useCallback((values) => {
        if (!paymentHotelType) {
            setMethodPopup(true)
            return
        }
        const payload = {
            paymentHotelType,
            blockId: roomtype.blockId,
            searchId: roomtype.searchId,
            voucherCode,
            stayingCutomers: values.customerInfo.map((customer) => ({
                ...customer,
                phoneNumber: `${customer.phoneCode}${customer.phoneNumber}`,
                dob: customer?.dob?.format('YYYY-MM-DD'),
            })),
            // searchId: 1625150577089210000,
        }
        values.customerInfo.forEach((customer) => {
            saveInformationAutoComplete('customerInfo', 'fullName', customer)
        })
        booking(payload, (res) => {
            navigate(`/hotels/detail/${res?.bookingGuid}`)
        },
            (res) => {
                if (res?.errorMessages?.[0]?.errorCode === 'ERRCH01') {
                    warning({
                        message: t(`base.notification.${res?.errorMessages?.[0]?.errorCode}`)
                    })
                    return
                }
                error({
                    title: 'Đặt phòng thất bại',
                    message: res?.errorMessages?.[0]?.errorMessage
                })
            }
        )
    }, [JSON.stringify(roomtype), paymentHotelType, booking, success, error, warning, setMethodPopup, voucherCode])

    useEffect(() => {
        contentRef.current?.addEventListener('scroll', () => {
            setScroll(contentRef.current?.scrollTop ?? 0)
        })
    }, [contentRef.current])

    useEffect(() => {
        const height = summarRef.current?.clientHeight ?? 0
        setSummaryHeight(height)
        summarRef.current?.addEventListener('resize', () => {
            const height = summarRef.current?.clientHeight ?? 0
            setSummaryHeight(height)
        })
    }, [summarRef.current])

    useEffect(() => {
        const height = bodyRef.current?.clientHeight ?? 0
        setBodyHeight(height)
        bodyRef.current?.addEventListener('resize', () => {
            const height = bodyRef.current?.clientHeight ?? 0
            setBodyHeight(height)
        })
    }, [bodyRef.current])

    useEffect(() => {
        let top = 'unset'
        if (windowHeight > summaryHeight) {
            if (scroll + summaryHeight > bodyHeight) {
                top = `${bodyHeight - summaryHeight}px`
            }
            else {
                top = `${scroll}px`
            }
        }
        else {
            if (scroll < summaryHeight - windowHeight + 90) {
                top = '0px'
            }
            if (scroll > summaryHeight - windowHeight + 90) {
                if (scroll > bodyHeight - windowHeight) {
                    top = `${bodyHeight - summaryHeight}px`
                }
                else {
                    top = `${scroll + windowHeight - summaryHeight - 90}px`
                }
            }

        }
        summarRef.current?.style.setProperty('top', top)
    }, [scroll, windowHeight, summaryHeight, bodyHeight, summarRef.current])


    return (
        <>
            <div
                ref={contentRef}
                style={{
                    height: '100%',
                    width: '100%',
                    overflow: 'auto',
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '12px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Row
                        justify='center'
                        style={{
                            height: '100%',
                            width: '100%',
                            maxWidth: '1366px',
                            gap: 12
                        }}>
                        <Col span={16} style={styles.col as any} ref={bodyRef}>
                            <TextCus style={styles.title}>{t('hotel.payment_fill_form')}</TextCus>
                            <RoomInfo />
                            <HotelInfo />
                            <Form
                                form={form}
                                scrollToFirstError={{ behavior: 'smooth', block: 'center', inline: 'center' }}
                                initialValues={{
                                    checkInDT: dayjs(checkInDT),
                                    checkOutDT: dayjs(checkOutDT),
                                    customerInfo: [{
                                        isMainContact: true,
                                        fullName: undefined,
                                        pin: '',
                                        dob: '',
                                        phoneNumber: '',
                                        email: ''
                                    }]
                                }}
                                layout='vertical'
                                requiredMark={false}
                                onFinish={handleSubmit}
                                style={{ width: '100%', padding: 0 }}
                            >

                                <CustomerInfo
                                    key={''}
                                    index={''}
                                    form={form}
                                />
                                <TimeInfo />
                                <PolicyInfo />
                            </Form>
                            <div
                                style={styles.attention}
                            >
                                <Row align='middle'>
                                    <Col span={24}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Icons.infoIcon /> <TextCus style={styles.attentionTitile}>{t('hotel.attention')}</TextCus>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div style={styles.attentionContent}>
                                            <TextCus style={{ color: `${theme.text.blue}` }}>
                                                {t('hotel.attention_content_date', { freeCancellationDate: roomtype.freeCancellationDate })}
                                            </TextCus>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        {/* <div style={styles.attentionContent}>{roomtype?.cancellationPolicy?.date.map((item, index) => (
                                        <p key={index}>Before: {dayjs(item?.before).format('DD-MM-YYYY')}</p>
                                    ))}
                                    </div> */}
                                        <div style={styles.attentionContent}>{t('hotel.attention_content_1')}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col
                            ref={summarRef}
                            span={7}
                            style={{ ...styles.col, gap: 12 } as any}
                        >
                            <Summary form={form} total={roomtype?.totalPayment?.totalPrice} />
                        </Col>

                    </Row>
                    <Footer />
                </div>
            </div >
            <Payment
                open={methodPopup}
                onClose={() => setMethodPopup(false)}
                onOk={({ method, transactionId }) => {
                    handleDataStep('SET', STEP.PAYMENT, 'method', method)
                    handleDataStep('SET', STEP.PAYMENT, 'transactionId', transactionId)
                    setMethodPopup(false)
                    form.submit()
                }}
                total={roomtype?.totalPayment?.totalPrice}
                currency={roomtype?.rate?.currency}
                methods={paymentMethod as any[]}
            />

        </>
    );
};
export default memo(PaymentForm, areEqual);