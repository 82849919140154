import React, { memo, useContext, useMemo } from "react";
import { Button, Col, Row } from "antd";
import { useCommon } from "hooks";
import { areEqual } from "utils/libs";
import { t } from "i18next";
import Icons from "assets/icons";
import { theme } from "theme";
import { createStyles } from "./styles";
import UserHeader from "./UserHeader";
import { TextCus } from "components";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "./Layout";
import HookBase from "pages/base/hook";


const Header = () => {
    const styles = createStyles();
    const navigate = useNavigate()
    const { isSmallScreen } = useCommon();
    const { publishedConfigData } = HookBase()
    const { layoutProps } = useContext(LayoutContext) as any

    const Logo = useMemo(() => publishedConfigData?.find(i => i?.key === 'LOGO')?.value, [publishedConfigData])

    return (
        <>
            {
                isSmallScreen
                    ? <Row align='middle' style={{ width: '100%', height: '100%', padding: '12px 12px' }}>
                        <Col
                            span={2}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            onClick={layoutProps.handleBack}
                        >
                            <Icons.leftIcon />
                        </Col>
                        <Col span={20} style={{ display: 'flex', justifyContent: 'center' }}>
                            <TextCus
                                style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: theme.text.black
                                }}
                            >Hotel</TextCus>
                        </Col>
                        <Col span={2} />
                    </Row>

                    : (
                        <>
                            <Row justify='space-between' align='middle' style={{ backgroundColor: '#FFFFFF', width: '100%', padding: '12px 0px', boxShadow: '0px 1px 1px #E5E5E5' }}>
                                <Col span={16}>
                                    <Row justify='start' gutter={[12, 12]}>
                                        <Col
                                            style={{ ...styles.col, paddingLeft: 10 }}
                                            onClick={layoutProps.handleBack}
                                        >
                                            <Icons.leftArrowIcon
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            style={styles.col}
                                        >
                                            <img
                                                src={Logo}
                                                style={{ cursor: 'pointer', height:'44px' }}
                                                onClick={() => location.replace('/')}
                                            />
                                        </Col>
                                        <Col
                                            style={styles.col}
                                        >
                                            <Button
                                                block
                                                icon={<Icons.bedIcon />}
                                                type="primary"
                                                style={{
                                                    backgroundColor: theme.background.third,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: theme.background.primary,
                                                }}
                                                onClick={() => navigate('/hotels', { replace: false })}
                                            >
                                                {t('base.hotel')}
                                            </Button>
                                        </Col>
                                        <Col
                                            style={styles.col}
                                        >
                                            <Button
                                                block
                                                icon={<Icons.heartHomeIcon />}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: theme.text.black,
                                                }}
                                            >
                                                {t('base.motel')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <UserHeader />
                            </Row>
                        </>
                    )
            }
        </>
    )
}
export default memo(Header, areEqual);