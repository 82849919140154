/** @format */

import { Button, Col, DatePicker, Flex, Radio, RadioChangeEvent, Row, Select } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { areEqual } from 'utils/libs';
import createStyles from './styles';
import Icons from 'assets/icons';
import { theme } from 'theme';
import { t } from 'i18next';
import Hook from '../hook';
import PersonFilter from './components/Input/PersonFilter';
import { TICKET_TYPES } from './constants';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { TextCus } from 'components';

const Flight = () => {
  const styles = createStyles();
  const { getList, list } = Hook();
  const [activeTab, setActiveTab] = useState<any>(TICKET_TYPES.ONE_WAY);
  const [startDate, setStartDate] = useState<any>(dayjs());
  const [endDate, setEndDate] = useState<any>(null);
  const [errorFromCity, setErrorFromCity] = useState<any>(false);
  const [errorToCity, setErrorToCity] = useState<any>(false);
  const root = document.getElementById('widget-search');
  const wf = root?.getAttribute('wf');
  const [counts, setCounts] = useState({
    adults: 1,
    children: 0,
    babies: 0,
    total: 1,
  });
  const [paramSearch, setParamSearch] = useState<any>({
    fromCityCode: '',
    toCityCode: '',
    totalAdults: counts?.adults || 1,
    totalChildren: counts?.children,
    totalInfants: counts?.babies,
    departDate: startDate?.format('YYYY/MM/DD'),
    endDate: endDate?.format('YYYY/MM/DD'),
  });

  // const PATH_LIST = useMemo(
  //   () => publishedConfigData?.find(i => i.key === 'STEP_TO_URL')?.value,
  //   [publishedConfigData],
  // );
  useEffect(() => {
    getList({
      keywords: '',
      pageSize: 6000,
      page: 1,
    });
  }, []);
  const onSelectTab = tabId => {
    setActiveTab(tabId);
    if (tabId === TICKET_TYPES.ONE_WAY) {
      setStartDate(startDate);
      setEndDate(null);
    } else {
      setStartDate(startDate);
      setEndDate(startDate.add(1, 'day'));
    }
  };

  const onChangeTab = (e: RadioChangeEvent) => {
    onSelectTab(e.target.value);
  };

  useEffect(() => {
    setParamSearch({
      ...paramSearch,
      totalAdults: counts?.adults || 1,
      totalChildren: counts?.children,
      totalInfants: counts?.babies,
      departDate: startDate?.format('YYYY/MM/DD'),
      endDate: endDate?.format('YYYY/MM/DD'),
    });
  }, [counts, startDate, endDate]);
  console.log('paramSearch', paramSearch);

  return (
    <Flex
      vertical
      flex='initial'
      style={{
        width: '100%',
        boxShadow: '0 2px 0 rgba(0, 0, 0, 0.02)',
        paddingBottom: 12,
      }}
    >
      <Radio.Group onChange={onChangeTab} value={activeTab}>
        <Radio value={TICKET_TYPES.ONE_WAY}>Một chiều</Radio>
        <Radio value={TICKET_TYPES.RETURN}>Khứ hồi</Radio>
      </Radio.Group>
      <Row
        gutter={[12, 12]}
        style={{
          margin: '12px 0',
        }}
      >
        <Col sm={24} xs={24} md={24} lg={12} xl={12} xxl={6}>
          <label style={{ marginLeft: '10px' }}>Bay đi</label>
          <Select
            style={styles.select}
            suffixIcon={<Icons.dropDownIcon fill={theme.background.black} />}
            showSearch
            allowClear
            onSelect={(_, op) => {
              setParamSearch(old => ({
                ...old,
                fromCityCode: op?.value,
                departureDetail: list.find(opt => opt.code === op.value),
              }));
              setErrorFromCity(false);
            }}
            //onPopupScroll={onPopupScroll}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={list.map(opt => ({ label: `${opt.address} (${opt.code})`, value: opt.code }))}
            {...(errorFromCity && {
              status: 'error',
              placeholder: <TextCus type='danger'>hotel.searchError.message</TextCus>,
            })}
          />
        </Col>
        <Col sm={24} xs={24} md={24} lg={12} xl={12} xxl={6}>
          <label style={{ marginLeft: '10px' }}>Bay đến</label>
          <Select
            style={styles.select}
            suffixIcon={<Icons.dropDownIcon fill={theme.background.black} />}
            showSearch
            allowClear
            onSelect={(_, op) => {
              setParamSearch(old => ({
                ...old,
                toCityCode: op?.value,
                destinationDetail: list.find(opt => opt.code === op.value),
              }));
              setErrorToCity(false);
            }}
            //onPopupScroll={onPopupScroll}
            filterOption={(input, option) =>
              (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={list.map(opt => ({ label: `${opt.address} (${opt.code})`, value: opt.code }))}
            {...(errorToCity && {
              status: 'error',
              placeholder: <TextCus type='danger'>hotel.searchError.message</TextCus>,
            })}
          />
        </Col>
        <Col sm={24} xs={24} md={24} lg={12} xl={12} xxl={6}>
          <Row gutter={[12, 12]}>
            <Col
              sm={activeTab === TICKET_TYPES.RETURN ? 12 : 24}
              xs={activeTab === TICKET_TYPES.RETURN ? 12 : 24}
              md={activeTab === TICKET_TYPES.RETURN ? 12 : 24}
              lg={activeTab === TICKET_TYPES.RETURN ? 12 : 24}
              xl={activeTab === TICKET_TYPES.RETURN ? 12 : 24}
              xxl={activeTab === TICKET_TYPES.RETURN ? 12 : 24}
            >
              <label style={{ marginLeft: '10px' }}>Chọn ngày đi</label>
              <DatePicker
                style={styles.datepicker}
                value={dayjs(startDate)}
                format={'ddd, DD/MM/YY'}
                locale={locale}
                minDate={dayjs()}
                onChange={value => {
                  if (activeTab === TICKET_TYPES.ONE_WAY) {
                    setStartDate(value);
                    setEndDate(null);
                  } else {
                    setStartDate(value);
                    setEndDate(value.add(1, 'day'));
                  }
                }}
              />
            </Col>
            {activeTab === TICKET_TYPES.RETURN && (
              <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}>
                <label style={{ marginLeft: '10px' }}>Chọn ngày về</label>
                <DatePicker
                  style={styles.datepicker}
                  value={dayjs(endDate)}
                  format={'ddd, DD/MM/YY'}
                  locale={locale}
                  minDate={dayjs(startDate)}
                  onChange={value => {
                    setEndDate(value);
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>

        <Col sm={24} xs={24} md={24} lg={12} xl={12} xxl={6}>
          <label style={{ marginLeft: '10px' }}>Chọn số lượng khách</label>
          <PersonFilter counts={counts} setCounts={setCounts} />
        </Col>
      </Row>
      <Row justify={'end'}>
        <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={4}>
          <Button
            block
            type='primary'
            style={styles.btn_Search}
            onClick={() => {
              if (!paramSearch?.departureDetail && !paramSearch?.destinationDetail) {
                setErrorFromCity(true);
                setErrorToCity(true);
                return;
              }
              if (paramSearch?.departureDetail && !paramSearch?.destinationDetail) {
                setErrorFromCity(false);
                setErrorToCity(true);
                return;
              }
              if (!paramSearch?.destinationDetail && paramSearch?.destinationDetail) {
                setErrorFromCity(true);
                setErrorToCity(false);
                return;
              }
              localStorage.setItem('paramSearchFlight', JSON.stringify(paramSearch));
              location?.replace(wf ? wf : '');
            }}
          >
            {t('hotel.search')}
          </Button>
        </Col>
      </Row>
    </Flex>
  );
};
export default memo(Flight, areEqual);
