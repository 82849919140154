import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Form, Input, Modal, Radio, Row } from "antd";
import { t } from "i18next";
import { areEqual } from "utils/libs";
import { theme } from "theme";
import { TextCus } from "components";
import createStyle from "./styles";
import Hook from "pages/base/hook";
import Icons from "assets/icons";
import Deposit from "./Deposit";
import QrTransfer from "./QrTransfer";

interface IProps {
    open: boolean
    onClose: () => void
    onOk?: ({ method, transactionId }) => void
    total?: number
    currency?: string
    methods?: ('PAYNOW' | 'PAYLATER' | 'BANK' | 'CREDIT')[]
    bookingGuid?: string
}
const Payment: FC<IProps> = ({ open, onClose, onOk, currency, methods = [], total }) => {
    const [form] = Form.useForm()
    const styles = createStyle()
    const { getBalance, balanceInfo, createPayments, getPaymentFee, paymentFeeData, isLoadingPayment } = Hook()
    const [selected, setSelected] = useState('PAYNOW');
    const [depositPopup, setDepositPopup] = useState(false)
    const [transferPopup, setTransferPopup] = useState({
        open: false,
        qrCode: '',
        transactionId: ''
    })
    const isNotEnough = useMemo(() => balanceInfo?.amount < (total ?? 0), [balanceInfo, total])

    const handleOk = useCallback((transactionId = '') => {
        switch (selected) {
            case 'PAYNOW':
                onOk && onOk({ method: 'PAYNOW', transactionId })
                break;
            case 'PAYLATER':
                onOk && onOk({ method: 'PAYLATER', transactionId })
                break;
            case 'BANK':
                if (!transferPopup.open) {
                    createPayments({
                        amount: total,
                        paymentMethod: 3,
                        paymentType: 2,
                        transactionFee: 0
                    }, (res) => {
                        setTransferPopup({
                            open: true,
                            qrCode: res.qrCode,
                            transactionId: res.transactionID
                        })
                    })
                }
                if (transactionId) {
                    onOk && onOk({ method: 'BANK', transactionId })
                }
                break;
            case 'CREDIT':
                setDepositPopup(true)
                if (transactionId) {
                    onOk && onOk({ method: 'CREDIT', transactionId })
                }
                break;
        }
    }, [onClose, selected, onOk, transferPopup, total])


    const PaynowComponent = useMemo(() => {
        return (
            <Col span={methods?.length === 1 ? 24 : 12} style={{ height: '100%' }}>
                <Badge.Ribbon text={`+0 VND`}>
                    <Radio value={"PAYNOW"}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '12px 12px',
                            paddingTop:24,
                            marginRight: 0,
                            borderRadius: 12,
                            border: `1px solid ${selected === 'PAYNOW' ? theme.background.secondary : '#E5E5E5'}`,
                            backgroundColor: selected === 'PAYNOW' ? theme.background.third : '#FFFFFF',
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <TextCus style={styles.payTitle}>{t('base.pay_now')}</TextCus>
                            </Col>
                            <Col span={24}>
                                <TextCus style={styles.payDesc}>{t('base.pay_now_desc')}</TextCus>
                            </Col>
                            <Col span={24}>
                                <TextCus style={styles.balance}>{t('base.balance1', { balance: balanceInfo?.amount?.toLocaleString() })}</TextCus>
                            </Col>
                        </Row>
                    </Radio>
                </Badge.Ribbon>
            </Col>
        )
    }, [selected, balanceInfo, styles])

    const PaylaterComponent = useMemo(() => {
        return (
            <Col span={methods?.length === 1 ? 12 : 24} style={{ height: '100%' }}>
                <Badge.Ribbon text={`+0 VND`}>
                    <Radio value={"PAYLATER"}
                        style={{
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'space-between',
                            padding: '12px 12px',
                            paddingTop:24,
                            marginRight: 0,
                            borderRadius: 12,
                            border: `1px solid ${selected === 'PAYLATER' ? theme.background.secondary : '#E5E5E5'}`,
                            backgroundColor: selected === 'PAYLATER' ? theme.background.third : '#FFFFFF',
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <TextCus style={styles.payTitle}>{t('base.booking')}</TextCus>
                            </Col>
                            <Col span={24}>
                                <TextCus style={styles.payDesc}>{t('base.pay_later_desc')}</TextCus>
                            </Col>
                        </Row>
                    </Radio>
                </Badge.Ribbon>
            </Col>
        )
    }, [selected, styles])


    const TransferComponent = useMemo(() => {
        return (
            <Col span={methods?.length === 1 ? 24 : 12} style={{ height: '100%' }}>
                <Badge.Ribbon text={`+${paymentFeeData?.bank?.toLocaleString()} VND`}>
                    <Radio value={"BANK"}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '12px 12px',
                            paddingTop:24,
                            marginRight: 0,
                            borderRadius: 12,
                            border: `1px solid ${selected === 'BANK' ? theme.background.secondary : '#E5E5E5'}`,
                            backgroundColor: selected === 'BANK' ? theme.background.third : '#FFFFFF',
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <TextCus style={styles.payTitle}>{t('base.transfer')}</TextCus>
                            </Col>
                            <Col span={24}>
                                <TextCus style={styles.payDesc}>{t('base.transfer_desc')}</TextCus>
                            </Col>
                        </Row>
                    </Radio>
                </Badge.Ribbon>
            </Col>
        )
    }, [selected, styles, paymentFeeData?.bank])

    const CreditComponent = useMemo(() => {
        return (
            <Col span={methods?.length === 1 ? 24 : 12} style={{ height: '100%' }}>
                <Badge.Ribbon text={`+${paymentFeeData?.credit?.toLocaleString()} VND`}>
                    <Radio value={"CREDIT"}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '12px 12px',
                            paddingTop:24,
                            marginRight: 0,
                            borderRadius: 12,
                            border: `1px solid ${selected === 'CREDIT' ? theme.background.secondary : '#E5E5E5'}`,
                            backgroundColor: selected === 'CREDIT' ? theme.background.third : '#FFFFFF',
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <TextCus style={styles.payTitle}>{t('base.credit')}</TextCus>
                            </Col>
                            <Col span={24}>
                                <TextCus style={styles.payDesc}>{t('base.credit_desc')}</TextCus>
                            </Col>
                        </Row>
                    </Radio>
                </Badge.Ribbon>
            </Col>
        )
    }, [selected, styles, paymentFeeData?.credit])

    useEffect(() => {
        getBalance({})
    }, [])

    useEffect(() => {
        getPaymentFee({ amount: total })
    }, [total])

    return (
        <>
            <Modal
                title={t('base.payment_method_choose')}
                open={open}
                onCancel={onClose}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText={t('base.confirm')}
                onOk={() => handleOk()}
                okButtonProps={{ disabled: isNotEnough && selected === 'PAYNOW' }}
                confirmLoading={isLoadingPayment}
            >
                <Form
                    form={form}
                    layout='vertical'
                >
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <Form.Item name='paymentMethod' noStyle>
                                <Radio.Group
                                    onChange={(e) => setSelected(e.target.value)}
                                    defaultValue={selected}
                                >
                                    <Row gutter={[12, 12]}>

                                        {methods?.includes('PAYNOW') && PaynowComponent}
                                        {methods?.includes('PAYLATER') && PaylaterComponent}
                                        {methods?.includes('BANK') && TransferComponent}
                                        {methods?.includes('CREDIT') && CreditComponent}
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {isNotEnough && selected === 'PAYNOW' &&
                            <Col span={24}>
                                <Row gutter={[12, 12]}>
                                    <Col span={2}>
                                        <Icons.infoIcon color={theme.text.red_700} />
                                    </Col>
                                    <Col span={22}>
                                        <TextCus style={styles.balanceWarning}>{t('base.balanceWarning')}</TextCus>
                                    </Col>
                                </Row>
                            </Col>}
                        <Col span={24} style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
                            <Button
                                type='primary'
                                style={{
                                    width: 'fit-content',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8
                                }}
                                onClick={() => setDepositPopup(true)}
                            >
                                {t('base.deposit')}
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name='voucher'
                                label={<>
                                    <span>{t('base.voucher')}</span>
                                </>}
                            >
                                <Input
                                    placeholder='Nhập mã'
                                    style={styles.input} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Row justify={'space-between'}>
                                <Col style={{ width: 'fit-content' }}>
                                    <TextCus style={styles.totalLabel}>{t('base.totalBill')}</TextCus>
                                </Col>
                                <Col>
                                    <TextCus style={styles.totalValue}>{`${total?.toLocaleString()} ${currency}`}</TextCus>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Deposit
                open={depositPopup}
                onClose={() => setDepositPopup(false)}
            />
            {
                transferPopup.open &&
                <QrTransfer
                    open={transferPopup.open}
                    onClose={() => setTransferPopup({ open: false, qrCode: '', transactionId: '' })}
                    qrCode={transferPopup.open ? transferPopup.qrCode : ''}
                    transactionId={transferPopup.open ? transferPopup.transactionId : ''}
                    handleSuccess={handleOk}
                />
            }
        </>
    );
}
export default memo(Payment, areEqual);