import { lazy } from "react";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createBrowserRouter } from "react-router-dom";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { all } from 'redux-saga/effects';
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { init as initBase } from "pages/base";
import { init as initHotel } from "pages/Hotel";
// import {init as initHotelGpt} from "pages/HotelGPT";
import { init as initTour } from "pages/Tour";
import { init as initBooking } from "pages/BookedManager";
import { init as initDebtManager } from "pages/DebtMangager";
import { initFlight } from "pages/Flight";
const Config = lazy(() => import("Config"));

const saga = []
const reducers = {}
const route: any = []
const en = {}
const vi = {}
/*******************************************************************************/
initBase(saga, reducers, route, en, vi)
initHotel(saga, reducers, route, en, vi)
// initHotelGpt(saga, reducers, route, en, vi)
initTour(saga, reducers, route, en, vi)
initBooking(saga, reducers, route, en, vi)
initDebtManager(saga, reducers, route, en, vi)
initFlight(saga, reducers)


/*******************************************************************************/

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources: {
        en: { translation: en },
        vi: { translation: vi },
    },
    lng: "vi",
    fallbackLng: "vi",
    interpolation: {
        escapeValue: true,
    },
})


const routes = createBrowserRouter([{
    path: '/',
    Component: Config,
    children: route,
}])

/***************************************************************/
function* rootSaga() {
    yield all(saga);
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['app'],
};
const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
    return appReducer(state, action)
};
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [...middleware],
});
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor, routes, i18n };

/***************************************************************/
