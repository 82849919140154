import { Col, Modal, Radio, Row } from "antd";
import { t } from "i18next";
import React, { FC, memo, useCallback, useContext, useMemo, useState } from "react";
import { theme } from "theme";
import { areEqual } from "utils/libs";
import { stepContext } from "pages/Hotel/view/Hotel";
import Hook from "pages/Hotel/hook";
import { useNotify } from 'hooks';
interface IProps {
    open?: boolean;
    onClose?: any;
}

const OffersPopup: FC<IProps> = ({ open, onClose }) => {
    const { handleDataStep } = useContext(stepContext) as any;
    const [cartOption, setCartOption] = useState(1);
    const { addCart } = Hook();
    const { success, error } = useNotify();
    const selectedRoom = useMemo(() => handleDataStep('GET', 'DETAIL', 'selectedRoom'), [handleDataStep])

    const handleOptionSelect = (value: number) => {
        setCartOption(value);
    }

    const handleAddToCart = useCallback(() => {
        const payload = {
            hotelId: selectedRoom.hotelId,
            option: cartOption,
            roomtypes: {
                guid: selectedRoom.guid,
                blockId: selectedRoom.blockId,
                hotelRoomtypeId: selectedRoom.hotelRoomtypeId,
                currency: selectedRoom.currency,
                exclusive: selectedRoom.totalPayment.exclusive,
                inclusive: selectedRoom.totalPayment.inclusive,
                tax: selectedRoom.totalPayment.tax,
                fees: selectedRoom.totalPayment.fees,
                freeBreakfast: selectedRoom.freeBreakfast,
                freeCancellation: selectedRoom.freeCancellation,
                cancellationText: selectedRoom?.cancellationPolicy?.cancellationText,
                translatedCancellationText: selectedRoom?.cancellationPolicy?.translatedCancellationText,
                freeCancellationDate: selectedRoom.freeCancellationDate,
                date: selectedRoom.cancellationPolicy?.date,
                // stayingCutomers: values.customerInfo.map((customer) => ({
                //     ...customer,
                //     dob: customer.dob.format('YYYY-MM-DD'),
                // })),
                benefits: selectedRoom.benefits,
                paymentModel: selectedRoom.paymentModel,
                providerName: selectedRoom.providerName,
                rate: selectedRoom.rate,
            },
        }
        addCart(payload, () => {
            success(
                {
                    title: 'Thêm chào giá thành công',
                    content: 'Bạn đã thêm chào giá thành công'
                }
            )
            onClose();
        },
            (res) => {
                error({
                    title: 'Thêm chào giá thất bại',
                    content: res?.data?.message
                })
                onClose();
            }
        )
    }, [JSON.stringify(selectedRoom), addCart, success, error])

    return (
        <>
            <Modal
                open={open}
                width={400}
                title={t("hotel.offersOptions")}
                onCancel={onClose}
                okText={t("hotel.confirm")}
                onOk={handleAddToCart}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Radio.Group
                    onChange={(e) => handleOptionSelect(e.target.value)}
                    value={cartOption}
                >
                    <Row gutter={[12, 12]}>
                        {[1, 2, 3].map((item, index) => (
                            <Col key={index}>
                                <Radio
                                    value={item}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '12px 12px',
                                        borderRadius: 12,
                                        border: `1px solid ${cartOption === item ? theme.background.secondary : '#E5E5E5'
                                            }`,
                                        backgroundColor: cartOption === item ? theme.background.third : '#FFFFFF',
                                    }}
                                >
                                    {t('hotel.option', { name: item })}
                                </Radio>
                            </Col>
                        ))}
                    </Row>
                </Radio.Group>
            </Modal>
        </>
    );
};

export default memo(OffersPopup, areEqual);
