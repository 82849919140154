import React, { memo, useCallback, useState } from "react";
import { Checkbox, Form, Modal, Row } from "antd";
import { TextCus } from "components";
import { t } from "i18next";
import { theme } from "theme";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import './styles.css'
import { useNotify } from "hooks";
import Hook from "pages/base/hook";

const PolicyInfo = () => {
    const styles = createStyles();
    const { warning } = useNotify()
    const { getNewsData } = Hook()
    const policyData = getNewsData("TERM") || []
    const [policyModal, setPolicyModal] = useState({
        open: false,
        title: '',
        content: ''
    })

    const handleShowPolicy = useCallback((key) => () => {
        const data = policyData.find((item: any) => item.title === key)
        setPolicyModal(
            {
                open: true,
                title: data?.description,
                content: data?.content
            }
        )
    }, [policyData, setPolicyModal]);

    return (
        <>
            <Row align='middle' gutter={[12, 12]} style={{ marginTop: 24 }}>
                <div style={{
                    width: 16,
                    height: 16,
                    position: 'relative',
                    background: theme.background.primary,
                    borderRadius: 100
                }}
                />
                <TextCus style={styles.roomInfo}>{t('hotel.policy_info')}</TextCus>
            </Row>
            <br />
            <Form.Item
                name='bookingCondition'
                noStyle
                valuePropName='checked'
                rules={[
                    {
                        required: true,
                        message: t('hotel.booking_condition_required'),
                    },
                    {
                        validator: (_, value) => {
                            if (value) {
                                return Promise.resolve()
                            }
                            else {
                                const isCheckboxUnchecked = typeof value === 'undefined' || value === false;
                                if (isCheckboxUnchecked) {
                                    return Promise.reject(warning({ title: 'hotel.booking_condition_required', message: '' }));
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        },
                        validateTrigger: 'onSubmit'
                    }
                ]}
            >
                <Checkbox>
                    <TextCus style={{ ...styles.bookingCondition }} >{t('hotel.booking_condition_content1')}</TextCus>
                    <TextCus style={{ ...styles.bookingCondition, color: theme.text.secondary, textDecorationLine: 'underline', cursor: 'pointer' }} onClick={handleShowPolicy('General Terms of Use')}>{t('hotel.booking_condition_content2')}</TextCus>
                    <TextCus style={{ ...styles.bookingCondition }}>{t('hotel.booking_condition_content3')}</TextCus>
                    <TextCus style={{ ...styles.bookingCondition, color: theme.text.secondary, textDecorationLine: 'underline', cursor: 'pointer' }} onClick={handleShowPolicy('Privacy policy')}>{t('hotel.booking_condition_content4')}</TextCus>
                </Checkbox>
            </Form.Item >
            {/* <TextCus style={styles.bookingCondition}>{t('hotel.booking_condition')}</TextCus> */}
            < Modal
                open={policyModal?.open}
                title={
                    < Row justify='center' style={{ width: '100%' }
                    }>
                        <TextCus style={{
                            fontSize: 18,
                            fontWeight: 600
                        }}>{policyModal?.title}</TextCus>
                    </Row >
                }
                closeIcon={null}
                okText={t('hotel.confirm')}
                cancelButtonProps={{
                    style: {
                        display: 'none'
                    }
                }}
                onOk={() => setPolicyModal({ open: false, title: '', content: '' })}
                onCancel={() => setPolicyModal({ open: false, title: '', content: '' })}
                width='80%'
                style={{
                    height: '85%'
                }}
                styles={{
                    content: {
                        height: '100%',
                    },
                    body: {
                        height: '90%',
                        overflow: 'auto'
                    },
                    header: {
                        width: '100%',
                        height: 20
                    }
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: policyModal?.content || '' }} />
            </Modal >
        </>
    );
};
export default memo(PolicyInfo, areEqual);