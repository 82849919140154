import { theme } from "theme"

export const createStyles = () => {
    return {
        badge: {
            indicator: {
                color: theme.background.primary,
                backgroundColor: theme.background.third,
                borderColor: theme.background.white,
            }
        },
        col: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            width: 'fit-content',
        }
    }
}