import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
    getAction,
    getLocationsAction,
    getOverviewAction,
    getFacilitiesAction,
    getRoomtypesAction,
    bookingAction,
    cancelBookingAction,
    getBookingDetailAction,
    getAccommodationAction,
    getChainBrandsAction,
    getPropertyAction,
    addCartAction,
    paymentBookingAction
} from './actions'
import {
    getRequest,
    getLocationsRequest,
    getOverviewRequest,
    getFacilitiesRequest,
    getRoomtypesRequest,
    getPoliciesRequest,
    bookingRequest,
    cancelBookingRequest,
    getBookingDetailRequest,
    getAccommodationRequest,
    getChainBrandsRequest,
    getPropertyRequest,
    addCartRequest,
    paymentBookingRequest
} from './reducer';
import { getPoliciesAction } from './actions/getPolicies';

export default [
    takeEvery(getRequest, getAction),
    takeEvery(getLocationsRequest, getLocationsAction),
    takeEvery(getOverviewRequest, getOverviewAction),
    takeEvery(getFacilitiesRequest, getFacilitiesAction),
    takeEvery(getRoomtypesRequest, getRoomtypesAction),
    takeEvery(getPoliciesRequest, getPoliciesAction),
    takeLatest(bookingRequest, bookingAction),
    takeLatest(cancelBookingRequest, cancelBookingAction),
    takeEvery(getBookingDetailRequest, getBookingDetailAction),
    takeEvery(getAccommodationRequest, getAccommodationAction),
    takeEvery(getChainBrandsRequest, getChainBrandsAction),
    takeEvery(getPropertyRequest, getPropertyAction),
    takeEvery(addCartRequest, addCartAction),
    takeLatest(paymentBookingRequest, paymentBookingAction)
]