import { useDispatch, useSelector } from "react-redux";
import { getNotifier } from "./store/actions";
import { data, loading, page, pageSize, total } from "./store/selector";

export default function Hook() {
    const dispatch = useDispatch();
    const list = useSelector(data);
    const isLoading = useSelector(loading);
    const totalList = useSelector(total);
    const pageSizeCount = useSelector(pageSize);
    const currentPage = useSelector(page);
    const getList = (params, onSuccess?, onFailure?) => {
        dispatch(
            getNotifier(params, onSuccess, onFailure))
    }
    return {
        isLoading,
        getList,
        list,
        totalList,
        pageSizeCount,
        currentPage,
    }
}