import React, { FC, memo, useEffect, useState } from "react";
import { DatePicker, DatePickerProps } from "antd";
import InputMask from "inputmask-core";
import dayjs from "dayjs";
import { areEqual } from "utils/libs";

type IProps = DatePickerProps & {
    onChangeDate?: (value: Date) => void;
};

const DatePickerCus: FC<IProps> = ({ onChangeDate, ...props }) => {
    const [value, setValue] = useState<any>(props.value);
    const [, setText] = useState("");
    const mask = new InputMask({ pattern: "11/11/1111" });

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (value !== props.value) {
            onChangeDate?.(value);
        }
    }, [value]);

    return (
        <DatePicker
            {...props}
            value={value}
            // onPanelChange={(value) => setValue(value)}
            onChange={(e) => {
                if (!e) {
                    setValue(null);
                    setText("");
                    mask.paste("");
                }
                console.log(e);
                setValue(e)
            }}
            popupClassName="datePicker"
            // htmlTitle={{ format: "DD/MM/YYYY" }}
            onKeyDown={(e) => {
                if (e.key !== "Backspace") {
                    setText(old => {
                        if (old.length >= 8) return old;
                        const newText = old + e.key;
                        mask.paste(newText);
                        if (newText.length >= 8) {
                            const isValid = dayjs(mask.getValue(), "DD/MM/YYYY").isValid();
                            isValid && setValue(dayjs(mask.getValue(), "DD/MM/YYYY"));
                        }
                        return newText;
                    });
                } else {
                    setText(old => {
                        const newText = old.slice(0, -1);
                        if (newText.length <= 0) {
                            setValue(null);
                            mask.paste(newText);
                        }
                        return newText;
                    });
                }
            }}
        />
    )
}

export default memo(DatePickerCus, areEqual);