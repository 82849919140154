export default {
    // hotel: 'Khách sạn',
    // motel: 'Nhà nghỉ cho thuê',
    // resultSearch: {
    //     1: 'Có ',
    //     2: ' {{0}} kết quả ',
    //     3: ' vé phù hợp với bạn',
    // },
    // distance: 'Khoảng cách {{0}} {{1}}',
    // compareAgoda: 'So sánh giá với Agoda',
    // viewDetail: 'Chi tiết',
    // type: 'Loại cơ sở lưu trú',
    // spa: 'Spa',
    // swim: 'Bể bơi',
    // price: 'Giá',
    // sort: 'Thứ tự sắp xếp',
    // star: 'Điểm đánh giá',
    // filter: 'Bộ lọc',
    // person: '{{0}} người, {{1}} phòng',
    // roomCount: 'Số phòng: {{0}}',
    // rooms: 'Số phòng',
    // adult: 'Người lớn',
    // child: 'Trẻ em',
    // delRoom: 'Xóa phòng',
    // addRoom: 'Thêm phòng',
    // apply: 'Áp dụng',
    // searchPlaceholder: 'Tìm theo tên địa điểm',
    // search: 'Tìm kiếm',
    // country: {
    //     default: 'Chọn quốc gia *',
    //     default2: 'Quốc gia',
    //     tooltip: 'Chọn quốc gia',
    // },
    // radiusFromTheCenter: {
    //     0: 'Bán kính 1km',
    //     1: 'Bán kính 5km',
    //     2: 'Bán kính 10km',
    //     3: 'Bán kính 15km',
    // },
    // markup: {
    //     default: 'Markup'
    // },
    // searchSetting: 'Cài đặt mặc định',
    // searchResult: '{{0}} kết quả',
    // clearFilter: 'Xóa bộ lọc',
    // sortBy: {
    //     default: 'Sắp xếp theo',
    //     closest: 'Mức độ phổ biến',
    //     lowestPrice: 'Giá thấp nhất',
    //     higheststar: 'Điểm xếp hạng cao nhất',
    //     highestReview: 'Nhiều bài đánh giá nhất',
    //     minDistance: 'Khoảng cách gần nhất',
    // },
    // types: {
    //     default: 'Loại cơ sở lưu trú',
    //     hotel: 'Khách sạn',
    //     spaHotel: 'Khách sạn có Spa',
    //     hotelWithBreakfast: 'Khách sạn có bữa sáng',
    //     motel: 'Nhà nghỉ cho thuê',
    // },
    // promotion: {
    //     title: 'Ưu đãi',
    //     freeCancel: 'Hủy miễn phí',
    //     orther: 'Các khuyến mãi đặc biệt khác',
    // },
    // hotelStar: 'Hạng khách sạn',
    // rank: {
    //     'all': 'Tất cả',
    //     '2s': '2 sao',
    //     '2sSub': 'Tiêu chuẩn',
    //     '3s': '3 sao',
    //     '3sSub': 'Tiện ích',
    //     '4s': '4 sao',
    //     '4sSub': 'Cao cấp',
    //     '5s': '5 sao',
    //     '5sSub': 'Hàng đầu',
    //     '6s': '6 sao',
    //     '6sSub': 'Sang trọng',
    //     '7s': '7 sao',
    //     '7sSub': 'Tuyệt vời',
    //     "tooltip": "Tỉ lệ sao",
    // },
    // rankDes: 'Khách sạn {{0}} sao',
    // convenient: {
    //     title: 'Tiện nghi',
    //     wifi: 'Wifi',
    //     parking: 'Đỗ xe',
    //     swim: 'Bể bơi',
    //     spa: 'Spa',
    //     airCondition: 'Điều hòa',
    //     child: 'Phù hợp trẻ em',
    // },
    // sustainable: {
    //     title: 'Tính bền vững',
    //     eco: 'Chứng nhận Eco',
    //     abc: 'Chứng nhận ABC',
    // },
    // brand: 'Thương hiệu',
    // view_maps: 'Xem bản đồ',
    // detail: {
    //     overview: 'Tổng quan',
    //     facilities: 'Tiện ích hotel',
    //     roomtypes: 'Phòng & Giá',
    //     policies: 'Chính sách & Quy định',
    //     comment: '{{0}} điểm / {{1}} nhận xét',
    //     popularityScore: '#{{0}} trong các khách sạn tại {{2}}',
    //     showInMap: 'TRÊN BẢN ĐỒ',
    //     propertyGroups: 'Tiện nghi',
    //     convenientPlaces: 'Tin hữu ích',
    //     localPlaces0: "Địa điểm gần đây",
    //     localPlaces1: "Địa điểm nổi tiếng",
    //     showLess: 'Ẩn bớt',
    //     showMore: 'Xem thêm',
    //     emptyData: 'Chưa có dữ liệu',
    //     speciality: 'Điểm đặc trưng',
    //     perNight: '/Đêm',
    //     promotionPrice: '{{0}}% chỉ hôm nay',
    //     from: 'Từ',
    //     freeCancellation: 'Huỷ miễn phí',
    //     freeCancellationTooltip: 'Miễn phí hủy phòng trước {{date}}',
    //     surcharges: 'Tiện ích cộng thêm',
    //     surchargeContent:'Các tiện ích cộng thêm này sẽ thanh toán tại khách sạn nếu có sử dụng',
    //     extraBeds:'{{0}} giường phụ',
    //     maxOccupancyPerRoom: 'Tiêu chuẩn: {{0}} người',
    //     sizeOfRoom:'Diện tích phòng: {{0}} m²',
    //     freeBreakfast: 'Bữa sáng miễn phí',
    //     freeWifi: 'Wifi miễn phí',
    // },
    // rating: {
    //     default: 'Tỉ lệ sao',
    // },
    // currency: {
    //     default: 'Tiền tệ',
    // },
    // save: 'Lưu',
    // totalPrice: 'Tổng giá: {{price}} {{currency}}',
    // totalPriceSub: '(Đã bao gồm thuế & phí)',
    // bookNow: 'Thanh toán',
    // addToCart: 'Thêm chào giá',
    // cart: 'Chào giá',
    // totalPriceOption: 'Tổng giá tùy chọn {{0}}',
    // offersOptions: 'Chọn Gói để thêm vào gói chào giá',
    // option: "Tùy chọn {{name}}",
    // confirm: 'Xác nhận',
    // customer_index: 'Khách hàng {{index}}',
    // nationalityIso2: 'Quốc tịch',
    // payment_fill_form: 'Điền thông tin',
    // room_info: 'Thông tin đơn',
    // hotel_info: 'Thông tin khách sạn',
    // customerInfo: 'Thông tin khách hàng',
    // customer_info: 'Thông tin khách hàng cư trú phòng {{room}}',
    // fullName: 'Họ và tên',
    // email: 'Email',
    // phoneNumber: 'Số điện thoại',
    // pin: "CMND/ CCCD/ PASSPORT",
    // dob: 'Ngày sinh',
    // time_info: 'Thời gian đặt',
    // checkInDT: "Ngày nhận phòng",
    // checkOutDT: 'Ngày trả phòng',
    // policy_info: 'Điều khoản & Điều kiện',
    // policyHotel: 'Quy định của chỗ nghỉ',
    // policyInfantAge: 'Trẻ sơ sinh {{0}}-{{1}} tuổi (bao gồm cả bé {{2}} tuổi)',
    // policyInfantAgeSubInfo: 'Cung cấp cũi/nôi em bé theo yêu cầu.',
    // policyChildrenAge: 'Trẻ em {{0}}-{{1}} tuổi (bao gồm cả bé {{2}} tuổi)',
    // policyChildrenAgeSubInfo: 'Nếu cần một giường phụ thì sẽ phụ thu thêm.',
    // policyAdultAge: 'Những khách từ {{0}} tuổi trở lên sẽ được tính là người lớn',
    // policyAdultAgeSubInfo: 'Cần đặt thêm một giường phụ và sẽ phụ thu thêm.',
    // policyStayFree0: 'Phải sử dụng giường phụ.',
    // policyStayFree1: 'Ở miễn phí nếu sử dụng giường có sẵn.',
    // otherPolicy: 'Quy định khác',
    // updating: 'Chưa được cập nhật',
    // booking_condition: 'Tôi muốn nhận thông tin về chương trình ưu đãi & khuyến mãi đặc biệt từ Bedlinker.Tôi hiểu có thẻ bỏ theo dõi khi không muốn nhận thông tin vào bất kỳ lúc nào',
    // booking_condition_content1: 'Tôi đã hiểu & Đồng ý với ',
    // booking_condition_content2: 'Điều khoản sử dụng chung',
    // booking_condition_content3: ' và ',
    // booking_condition_content4: 'Chính sách quyền riêng tư',
    // attention: 'Chú ý',
    // attention_content: '- Hoàn hủy miễn phí trong 24h',
    // attention_content_1: '- Vui lòng điền thông tin chính xác. Khi đã gửi thông tin, bạn sẽ không thể thay đổi được',
    // add_customer: 'Thêm người cư trú',
    // bill: 'Hóa đơn',
    // bookingDate: 'Ngày đặt',
    // bookingCode: 'Mã đặt chỗ',
    // expiredRequest: 'Vui lòng thanh toán trước',
    // afterBookingDate: 'Sau thời gian trên mã đặt chỗ sẽ bị hủy',
    // roomTitle: 'Phòng',
    // occupancy: 'Sức chứa',
    // priceBill: 'Giá ( 1 phòng x 1 đêm)',
    // priceBillinfo:'(Đã bao gồm thuế & phí)',
    // totalBill: 'Tổng tiền',
    // payment: 'Thanh toán',
    // occupancyDetail: '{{num}} người',
    // payment_method_choose: 'Chọn phương thức thanh toán',
    // pay_now: 'Thanh toán ngay',
    // pay_now_desc: 'Bằng hạn mức có sẵn',
    // pay_later: 'Thanh toán sau',
    // booking: 'Đặt phòng',
    // pay_later_desc: 'Đặt trước & thanh toán sau ',
    // balance: 'Số dư: {{balance}} đ',
    // charge: 'Nạp tiền',
    // balanceWarning: 'Số dư của bạn không đủ để thanh toán, vui lòng ấn nạp tiền để nạp',
    // voucher: 'Mã giảm giá',
    // booking_success: 'Đặt phòng thành công',
    // cancel_success: 'Hủy phòng thành công',
    // cancel_success1: 'Vui lòng kiểm tra lại danh sách đặt phòng',
    // payment_detail_form: 'Chi tiết đơn hàng',
    // download_payment_detail_form: 'Tải chi tiết đơn hàng xuống',
    // sendByEmail: 'Gửi qua email',
    // main_contact_customer: 'Người liên lạc chính',
    // gotoManageBooking: 'Đến trang quản lý đặt phòng',
    // statusBill: {
    //     success: 'Đặt phòng thành công',
    //     processing: 'Đặt phòng chưa thành công',
    //     error: 'Đặt phòng thất bại',
    //     warning: 'Đang đặt phòng',
    //     default: 'Hủy đặt phòng'
    // },
    // all: "Bất kỳ",
    // ageOfchild: "Tuổi của trẻ em {{child}}",
    // searchError: {
    //     title: 'Lỗi',
    //     message: 'Vui lòng chọn địa điểm',
    // },
    // currentLocation: 'Vị trí hiện tại',
    // warning: 'Cảnh báo',
    // warningLocationContent: 'Vui lòng bật định vị để sử dụng tính năng này',
    // numberOfRoom: 'Số phòng: {{0}}',
    // remainingRooms: 'Số phòng còn lại: {{0}}',
    // detailRoom: 'Ảnh phòng & tiện ích',
    // booking_condition_required: 'Vui lòng đồng ý với điều khoản & điều kiện',
    // error: 'Lỗi',
    // loginRequired: 'Bạn cần đăng nhập để thực hiện chức năng này',
    // requiredField: 'Vui lòng không bỏ trống trường này',
    // emailInvalid: 'Email không hợp lệ',
    // phoneInvalid: 'Số điện thoại không hợp lệ',
    // pickEndDate: 'Chọn ngày hết hạn',
    // cancelBooking: 'Hủy đặt phòng',
    // unableCancelBooking: 'Không thể hủy đặt phòng',
    // cartOption: 'Chào giá {{0}}',
    // flight: 'Vé máy bay',
    // cars: 'Xe sân bay',
    // cartAuthenRequire: 'Bạn cần đăng nhập để xem giỏ hàng',
    // confirmCancelBooking: 'Xác nhận hủy đặt phòng',
    // cancelReason: 'Lý do hủy phòng',
    // reasons: {
    //     reason0: 'Lý do khác',
    //     reason13: 'Đặt trực tiếp với khách sạn',
    //     reason14: 'Buộc phải hủy hoặc hoãn chuyến đi',
    //     reason15: 'Chọn một khách sạn khác không được cung cấp bởi agoda',
    //     reason16: 'Đặt một khách sạn khác qua agoda',
    //     reason17: 'Tìm thấy giá thấp hơn trên Internet',
    //     reason18: 'Tìm thấy giá thấp hơn qua một đại lý địa phương',
    //     reason19: 'Không thích điều khoản thanh toán',
    //     reason20: 'Không thích điều khoản hủy',
    //     reason22: 'Lo ngại về độ tin cậy',
    //     reason23: 'Lo ngại về độ an toàn',
    //     reason25: 'Đặt phòng không được xác nhận nhanh',
    //     reason44: 'Gặp thảm họa tự nhiên',
    // },
    // otherReason: 'Lý do khác',
    // paymentMethod: 'Phương thức thanh toán',
    // PAYNOW: 'Thanh toán ngay',
    // PAYLATER: 'Thanh toán sau',
    // detailOrder: 'Chi tiết đặt hàng',
    // detailService: 'Chi tiết dịch vụ',
    // detailCancelBooking: 'Chi tiết hủy',
    // refundable: 'Có thể hoàn tiền',
    // nonRefundable: 'Không thể hoàn tiền',
    // detailServiceInfo: 'Chú ý: Giá đã bao gồm Thuế Nhà nước, Phí dịch vụ và Thuế thành phố',
    // detailCancelBookingInfo: 'Thời gian được tính dựa trên thời gian khu vực bạn ở',
    // sendEmail: 'Gửi Email',
    // emailInfoText: 'Bạn có thể nhập nhiều tài khoản email, tách biệt bằng dấu phẩy (,)',
    // receiverEmail: 'Email người nhận',
    // sendMailSuccess: 'Gửi email thành công',
    // sendMailFail: 'Gửi email thất bại',
    // agentCode: 'Mã Agent',
    // isPaid: 'Tình trạng thanh toán',
    // paid: 'Đã thanh toán',
    // unpaid: 'Chưa thanh toán',
    // sameDateError: 'Ngày nhận phòng và ngày trả phòng không được trùng nhau',
    hotel: 'Hotel',
    motel: 'Motel',
    resultSearch: {
        1: 'There are ',
        2: ' {{0}} results ',
        3: ' suitable for you',
    },
    distance: '{{0}} {{1}} away',
    compareAgoda: 'Compare price with Agoda',
    viewDetail: 'View detail',
    type: 'Type of accommodation',
    spa: 'Spa',
    swim: 'Swimming pool',
    price: 'Price',
    sort: 'Sort',
    star: 'Star',
    filter: 'Filter',
    person: '{{0}} people, {{1}} room',
    roomCount: 'Number of rooms: {{0}}',
    rooms: 'Rooms',
    adult: 'Adult',
    child: 'Child',
    delRoom: 'Delete room',
    addRoom: 'Add room',
    apply: 'Apply',
    searchPlaceholder: 'Search by location name',
    search: 'Search',
    country: {
        default: 'Select country *',
        default2: 'Country',
        tooltip: 'Select country',
    },
    radiusFromTheCenter: {
        0: '1km radius',
        1: '5km radius',
        2: '10km radius',
        3: '15km radius',
    },
    markup: {
        default: 'Markup'
    },
    searchSetting: 'Default settings',
    searchResult: '{{0}} results',
    clearFilter: 'Clear filter',
    sortBy: {
        default: 'Sort by',
        closest: 'Popularity',
        lowestPrice: 'Lowest price',
        higheststar: 'Highest star rating',
        highestReview: 'Most reviews',
        minDistance: 'Closest distance',
    },
    types: {
        default: 'Type of accommodation',
        hotel: 'Hotel',
        spaHotel: 'Spa hotel',
        hotelWithBreakfast: 'Hotel with breakfast',
        motel: 'Motel',
    },
    promotion: {
        title: 'Promotion',
        freeCancel: 'Free cancel',
        orther: 'Other special offers',
    },
    hotelStar: 'Hotel star',
    rank: {
        'all': 'All',
        '2s': '2 star',
        '2sSub': 'Standard',
        '3s': '3 star',
        '3sSub': 'Convenient',
        '4s': '4 star',
        '4sSub': 'High class',
        '5s': '5 star',
        '5sSub': 'Top class',
        '6s': '6 star',
        '6sSub': 'Luxury',
        '7s': '7 star',
        '7sSub': 'Excellent',
        "tooltip": "Star rating",
    },
    rankDes: '{{0}} star hotel',
    convenient: {
        title: 'Convenient',
        wifi: 'Wifi',
        parking: 'Parking',
        swim: 'Swimming pool',
        spa: 'Spa',
        airCondition: 'Air conditioning',
        child: 'Child friendly',
    },
    sustainable: {
        title: 'Sustainable',
        eco: 'Eco certification',
        abc: 'ABC certification',
    },
    brand: 'Brand',
    view_maps: 'View map',
    detail: {
        overview: 'Overview',
        facilities: 'Hotel facilities',
        roomtypes: 'Rooms & Prices',
        policies: 'Policies & Rules',
        comment: '{{0}} point / {{1}} reviews',
        popularityScore: '#{{0}} in hotels in {{2}}',
        showInMap: 'ON MAP',
        propertyGroups: 'Facilities',
        convenientPlaces: 'Useful information',
        localPlaces0: "Nearby places",
        localPlaces1: "Famous places",
        showLess: 'Show less',
        showMore: 'Show more',
        emptyData: 'No data',
        speciality: 'Specialty',
        perNight: '/Night',
        promotionPrice: '{{0}}% only today',
        from: 'From',
        freeCancellation: 'Free cancellation',
        freeCancellationTooltip: 'Free cancellation before {{date}}',
        surcharges: 'Additional surcharges',
        surchargeContent: 'These additional surcharges will be paid at the hotel if used',
        extraBeds: '{{0}} extra beds',
        maxOccupancyPerRoom: 'Standard: {{0}} people',
        sizeOfRoom: 'Room size: {{0}} m²',
        freeBreakfast: 'Free breakfast',
        freeWifi: 'Free wifi',
    },
    rating: {
        default: 'Star rating',
    },
    currency: {
        default: 'Currency',
    },
    save: 'Save',
    totalPrice: 'Total price: {{price}} {{currency}}',
    totalPriceSub: '(Including tax & fees)',
    bookNow: 'Book now',
    addToCart: 'Add to cart',
    cart: 'Cart',
    totalPriceOption: 'Total price option {{0}}',
    offersOptions: 'Select a package to add to the quote package',
    option: "Option {{name}}",
    confirm: 'Confirm',
    customer_index: 'Customer {{index}}',
    nationalityIso: 'National',
    nationalityIso2: 'National',
    payment_fill_form: 'Fill in the information',
    room_info: 'Room information',
    hotel_info: 'Hotel information',
    customerInfo: 'Customer information',
    customer_info: 'Customer information staying in room {{room}}',
    fullName: 'Full name',
    email: 'Email',
    phoneNumber: 'Phone number',
    pin: "ID/ CCCD/ PASSPORT",
    dob: 'Date of birth',
    time_info: 'Booking time',
    checkInDT: "Check-in date",
    checkOutDT: 'Check-out date',
    policy_info: 'Terms & Conditions',
    policyHotel: 'Hotel regulations',
    policyInfantAge: 'Infants {{0}}-{{1}} years old (including {{2}} years old)',
    policyInfantAgeSubInfo: 'Provide baby cot/cradle upon request.',
    policyChildrenAge: 'Children {{0}}-{{1}} years old (including {{2}} years old)',
    policyChildrenAgeSubInfo: 'If an extra bed is needed, an extra charge will be applied.',
    policyAdultAge: 'Guests from {{0}} years old and above will be considered as adults',
    policyAdultAgeSubInfo: 'An extra bed is needed and an extra charge will be applied.',
    policyStayFree0: 'An extra bed is required.',
    policyStayFree1: 'Stay for free if using existing bedding.',
    otherPolicy: 'Other regulations',
    updating: 'Not updated',
    booking_condition: 'I want to receive information about special offers & promotions from Bedlinker. I understand that I can unsubscribe at any time',
    booking_condition_content1: 'I have understood & agreed with ',
    booking_condition_content2: 'Terms of use',
    booking_condition_content3: ' and ',
    booking_condition_content4: 'Privacy policy',
    attention: 'Attention',
    attention_content: '- Free cancellation within 24h',
    attention_content_1: '- Please fill in the correct information. Once submitted, you will not be able to change it',
    attention_content_date: "- Risk-free booking! Cancel before {{freeCancellationDate}} and you'll pay nothing! Any cancellation received after this date will be be charged for the entire stay. Failure to arrive at your hotel or property will be treated as a No-Show and will incur a charge of 100% of the booking value.",
    add_customer: 'Add resident',
    bill: 'Bill',
    bookingDate: 'Booking date',
    bookingCode: 'Booking code',
    expiredRequest: 'Please pay before',
    afterBookingDate: 'After the above time the booking code will be canceled',
    roomTitle: 'Room',
    occupancy: 'Occupancy',
    priceBill: 'Price ( 1 room x 1 night)',
    priceBillinfo: '(Including tax & fees)',
    totalBill: 'Total bill',
    payment: 'Payment',
    occupancyDetail: '{{num}} people',
    payment_method_choose: 'Choose payment method',
    pay_now: 'Pay now',
    pay_now_desc: 'By available credit limit',
    pay_later: 'Pay later',
    booking: 'Booking',
    pay_later_desc: 'Book now & pay later ',
    balance: 'Balance: {{balance}} đ',
    charge: 'Charge',
    balanceWarning: 'Your balance is not enough to pay, please press charge to top up',
    voucher: 'Discount code',
    booking_success: 'Booking success',
    cancel_success: 'Cancel success',
    cancel_success1: 'Please check the booking list again',
    payment_detail_form: 'Order details',
    download_payment_detail_form: 'Download order details',
    sendByEmail: 'Send by email',
    main_contact_customer: 'Main contact',
    gotoManageBooking: 'Go to manage booking',
    statusBill: {
        success: 'Booking success',
        processing: 'Booking not success',
        error: 'Booking failed',
        warning: 'Booking',
        default: 'Cancel booking',
        volcano: 'Cancelling',
        purple: 'Cancel booking fail',
        cyan: 'Completed'
    },
    all: "Any",
    ageOfchild: "Age of child {{child}}",
    searchError: {
        title: 'Error',
        message: 'Please select location',
    },
    currentLocation: 'Current location',
    warning: 'Warning',
    warningLocationContent: 'Please turn on location to use this feature',
    numberOfRoom: 'Number of rooms: {{0}}',
    remainingRooms: 'Remaining rooms: {{0}}',
    detailRoom: 'Room & facility images',
    booking_condition_required: 'Please agree to the terms & conditions',
    error: 'Error',
    loginRequired: 'You need to login to perform this function',
    requiredField: 'Please do not leave this field blank',
    emailInvalid: 'Invalid email',
    phoneInvalid: 'Invalid phone number',
    pickEndDate: 'Choose expiration date',
    cancelBooking: 'Cancel booking',
    unableCancelBooking: 'Unable to cancel booking',
    cartOption: 'Quote {{0}}',
    flight: 'Flight',
    cars: 'Airport car',
    cartAuthenRequire: 'You need to login to view cart',
    confirmCancelBooking: 'Confirm cancel booking',
    cancelReason: 'Cancel reason',
    reasons: {
        reason0: 'Other reason',
        reason13: 'Direct booking with hotel',
        reason14: 'Forced to cancel or postpone the trip',
        reason15: 'Choose another hotel not provided by agoda',
        reason16: 'Book another hotel through agoda',
        reason17: 'Found a lower price on the Internet',
        reason18: 'Found a lower price through a local agent',
        reason19: 'Do not like payment terms',
        reason20: 'Do not like cancellation terms',
        reason22: 'Concerned about reliability',
        reason23: 'Concerned about safety',
        reason25: 'Booking not confirmed quickly',
        reason44: 'Encounter natural disaster',
    },
    otherReason: 'Other reason',
    paymentMethod: 'Payment method',
    PAYNOW: 'Pay now',
    PAYLATER: 'Pay later',
    detailOrder: 'Order details',
    detailService: 'Service details',
    detailCancelBooking: 'Cancel details',
    refundable: 'Refundable',
    nonRefundable: 'Non-refundable',
    detailServiceInfo: 'Note: Price includes State Tax, Service Fee and City Tax',
    detailCancelBookingInfo: 'Time is calculated based on your area',
    sendEmail: 'Send Email',
    emailInfoText: 'You can enter multiple email accounts, separated by commas (,)',
    receiverEmail: 'Receiver email',
    sendMailSuccess: 'Send email success',
    sendMailFail: 'Send email fail',
    agentCode: 'Agent code',
    isPaid: 'Payment status',
    paid: 'Paid',
    unpaid: 'Unpaid',
    sameDateError: 'Check-in and check-out dates cannot be the same',
}