import React, { FC, memo } from "react";
import { Button, Col, Input, Popover, Row, Typography } from "antd";
import Icons from 'assets/icons';
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import { t } from "i18next";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

interface IProps {
    counts?: any
    setCounts?: any
}

interface IPropsPlusMinus {
    value?: any
    option?: any,
    onPlus: () => void,
    onMinus: () => void
}

const PlusMinus: FC<IPropsPlusMinus> = ({ value,
    option, onPlus, onMinus }) => {
    const styles = createStyles();
    return (

        <Row
            justify={'space-between'}
            align={'middle'}
            style={{ gap: 6 }}
            gutter={[12, 12]}

        >
            <Col>
                <Row
                    align={'middle'}
                    style={{ gap: 6 }}>
                    <option.icon />
                    <Col
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        <Typography
                            style={styles.titlePerson}
                        >
                            {option.title}
                        </Typography>
                        <span>  {option.description}</span>
                    </Col>
                </Row>
            </Col>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 136,

                }}
            >
                <Button
                    icon={<MinusOutlined />}
                    onClick={onMinus}
                />
                {/* <InputNumberAntd
                    {...props}
                    //value={value}
                    variant='borderless'
                    controls={false}
                    style={{
                        width: 48,
                        display: 'flex',
                        textAlign: 'center',
                        justifyItems: 'center',
                    }}
                >
                    {value}
                </InputNumberAntd> */}
                <div style={{
                    padding: '0 20px',
                    display: 'flex',
                    textAlign: 'center',
                    justifyItems: 'center',
                }}>{value}</div>
                <Button
                    icon={<PlusOutlined />}
                    onClick={onPlus}
                />
            </div>
        </Row>

    )
}

const PersonFilter: FC<IProps> = ({counts, setCounts}) => {
    const styles = createStyles();

    const options = [
        {
            id: 'adults',
            icon: Icons.adultIcon,
            title: t('hotel.adult'),
            description: '',
        },
        {
            id: 'children',
            icon: Icons.childIcon,
            title: t('hotel.child'),
            description: t('(2-12 tuổi)'),
        },
        {
            id: 'babies',
            icon: Icons.bedIcon,
            title: t('Em bé'),
            description: t('(Dưới 2 tuổi)'),
        },
    ];

    const isValidPassengers = newCounts => {
        const { adults, babies, total } = newCounts;

        if (total > 9) {
            return false;
        }

        if (adults < 1) {
            return false;
        }

        if (babies > adults) {
            return false;
        }

        return true;
    };
    const onMinus = id => {
        setCounts(prevCounts => {
            const newCounts = {
                ...prevCounts,
                [id]: prevCounts?.[id] > 0 ? prevCounts?.[id] - 1 : prevCounts?.[id],
                total:
                    id !== 'babies' && prevCounts?.[id] > 0 && prevCounts?.total > 0 ? prevCounts?.total - 1 : prevCounts?.total,
            };

            if (isValidPassengers(newCounts)) {
                return newCounts;
            }

            return prevCounts;
        });
    };

    const onPlus = id => {
        setCounts(prevCounts => {
            const newCounts = {
                ...prevCounts,
                [id]: prevCounts?.[id] + 1,
                total: id !== 'babies' ? prevCounts?.total + 1 : prevCounts?.total,
            };

            if (isValidPassengers(newCounts)) {
                return newCounts;
            }

            return prevCounts;
        });
    };

    const content = () => (
        <>
            <div
                style={styles.personFilterContainer as any}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 12,
                        height: 'fit-content'
                    }}
                >

                    {options?.map((option) => (
                        <PlusMinus
                            key={option?.id}
                            option={option}
                            // isLastChild={index === options?.length - 1}
                            value={counts?.[option?.id] || 0}
                            onPlus={() => onPlus(option?.id)}
                            onMinus={() => onMinus(option?.id)}
                        />

                    ))}
                </div>
            </div>
        </>
    )

    return (
        <Popover
            arrow={false}
            content={content}
            trigger={'click'}
            placement='bottom'
        >
            <Input
                suffix={<Icons.userIcon />}
                style={{ ...styles.input, cursor: 'pointer' }}
                readOnly
                value={`${counts?.total} hành khách`}
            // value={t('hotel.person', { 0: value?.Adults + value?.Children, 1: value?.Count })}
            />
        </Popover>
    )
}
export default memo(PersonFilter, areEqual);