import { call, put } from 'redux-saga/effects';

export default function* requestSuccess({
    successAction,
    data,
    extraParams,
    callback,
    onSuccess,
}) {
    if (Array.isArray(data)) {
        yield put(successAction(data))
    } else yield put(successAction({ ...data, extraParams }))
    if (callback) yield call(callback, { ...data });
    if (onSuccess) yield call(onSuccess, { ...data });
}
