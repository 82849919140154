import React, { FC, memo, useEffect, useMemo, useState } from "react";
import { Button, ButtonProps } from "antd";
import { t } from "i18next";
import { areEqual } from "utils/libs";
import styles from "./styles";
import Icons from "assets/icons";
import { theme } from "theme";

type IProps = ButtonProps & {
    label: string;
    useI18n?: boolean;
    onChange?: (boolean) => void
    iconName?: string
    active?: boolean
}

const RadioButton: FC<IProps> = ({ label, useI18n = true, iconName, icon, onChange, style, ...props }) => {
    const [active, setActive] = useState<boolean>(false)

    const styleActive = useMemo(() => active ? styles?.['active'] : styles?.['deactive'], [active, styles])

    useEffect(() => {
        onChange?.(active)
    }, [active])

    useEffect(() => {
        props?.['active'] !== undefined && setActive(props?.['active'])
    }, [props?.['active']])


    return (
        <Button
            {...props}
            icon={(iconName && Icons?.[iconName])
                ? Icons?.[iconName]({
                    color: active ? theme.background.primary : theme.background.black
                })
                : icon
            }
            style={{ ...style, ...styleActive }}
            onClick={() => setActive(old => !old)}
        >{
                useI18n ? t(label) : label
            }
        </Button>
    );
};

export default memo(RadioButton, areEqual);
