import React, { FC, memo, useEffect, useState } from "react";
import { Button, InputNumberProps } from "antd";
import { areEqual } from "utils/libs";
import { InputNumber as InputNumberAntd } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";


type IProps = InputNumberProps & {
    button?: boolean;
};
const InputNumber: FC<IProps> = ({ button, ...props }) => {

    const [value, setValue] = useState<number>((props?.value || props?.defaultValue || 0) as number);

    useEffect(() => {
        props?.onChange?.(value);
    }, [value]);

    useEffect(() => {
        setValue((props?.value ?? props?.defaultValue ?? 0) as number);   
    }, [props?.defaultValue, props?.value]);

    return (
        <>
            {
                button
                    ? (
                        <>
                            {
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 136,
                                        ...props?.style
                                    }}
                                >
                                    <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => setValue(old => old - 1)}
                                        disabled={(value <= (props?.min as number || 0))}
                                    />
                                    <InputNumberAntd
                                        {...props}
                                        value={value}
                                        variant='borderless'
                                        controls={false}
                                        style={{
                                            width: 48,
                                            display: 'flex',
                                            textAlign: 'center',
                                            justifyItems: 'center',
                                        }}
                                    >
                                        {props?.children as any}
                                    </InputNumberAntd>
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => setValue(old => old + 1)}
                                        disabled={(value >= (props?.max as number)) || false}
                                    />
                                </div>
                            }
                        </>
                    )
                    :
                    <InputNumberAntd
                        {...props}
                        
                    >
                        {props?.children as any}
                    </InputNumberAntd>
            }
        </>
    )
};

export default memo(InputNumber, areEqual);