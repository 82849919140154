import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { TextCus } from "components";
import { theme } from "theme";
import { areEqual } from "utils/libs";

interface IProps {
    value?: any
    setValue?: any
}

const HotelRank: FC<IProps> = ({ value, setValue }) => {
    const [hover, setHover] = useState('')
    const [active, setActive] = useState<number[]>(value)

    const selectStar = useCallback((key) => () => {
        setActive(old => {
            if (old?.includes(key)) {
                return old?.filter(item => item !== key)
            }
            return [...old, key]
        })
    }, [setActive])

    useEffect(() => {
        setActive(value)
    }, [JSON.stringify(value)])

    useEffect(() => {
        setValue(active)
    }, [JSON.stringify(active)])

    return (
        <Row
            style={{
                height: 'fit-content',
                borderRadius: 6,
                border: '1px solid #E5E5E5',
                marginTop: 12
            }}
        >
            <Col
                key='1'
                onMouseEnter={() => setHover('')}
                onMouseLeave={() => setHover('')}
                onClick={selectStar(0)}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === '0' ? theme.background.primary : '#E5E5E5'}`,
                    borderTopLeftRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active?.includes(0) ? theme.background.third : 'unset',
                }}>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active?.includes(0) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.2s
                </TextCus>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: active?.includes(0) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.2sSub
                </TextCus>
            </Col>
            <Col
                key='2'
                onMouseEnter={() => setHover('1')}
                onMouseLeave={() => setHover('')}
                onClick={selectStar(1)}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === '1' ? theme.background.primary : '#E5E5E5'}`,
                    borderTopRightRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active?.includes(1) ? theme.background.third : 'unset'
                }}>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active?.includes(1) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.3s
                </TextCus>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color:  active?.includes(1) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.3sSub
                </TextCus>
            </Col>
            <Col
                key='3'
                onMouseEnter={() => setHover('2')}
                onMouseLeave={() => setHover('')}
                onClick={selectStar(2)}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === '2' ? theme.background.primary : '#E5E5E5'}`,
                    borderBottomLeftRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor:  active?.includes(2) ? theme.background.third : 'unset'
                }}>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active?.includes(2) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.4s
                </TextCus>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: active?.includes(2) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.4sSub
                </TextCus>
            </Col>
            <Col
                key='4'
                onMouseEnter={() => setHover('3')}
                onMouseLeave={() => setHover('')}
                onClick={selectStar(3)}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === '3' ? theme.background.primary : '#E5E5E5'}`,
                    borderBottomRightRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active?.includes(3) ? theme.background.third : 'unset'
                }}>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active?.includes(3) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.5s
                </TextCus>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color:active?.includes(3) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.5sSub
                </TextCus>
            </Col>
            {/* <Col
                key='5'
                onMouseEnter={() => setHover('4')}
                onMouseLeave={() => setHover('')}
                onClick={selectStar(4)}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === '4' ? theme.background.primary : '#E5E5E5'}`,
                    borderBottomRightRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor:active?.includes(4) ? theme.background.third : 'unset'
                }}>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active?.includes(4) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.6s
                </TextCus>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: active?.includes(4) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.6sSub
                </TextCus>
            </Col>
            <Col
                key='6'
                onMouseEnter={() => setHover('5')}
                onMouseLeave={() => setHover('')}
                onClick={selectStar(5)}
                span={12}
                style={{
                    width: '180px',
                    height: '85px',
                    border: `1px solid ${hover === '5' ? theme.background.primary : '#E5E5E5'}`,
                    borderBottomRightRadius: 6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: active?.includes(5) ? theme.background.third : 'unset'
                }}>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '158%',
                        color: active?.includes(5) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.7s
                </TextCus>
                <TextCus
                    style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: active?.includes(5) ? theme.text.secondary : theme.text.black,
                    }}
                >
                    hotel.rank.7sSub
                </TextCus>
            </Col> */}
        </Row>
    )
}

export default memo(HotelRank, areEqual);