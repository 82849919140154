import { useDispatch, useSelector } from "react-redux";
import {
    createPaymentsNotifier,
    getBalanceNotifier,
    getCountriesNotifier,
    getNewsNotifier,
    getNewsTypeNotifier,
    getPaymentFeeNotifier,
    getPusblishConfigNotifier,
    getUserInfoNotifier,
    loginNotifier,
    getPaymentDetailNotifier,
    getLandingPageNotifier
} from "./store/actions";
import { balance, countries, getDataByKey, loading, loadingConfig, loadingPayment, paymentFee, publishedConfig, landingPage, loadingLandingPage } from "./store/selector";
import { useNotify } from "hooks";


export default function Hook() {
    const dispatch = useDispatch();
    const { errorFromServer } = useNotify()
    const login = (params, onSuccess?, onFailure?) => {
        dispatch(
            loginNotifier(params,
                () =>
                    getUserInfo(onSuccess, onFailure)
                , onFailure))
    }
    const getUserInfo = (onSuccess?, onFailure?) => {
        dispatch(getUserInfoNotifier({}, onSuccess, onFailure))
    }

    const getCountries = (params, onSuccess?, onFailure?) => {
        dispatch(getCountriesNotifier(params, res => onSuccess?.(res?.countries), onFailure))
    }

    const isLoading = useSelector(loading);
    const getBalance = (params, onSuccess?, onFailure?) => {
        dispatch(getBalanceNotifier(params, onSuccess, onFailure))
    }

    const balanceInfo = useSelector(balance);

    const getLandingPage = (params, onSuccess?, onFailure?) => {
        dispatch(getLandingPageNotifier(params, onSuccess, onFailure))
    }
    const isLoadingLandingPage = useSelector(loadingLandingPage);
    const landingPageData = useSelector(landingPage);

    const countriesData = useSelector(countries);
    const getPusblishConfig = (params, onSuccess?, onFailure?) => {
        dispatch(getPusblishConfigNotifier(params, onSuccess, onFailure))
    }
    const publishedConfigData = useSelector(publishedConfig);
    const getNews = (params, onSuccess?, onFailure?) => {
        dispatch(getNewsTypeNotifier(params, (res) => {
            const { items } = res
            if (items?.length) {
                dispatch(getNewsNotifier({
                    SiteId: params.siteId,
                    NewsTypeId: items[0].id,
                    Page: 1,
                    PageSize: 10,
                    extraParams: {
                        id: params.keywords
                    }
                }, onSuccess, onFailure))
            }
            else {
                onFailure?.()
            }
        }, onFailure))
    }
    const getNewsData = (key: string) => useSelector(getDataByKey(key));
    const isLoadingConfig = useSelector(loadingConfig);
    const getPaymentFee = (params, onSuccess?, onFailure?) => {
        dispatch(getPaymentFeeNotifier(params, onSuccess, onFailure))
    }
    const paymentFeeData = useSelector(paymentFee);
    const createPayments = (params, onSuccess?, onFailure?) => {
        dispatch(createPaymentsNotifier(params, onSuccess, (res)=>{
            errorFromServer(res)
            onFailure?.(res)
        }))
    }
    const isLoadingPayment = useSelector(loadingPayment);
    const paymentData = useSelector(getDataByKey("payment"));

    const getPaymentDetail = (params, onSuccess?, onFailure?) => {
        dispatch(getPaymentDetailNotifier(params, onSuccess, onFailure))
    }
    return {
        login,
        getCountries,
        isLoading,
        getBalance,
        balanceInfo,
        countriesData,
        getPusblishConfig,
        publishedConfigData,
        getNews,
        getNewsData,
        isLoadingConfig,
        getPaymentFee,
        paymentFeeData,
        createPayments,
        isLoadingPayment,
        paymentData,
        getPaymentDetail,
        getLandingPage,
        landingPageData,
        isLoadingLandingPage
    }
}