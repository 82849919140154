import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { bookingRequest, bookingSuccess, bookingFailed } from '../reducer'
import { booking } from '../../services';

export const bookingNotifier = (params: any = {},onSuccess, onFailure) => {
    return {
        type: bookingRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* bookingAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: booking,
        params: payload,
        cancelId: type,
        failureAction: bookingFailed,
        successAction: bookingSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}