import React, { FC, memo, useCallback, useEffect, useMemo } from "react";
import { Button, Divider, Form, Modal, Row, Radio, Slider, Checkbox } from "antd";
import { areEqual } from "utils/libs";
import Icons from "assets/icons";
import { TextCus } from "components";
import createStyles from "./styles";
import { t } from "i18next";
import { filterInit, optionsPromotion, optionsSort } from "./data";
import { theme } from "theme";
import HotelRank from "./HotelRank";
import Convenient from "./Convenient";
import Brand from "./Brand";
import { ISearchHotel } from "pages/Hotel/types";
import Hook from "pages/Hotel/hook";
import RatingAverage from "./RatingAverage";
import './styles.css'

interface IProps {
    open: boolean;
    onClose: () => void;
    value: ISearchHotel['Filter'];
    setValue: (value: ISearchHotel['Filter']) => void;
}

const FilterPopup: FC<IProps> = ({
    open,
    onClose,
    value,
    setValue
}) => {
    const styles = createStyles();
    const [form] = Form.useForm()
    const { accommodationData = [] } = Hook()
    const optionsType = useMemo(() => {
        return accommodationData?.map(item => ({
            label: item?.accommodationType,
            value: item.guid
        }))
    }, [JSON.stringify(accommodationData)])

    const handleClearFilter = useCallback(() => {
        form.resetFields()
        form.setFieldsValue(filterInit.Filter)
        setValue(filterInit.Filter)
    }, [form, setValue, filterInit.Filter])

    useEffect(() => {
        form.setFieldsValue(value)
    }, [JSON.stringify(value)])

    return (
        <>
            <Modal
                open={open}
                onCancel={onClose}
                title={
                    <Row align={'middle'} style={{ gap: 6 }}>
                        <Icons.filterIcon />
                        <TextCus style={styles.titleFilterPopup}>
                            hotel.filter
                        </TextCus>
                    </Row>
                }
                footer={
                    <>
                        <Divider style={{ margin: 12 }} />
                        <Row justify='space-between' align='middle'>
                            <Button
                                type='primary'
                                onClick={() => {
                                    form.submit()
                                    onClose()
                                }}
                            >{t('hotel.apply')}</Button>
                            <Button
                                type='text'
                                onClick={handleClearFilter}
                            >
                                {t('hotel.clearFilter')}
                            </Button>
                        </Row>
                    </>
                }
            >
                <Form
                    id='filterForm'
                    form={form}
                    layout='vertical'
                    style={styles.filterContent}
                    onFinish={val => setValue(val)}
                >
                    <div
                        style={styles.filterContainer as any}
                    >
                        <Form.Item
                            name='SearchHotelSortBy'
                            label={<TextCus style={styles.labelFilter}>hotel.sort</TextCus>}
                        >
                            <Radio.Group
                                style={styles.radioGroup}
                                value={value?.SearchHotelSortBy}
                            >
                                {optionsSort.map((item, index) => (
                                    <Radio
                                        key={index}
                                        value={item.value}
                                        style={styles.radio}
                                    >
                                        <TextCus style={styles.textFilter}>{item.label}</TextCus>
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            id='price'
                            name='price'
                            label={<TextCus style={{ ...styles.labelFilter, color: theme.text.black_900 }}>hotel.price</TextCus>}
                        >
                            <Slider
                                range
                                step={500000}
                                style={{
                                    marginTop: 40,
                                    marginLeft: 20,
                                }}
                                tooltip={{
                                    open: true,
                                    color: theme.background.orange_100,
                                    getPopupContainer :() => document.querySelector(".ant-slider-step") as HTMLElement,
                                    placement: 'top',
                                    formatter: (_value) => `${_value?.toLocaleString()} đ`
                                }}
                                min={0}
                                max={50000000}
                            />
                        </Form.Item>
                        <Form.Item
                            name='AccommodationTypeGuids'
                            label={<TextCus style={styles.labelFilter}>hotel.type</TextCus>}
                        >
                            {/* <Segmented
                                defaultValue={'hotel'}
                                options={[
                                    { label: t('hotel.hotel'), value: 'hotel' },
                                    { label: t('hotel.motel'), value: 'motel' },
                                ]}
                            /> */}
                            <Checkbox.Group
                                style={styles.checkboxGroup}
                                options={optionsType}
                            />
                        </Form.Item>
                        <Form.Item
                            name='offers'
                            label={<TextCus style={styles.labelFilter}>hotel.promotion.title</TextCus>}
                        >
                            <Checkbox.Group
                                style={styles.checkboxGroup}
                                options={optionsPromotion?.map(item => ({ ...item, label: t(item.label) }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name='RatingAverage'
                            label={<TextCus style={styles.labelFilter}>hotel.star</TextCus>}
                        >
                            <RatingAverage
                                value={value?.RatingAverage}
                                setValue={val => form.setFieldValue('RatingAverage', val)}
                            />
                        </Form.Item>
                        <Form.Item
                            name='StarRatings'
                            label={<TextCus style={{ ...styles.labelFilter, color: theme.text.black_900 }}>hotel.hotelStar</TextCus>}
                            trigger="setValue"
                        >
                            <HotelRank
                                value={value?.StarRatings}
                                setValue={val => form.setFieldValue('StarRatings', val)}
                            />
                        </Form.Item>
                        <Form.Item
                            name='PropertyGroupGuids'
                            label={<TextCus style={styles.labelFilter}>hotel.convenient.title</TextCus>}
                            trigger="setValue"
                        >
                            <Convenient
                                value={value?.PropertyGroupGuids}
                                setValue={val => form.setFieldValue('PropertyGroupGuids', val)}
                            />
                        </Form.Item>
                        {/* <Form.Item
                            name='sustainable'
                            label={<TextCus style={styles.labelFilter}>hotel.sustainable.title</TextCus>}
                        >
                            <Checkbox.Group
                                style={styles.checkboxGroup}
                                options={optionsSustainable}
                            />
                        </Form.Item> */}
                        <Form.Item
                            name='BrandGuids'
                            label={<TextCus style={styles.labelFilter}>hotel.brand</TextCus>}
                        >
                            <Brand
                                value={value?.BrandGuids}
                                setValue={val => form.setFieldValue('BrandGuids', val)}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default memo(FilterPopup, areEqual);