import { createSelector } from 'reselect';

const selector = (state: { base }) => state.base;

const error = createSelector(selector, ({ error }) => error);
const loading = createSelector(selector, ({ loading }) => loading);
const loadingConfig = createSelector(selector, ({ loadingConfig }) => loadingConfig);
const data = createSelector(selector, ({ data }) => data);
const balance = createSelector(selector, ({ balance }) => balance);
const countries = createSelector(selector, ({ countries }) => countries);
const publishedConfig = createSelector(selector, ({ publishedConfig }) => publishedConfig);
const getDataByKey = (key: string) => createSelector(selector, (data) => data?.[key]);
const currency = createSelector(selector, ({ currency }) => currency);
const language = createSelector(selector, ({ language }) => language);
const paymentFee = createSelector(selector, ({ paymentFee }) => paymentFee);
const payment = createSelector(selector, ({ payment }) => payment);
const loadingPayment = createSelector(selector, ({ loadingPayment }) => loadingPayment);    
const landingPage = createSelector(selector, ({ landingPage }) => landingPage);
const loadingLandingPage = createSelector(selector, ({ loadingLandingPage }) => loadingLandingPage);

export {
    error,
    loading,
    data,
    balance,
    countries,
    publishedConfig,
    getDataByKey,
    loadingConfig,
    currency,
    language,
    paymentFee,
    payment,
    loadingPayment,
    landingPage,
    loadingLandingPage
}
