export default {
    hotel: 'Khách sạn',
    bookedManager: 'Quản lý đặt phòng',
    viewDetails: "Xem chi tiết",
    bookingDate: 'Ngày đặt',
    bookingCode: 'Mã đặt',
    checkinDate:'Ngày nhận phòng',
    checkoutDate:'Ngày trả phòng',
    all:'Tất cả',
    areBooking: 'Đang đặt phòng',
    preCheckFail:'Đặt phòng chưa thành công',
    bookingSuccessfully: 'Đặt phòng thành công',
    bookingFail: 'Đặt phòng thất bại',
    areCanceling: 'Đang hủy phòng',
    cancelled: 'Đã hủy',
    areCancelling: 'Đang hủy đặt phòng',
    cancellingFail: 'Hủy đặt phòng thất bại',
    completed: 'Đã hoàn tất',
    default:'Mặc định',
    search:'Tìm kiếm',
    resultBooked: {
        1: 'Có tất cả ',
        2: ' {{0}} đơn ',
        3: ' được tìm thấy',
    },
    error:'Lỗi',
    warning:'Chú ý',
    notiLogin: 'Bạn cần đăng nhập để thực hiện chức năng này',
    bookingNotFound: 'Không tìm thấy đơn đặt phòng',
}