import React, { FC, memo, useCallback, useMemo } from "react";
import { AutoComplete, AutoCompleteProps, Input, InputNumberProps, InputProps } from "antd";
import { areEqual } from "utils/libs";
import { InputNumberCus } from "components";
import { useAutoCompleteInput } from "hooks";

type IProps = AutoCompleteProps & {
    type?: 'text' | 'number'
    childrenProps?: InputProps | InputNumberProps
    keyData?: string
    keyField?: string
}

const AutoCompleteInput: FC<IProps> = ({
    type = 'text',
    childrenProps,
    keyData = 'customerInfo'
    , keyField = 'name',
    ...props
}) => {
    const { dataAutoComplete } = useAutoCompleteInput()
    const children = useMemo(() => ({
        text: Input,
        number: InputNumberCus
    }), [Input, InputNumberCus])

    const RenderChildren = useMemo(() => children[type] as any, [type])

    const options = useMemo(() => {
        return dataAutoComplete?.[keyData]?.map((item: any) => ({
            ...item,
            value: item?.[keyField]
        })
        )
    }, [keyData, keyField, JSON.stringify(dataAutoComplete)])

    const handleFilterOption = useCallback((inputValue: string, option: any) => {
        return option?.value?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
    }, [])

    return (
        <>
            <AutoComplete
                {...props}
                options={options}
                filterOption={handleFilterOption}
                backfill
                defaultActiveFirstOption
            >
                <RenderChildren
                    {...childrenProps}
                />
            </AutoComplete>
        </>
    );
};

export default memo(AutoCompleteInput, areEqual);