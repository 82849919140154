import React, { FC, memo, useCallback, useEffect } from 'react';
import { Checkbox, Input, Spin } from 'antd';
import { areEqual, debounce } from 'utils/libs';
import createStyles from './styles';
import Hook from 'pages/Hotel/hook';
import Icons from 'assets/icons';
import { t } from 'i18next';

interface IProps {
    value?: string[],
    setValue?: any
}
const Brand: FC<IProps> = ({ value, setValue }) => {
    const { chainBrandsData: options, getChainBrands, isLoadingdetail } = Hook()
    const styles = createStyles();


    const handleSearch = useCallback((e) => {
        const { value } = e.target
        getChainBrands({
            keywords: value,
            pageSize: 5,
            page: 1
        })
    }, [])

    useEffect(() => {
        setValue(value as any)
    }, [JSON.stringify(value)])

    return (
        <>
            <Input
                placeholder={t('hotel.search')}
                suffix={<Icons.searchIcon />}
                onChange={debounce(handleSearch)}
            />
            <Spin spinning={isLoadingdetail}>
                <Checkbox.Group
                    style={styles.checkboxGroup}
                    value={value}
                    onChange={setValue as any}
                >
                    {options?.map(item => (
                        <Checkbox
                            key={item?.guid}
                            value={item?.guid}
                        >{item?.brandName}
                        </Checkbox>
                    ))}

                </Checkbox.Group >
            </Spin>
        </>
    );
}

export default memo(Brand, areEqual);