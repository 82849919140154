import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SelectProps, Spin, Select, Row, Modal, Col } from "antd";
import { t } from "i18next";
import Hook from "pages/Hotel/hook";
import { areEqual, debounce } from "utils/libs";
import Icons from "assets/icons";
import './styles.css'
import {  TextCus } from "components";
import { STEP } from "pages/Hotel/types";
import { theme } from "theme";
import { stepContext } from "../../Hotel";

type IProps = SelectProps & {
    placeholder?: string,
    handleSelect?: (value: any) => void,
    countryIso2?: string,
    error: boolean,
    valueSearch?: string,
}

const SearchKeywords: FC<IProps> = ({
    placeholder,
    suffixIcon,
    handleSelect,
    valueSearch,
    error,
    style,
    ...props
}) => {
    const { getLocations } = Hook()
    const [options, setOptions] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const { handleDataStep } = useContext(stepContext) as any
    const [isHover, setIsHover] = useState(false)
    const [isFocus, setIsFocus] = useState(false)

    const countryIso2Search = useMemo(() => handleDataStep('GET', STEP.LIST, 'searchParams')?.countryIso2Search, [handleDataStep])
    // const setCountryIso2Search = useCallback((value) => handleDataStep('SET', STEP.LIST, 'searchParams', old => ({
    //     ...old,
    //     countryIso2Search: value,
    //     EnumOperationName: null, 
    //     ObjectGuid: null, 
    //     EnumPlaceType: null
    // })), [handleDataStep])

    const handleSearch = useCallback((value) => {
        setIsLoading(true)
        getLocations({
            countryIso2: countryIso2Search,
            keywords: value
        }, (res) => {
            setIsLoading(false)
            const hotels = res?.hotels?.map((item, index) => ({
                key: item.guid + index,
                value: item?.guid,
                label: item?.name,
                guid: item.guid,
                enumOperationName: item.enumOperationName
            }))
            const cities = res?.cities?.map((item, index) => ({
                key: item.guid + index,
                value: item?.guid,
                label: item?.name,
                guid: item.guid,
                enumOperationName: item.enumOperationName
            }))
            const areas = res?.areas?.map((item, index) => ({
                key: item.guid + index,
                value: item?.guid,
                label: item?.name,
                guid: item.guid,
                enumOperationName: item.enumOperationName
            }))
            const places = res?.places?.map((item, index) => ({
                key: item.guid + index,
                value: item?.guid,
                label: item?.name,
                guid: item.guid,
                enumOperationName: item.enumOperationName,
                enumPlaceType: item.enumPlaceType
            }))
            const locations = [
                { key: 'currentLocation', label: <Row align='middle'><Icons.locationIcon /> <TextCus style={{ marginLeft: 12 }}>hotel.currentLocation</TextCus></Row>, value: 'currentLocation', enumOperationName: 3 },
                places?.length ? { key: 'places', label: t('Địa điểm'), options: places } : null,
                cities?.length ? { key: 'cities', label: t('Thành phố'), options: cities } : null,
                areas?.length ? { key: 'areas', label: t('Khu vực'), options: areas } : null,
                hotels?.length ? { key: 'hotels', label: t('Khách sạn'), options: hotels } : null,
            ]
            setOptions(locations.filter((item: any) => item))
        },
            () => {
                setIsLoading(false)
                setOptions([])
            }
        )
    }, [getLocations, setOptions, countryIso2Search])

    const handleSelectCus = useCallback((value, option) => {
        if (value === 'currentLocation') {
            requestCurrentLocation(() => {
                handleSelect && handleSelect(option)
            })
            return
        }
        handleSelect && handleSelect(option)

    }, [handleSelect])

    const requestCurrentLocation = useCallback((callback) => {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
            if (result.state === 'prompt') {
                Modal.warning({
                    title: t('hotel.warning'),
                    content: t('hotel.warningLocationContent'),
                })
            }
            callback && callback()
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords
                handleDataStep('SET', STEP.LIST, 'searchParams', old => ({
                    ...old,
                    latitude,
                    longitude
                }))
                handleDataStep('SET', STEP.COMMON, 'center', {
                    lat: latitude,
                    lng: longitude
                })
            }, () => {
                Modal.warning({
                    title: t('hotel.warning'),
                    content: t('hotel.warningLocationContent'),
                })
            })
        })

    }, [handleDataStep])

    useEffect(() => {
        countryIso2Search && handleSearch(valueSearch)
    }, [countryIso2Search])

    return (
        <Row
            {...error && {
                className: 'search-keywords'
            }}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            style={{
                ...style,
                gap: 0,
                padding: 0,
                border: `1px solid ${error ? 'red' : (isHover || isFocus ? theme.background.primary : '#d9d9d9')}`,
                borderRadius: 6,
            }}>
            {/* <Col span={6} style={{ height: '100%' }}>
                <Combobox
                    getData={getCountries}
                    valueField="countryIso2"
                    displayField="countryIso2"
                    displaySelect={it=>`${it.countryIso2} - ${it.countryTranslated}`}
                    removeIcon={false}
                    defaultValue={countryIso2Search}
                    // suffixIcon={false}
                    variant="borderless"
                    popupMatchSelectWidth={false}
                    style={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.04)', height: '100%', padding: 0 }}
                    handleSelect={(_, option) => {
                        setCountryIso2Search(option?.countryIso2)
                    }}
                    {...error && {
                        status: 'error',
                    }}
                />
            </Col> */}
            <Col span={24} style={{ height: '100%' }}>
                <Select
                    {...props}
                    style={{ width: '100%', height: '100%' }}
                    variant='borderless'
                    showSearch
                    // popupMatchSelectWidth={false}
                    // popupClassName='search-keywords-popup'
                    options={options}
                    onSearch={debounce(handleSearch, 300)}
                    onSelect={handleSelectCus}
                    loading={isLoading}
                    suffixIcon={isLoading ? <Spin /> : suffixIcon}
                    filterOption={false}
                    placeholder={placeholder}
                    {...error && {
                        status: 'error',
                        placeholder: <TextCus type='danger'>hotel.searchError.message</TextCus>
                    }}
                />
            </Col>
        </Row>
    );
}
export default memo(SearchKeywords, areEqual);