import { TextCus } from 'components';
import React, { FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { areEqual, getMeta } from 'utils/libs';
import createStyles from '../styles';
import { Button, Col, Divider, Row, Spin } from 'antd';
import Icons from 'assets/icons';
import Hook from 'pages/Hotel/hook';
import PhotoAlbum from 'react-photo-album';
import { stepContext } from 'pages/Hotel/view/Hotel';
import { STEP } from 'pages/Hotel/types';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Overview: FC = () => {
  const styles = createStyles();
  const { isLoadingdetail, getOverview, overviewData } = Hook();
  const [photos, setPhotos] = useState<any[]>([]);
  const [favorites, setFavorites] = useState<string[]>([]);
  const isFavorited = (itemId: string) => {
    return favorites.includes(itemId);
  };
  const toggleFavorite = (itemId: string) => {
    if (isFavorited(itemId)) {
      const updatedFavorites = favorites.filter(id => id !== itemId);
      setFavorites(updatedFavorites);
    } else {
      const updatedFavorites = [...favorites, itemId];
      setFavorites(updatedFavorites);
    }
  };
  const handleFavoriteHotel = () => {
    toggleFavorite(overviewData?.guid);
  };
  useEffect(() => {
    const storedFavorites = localStorage.getItem('favorites');
    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }, [favorites]);

  const { handleDataStep } = useContext(stepContext) as any;
  const { i18n } = useTranslation();
  const isVietnamese = useMemo(() => i18n.language === 'vi', [i18n.language]);

  const guid = useMemo(() => handleDataStep('GET', 'DETAIL', 'guidView'), [handleDataStep]);
  const overview = useMemo(() => handleDataStep('GET', 'DETAIL', 'data'), [handleDataStep]);
  useEffect(() => {
    overviewData?.urLs?.map(async item => {
      let width = 80;
      let height = 80;
      const img = await getMeta(item);
      if (img) {
        width = img?.['naturalWidth'];
        height = img?.['naturalHeight'];
      }
      setPhotos(old => [
        ...old,
        {
          src: item,
          width,
          height,
        },
      ]);
    });
  }, [JSON.stringify(overviewData?.urLs)]);

  const startComponent = useCallback((rating?: number) => {
    const arr: any[] = [];
    for (let i = 0; i < (rating ?? 0); i++) {
      arr.push(<Icons.starFillIcon />);
    }
    return arr;
  }, []);

  const openGGMaps = useCallback(() => {
    const url = `https://maps.google.com/?q=${overviewData?.latitude},${overviewData?.longitude}`;
    window.open(url, '_blank')?.focus();
  }, [overviewData?.latitude, overviewData?.longitude]);

  useEffect(() => {
    if (guid && overviewData?.guid !== guid) {
      getOverview({ hotelId: guid });
    }
    handleDataStep('SET', STEP.DETAIL, 'overview', overview);
  }, [guid]);

  return (
    <>
      <Spin spinning={isLoadingdetail}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Row align='middle' justify='space-between'>
            <Col style={{ gap: 12 }}>
              <Row align='middle' style={{ margin: '6px 0' }}>
                <TextCus style={styles.hotelName}>{overviewData?.hotelName}</TextCus>
                <Row style={{ marginLeft: 10 }}>
                  {startComponent(overviewData?.starRating)?.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })}
                </Row>
              </Row>
              <Row align='middle' style={{ gap: 10, margin: '6px 0' }}>
                <TextCus
                  style={styles.comment}
                  replace={{
                    0: overviewData?.ratingAverage,
                    1: overviewData?.numberOfReviews as any,
                  }}
                >
                  hotel.detail.comment
                </TextCus>
                <Divider type='vertical' />
                <TextCus
                  style={styles.subName}
                  replace={{
                    0: overviewData?.rankInCity as any,
                    2: isVietnamese
                      ? overviewData?.cityTranslated
                      : (overviewData?.cityName as string),
                  }}
                >
                  hotel.detail.popularityScore
                </TextCus>
              </Row>
            </Col>
            <Col>
              {isFavorited(overviewData?.guid) ? (
                <Icons.favoriteIcon style={{ cursor: 'pointer' }} onClick={handleFavoriteHotel} />
              ) : (
                <Icons.bookmarkIcon style={{ cursor: 'pointer' }} onClick={handleFavoriteHotel} />
              )}
            </Col>
          </Row>
          <Row align='middle' style={{ gap: 10 }}>
            <TextCus style={styles.address}>{overviewData?.addresses}</TextCus>
            <Button type='link' onClick={openGGMaps} style={styles.showInMap}>
              <span style={{ textDecorationLine: 'underline' }}>{t('hotel.detail.showInMap')}</span>
            </Button>
          </Row>
          <Divider />
          <Row>
            <TextCus style={styles.overview}>{overviewData?.overview}</TextCus>
          </Row>
        </div>
      </Spin>
      {!isLoadingdetail && <PhotoAlbum photos={photos} layout='rows' />}
    </>
  );
};
export default memo(Overview, areEqual);
