import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { addCartRequest, addCartSuccess, addCartFailed } from '../reducer'
import { addCart } from '../../services';

export const addCartNotifier = (params: any = {}, onSuccess, onFailure) => {
    return {
        type: addCartRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* addCartAction({ payload, type, ...props }: PayloadAction<any>) {
    yield call(request, {
        service: addCart,
        params: payload,
        cancelId: type,
        failureAction: addCartFailed,
        successAction: addCartSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}