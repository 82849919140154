import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { TextCus } from "components";
import { Badge, Button, Col, Form, InputNumber, Modal, Radio, Row } from "antd";
import { useWatch } from "antd/es/form/Form";
import { t } from "i18next";
import { areEqual } from "utils/libs";
import { theme } from "theme";
import Hook from "pages/base/hook";
import QrTransfer from "./QrTransfer";

interface IProps {
    open: boolean
    onClose: () => void
}

const Deposit: FC<IProps> = ({ open, onClose }) => {
    const [form] = Form.useForm()
    const { getPaymentFee, paymentFeeData, createPayments, isLoadingPayment, getBalance } = Hook()
    const [method, setMethod] = useState(1)
    const [popupQR, setPopupQR] = useState({
        open: false,
        qrCode: '',
        transactionId: ''
    })
    const transactionFee = useMemo(() => ({
        1: paymentFeeData?.credit,
        3: paymentFeeData?.bank
    }), [paymentFeeData])

    const depositAmount = useWatch(['depositAmount'], form)

    const handleDeposit = useCallback((values) => {
        const params = {
            amount: values.depositAmount,
            paymentMethod: method,
            paymentType: 1,
            transactionFee: transactionFee[method]
        }
        createPayments(params, (res) => {
            setPopupQR({
                open: true,
                qrCode: res.qrCode,
                transactionId: res.transactionID
            })
        })
    }, [method, transactionFee])

    const handleCancelQR = useCallback(() => {
        setPopupQR({
            open: false,
            qrCode: '',
            transactionId: ''
        })
        getBalance({})
        onClose()
    }, [setPopupQR, getBalance])

    useEffect(() => {
        getPaymentFee({ amount: depositAmount })
    }, [depositAmount])

    return (
        <>
            <Modal
                title={t('base.deposit')}
                open={open}
                onCancel={onClose}
                width={'60%'}
                onOk={() => form.submit()}
                okText={t('base.deposit')}
                cancelText={t('base.cancel')}
                confirmLoading={isLoadingPayment}
            >
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={handleDeposit}
                >
                    <Form.Item label={t('base.deposit_amount')} name='depositAmount' style={{ marginBottom: 6 }}>
                        <InputNumber
                            style={{ width: '100%' }}
                            min={0}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value!.replace(/\$\s?|(,*)/g, '') as any}
                            addonAfter='VND'
                        />
                    </Form.Item>
                    {/* chọn nhanh số tiền */}
                    <Row style={{ marginBottom: 12 }}>
                        <Button type='link' onClick={() => form.setFieldsValue({ depositAmount: 1000000 })}>1,000,000 VND</Button>
                        <Button type='link' onClick={() => form.setFieldsValue({ depositAmount: 10000000 })}>10,000,000 VND</Button>
                        <Button type='link' onClick={() => form.setFieldsValue({ depositAmount: 50000000 })}>50,000,000 VND</Button>
                        <Button type='link' onClick={() => form.setFieldsValue({ depositAmount: 100000000 })}>100,000,000 VND</Button>
                    </Row>
                </Form>
                {/* Chọn phương thức thanh toán */}
                <Radio.Group
                    name="paymentMethod"
                    value={method}
                    onChange={(e) => setMethod(e.target.value)}
                >
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <TextCus>base.payment_method</TextCus>
                        </Col>
                        <Col span={12}>
                            <Badge.Ribbon text={`+${paymentFeeData?.credit?.toLocaleString()} VND`}>
                                <Radio
                                    value={1}
                                    style={{
                                        display: 'flex',
                                        height: 90,
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '12px 12px',
                                        margin: 0,
                                        borderRadius: 12,
                                        border: `1px solid ${method === 1 ? theme.background.secondary : '#E5E5E5'}`,
                                        backgroundColor: method === 1 ? theme.background.third : '#FFFFFF',
                                    }}
                                >
                                    {t('base.credit')}
                                </Radio>
                            </Badge.Ribbon>
                        </Col>
                        <Col span={12}>
                            <Badge.Ribbon text={`+${paymentFeeData?.bank} VND`}>
                                <Radio
                                    value={3}
                                    style={{
                                        display: 'flex',
                                        height: 90,
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '12px 12px',
                                        margin: 0,
                                        borderRadius: 12,
                                        border: `1px solid ${method === 3 ? theme.background.secondary : '#E5E5E5'}`,
                                        backgroundColor: method === 3 ? theme.background.third : '#FFFFFF',
                                    }}
                                >
                                    {t('base.bank')}
                                </Radio>
                            </Badge.Ribbon>
                        </Col>
                    </Row>
                </Radio.Group>
            </Modal>
            {popupQR.open && method === 3 &&
                <QrTransfer
                    open={popupQR.open}
                    onClose={handleCancelQR}
                    qrCode={popupQR.qrCode}
                    transactionId={popupQR.transactionId}
                    handleSuccess={handleCancelQR}
                />}
        </>
    );
}
export default memo(Deposit, areEqual)