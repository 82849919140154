import React, { createContext, useState } from "react"

const NotiContext = createContext({})

const NotiProvider = ({ children }: any) => {
    const [notiObj, setNotiObj] = useState({})
    const [noti, setNoti] = useState<any[]>([])
    return (
        <NotiContext.Provider value={{ notiObj, setNotiObj, noti, setNoti }}>
            {children}
        </NotiContext.Provider>
    )
}
export { NotiContext, NotiProvider }