import { NotiContext } from "components"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

export function useNotify() {
  const { setNotiObj } = useContext(NotiContext) as any
  const { t } = useTranslation()
  const success = ({ title = "noti.success", message="", ...rest }: any) => {
    setNotiObj({
      title: t(`${title}`),
      message: t(`${message}`),
      ...rest,
      type: "S",
    })
  }

  const error = ({ message = "noti.wrong", title = "base.error", ...rest }: any) => {
    setNotiObj({
      title: t(`${title}`),
      message: t(`${message}`),
      ...rest,
      type: "E",
    })
  }

  const warning = ({ message = "noti.warning", title = "base.warning", ...rest }: any) => {
    setNotiObj({
      title: t(`${title}`),
      message: t(`${message}`),
      ...rest,
      type: "W",
    })
  }

  const errorFromServer = (res: any) => {
    setNotiObj({
      title: t("base.error"),
      message:  res?.errorMessages[0]?.errorMessage|| t("base.wrong"),
      type: "E",
    })
  }

  return {
    success,
    error,
    warning,
    errorFromServer,
  }
}
