/** @format */

export default {
  resultSearch: {
    1: 'Có ',
    2: ' {{0}} kết quả ',
    3: ' vé phù hợp với bạn',
  },
  distance: 'Khoảng cách {{0}} {{1}}',
  compareAgoda: 'So sánh giá với Agoda',
  viewDetail: 'Chi tiết',
  type: 'Loại cơ sở lưu trú',
  spa: 'Spa',
  swim: 'Bể bơi',
  price: 'Giá',
  sort: 'Thứ tự sắp xếp',
  star: 'Xếp hạng',
  filter: 'Bộ lọc',
  room: 'Phòng {{0}} :',
  adult: 'Người lớn',
  adultAge: 'Trên 12',
  child: 'Trẻ em',
  childAge: 'Dưới 12',
  delRoom: 'Xóa phòng',
  addRoom: 'Thêm phòng',
  apply: 'Áp dụng',
  searchPlaceholder: 'Tìm theo tên địa điểm',
  search: 'Tìm kiếm',
  country: {
    default: 'Chọn quốc gia *',
    default2: 'Quốc gia',
  },
  markup: {
    default: 'Markup',
  },
  searchSetting: 'Cài đặt mặc định',
  searchResult: '{{0}} kết quả',
  clearFilter: 'Xóa bộ lọc',
  sortBy: {
    default: 'Sắp xếp theo',
    closest: 'Mức độ liên quan',
    lowestPrice: 'Giá thấp nhất',
    higheststar: 'Điểm xếp hạng cao nhất',
    highestReview: 'Nhiều bài đánh giá nhất',
  },
  types: {
    default: 'Loại cơ sở lưu trú',
    hotel: 'Khách sạn',
    spaHotel: 'Khách sạn có Spa',
    hotelWithBreakfast: 'Khách sạn có bữa sáng',
    motel: 'Nhà nghỉ cho thuê',
  },
  promotion: {
    title: 'Ưu đãi',
    freeCancel: 'Hủy miễn phí',
    orther: 'Các khuyến mãi đặc biệt khác',
  },
  hotelStar: 'Hạng khách sạn',
  rank: {
    '2s': '2 sao',
    '2sSub': 'Tiêu chuẩn',
    '3s': '3 sao',
    '3sSub': 'Tiện ích',
    '4s': '4 sao',
    '4sSub': 'Cao cấp',
    '5s': '5 sao',
    '5sSub': 'Hàng đầu',
  },
  detail: {
    overview: 'Tổng quan',
    facilities: 'Tiện ích',
    roomtypes: 'Phòng & Giá',
    policies: 'Chính sách & Quy định',
    comment: '{{0}} nhận xét',
    popularityScore: '#{{0}} trong {{1}} khách sạn tại {{2}}',
    showMore: 'Xem thêm',
    showLess: 'Ẩn bớt',
  },
  rating: {
    default: 'Tỉ lệ sao',
  },
  currency: {
    default: 'Tiền tệ',
  },
  save: 'Lưu',

  // Tour
  findPlaceExplore: 'Tìm nơi bạn muốn khám phá',
  inDayTour: 'Tour trong ngày',
  inDaysTour: 'Tour dài ngày',
  travelTour: 'Vé tham quan',
  climbing: 'Leo núi',
  canyoning: 'Vượt thác',
  searchByKeyword: 'Tìm theo từ khóa',
  higheststar: 'Điểm xếp hạng',
  selectDate: 'Chọn ngày',
  ranking: {
    today: 'Hôm nay',
    tomorrow: 'Ngày mai',
    otherDay: 'Ngày khác',
  },
  category: 'Danh mục',
  duration: 'Thời lượng',
  optionsDuration: {
    '0-3 hours': '0-3 tiếng',
    '3-5 hours': '3-5 tiếng',
    halfDay: 'Nửa ngày',
    oneDay: '1 ngày',
  },
  departureTime: 'Giờ khởi hành',
  optionsTimeStarts: {
    earlyMorning: 'Sáng sớm (05:00-09:00)',
    afternoon: 'Chiều (12:00-16:00)',
    evening: 'Chiều tối (16:00-20:00)',
    night: 'Tối (20:00-24:00)',
  },
  instructionLang: 'Ngôn ngữ hướng dẫn',
  optionsInstructureLang: {
    vietnamese: 'Tiếng Việt',
    english: 'Tiếng Anh',
  },
  emptyData: 'Không có dữ liệu',
  booked: 'Đã đặt',
  frequencyQuestions: 'Câu hỏi thường gặp về Tour',
  outstandingTours: 'Tour nổi bật',
  outstandingLocation: 'Địa điểm nổi bật',
  readMore: 'Xem thêm',
  addToCart: 'Thêm chào giá',
  bookNow: 'Đặt ngay',
  services: 'Các gói dịch vụ',
  timeAll: 'Thời gian - Tất cả',
  error: 'Lỗi',
  pickDayToJoin: 'Chọn ngày tham gia',
  serviceInfo: 'Thông tin gói dịch vụ',
  noteRequired: 'Vui lòng hoàn tất các mục yêu cầu để chuyển đến bước tiếp theo',
  loginRequired: 'Bạn cần đăng nhập để thực hiện chức năng này',

  detailServices: 'Chi tiết gói dịch vụ',
  aboutThisService: 'Về dịch vụ này',
  mightLike: 'Có thể bạn sẽ thích',
  includes: 'Bao gồm',
  pick: 'Chọn',
  cancelPolicies: 'Chính sách hủy',
  generalTerms: 'Điều khoản chung',
  all: 'Tất cả',
  elderly: 'Người cao tuổi',
  totalPrice: 'Tổng tiền: ',
  pickTour: 'Chọn Tour',
  orderInfo: 'Thông tin đơn',
  fillInfo: 'Điền thông tin',
  payment: 'Thanh toán',
  customerInfo: 'Thông tin người tham gia',
  customerInfoIndex: 'Thông tin người tham gia {{index}}',
  customer_index: 'Người tham gia {{index}}',
  add_customer: 'Thêm người tham gia',
  name: 'Họ tên',
  dateOfBirth: 'Ngày sinh',
  phoneNumber: 'Số điện thoại',
  email: 'Email',
  requiredField: 'Vui lòng không bỏ trống trường này',
  emailInvalid: 'Email không hợp lệ',
  phoneInvalid: 'Số điện thoại không hợp lệ',
  vat: 'Thuế giá trị gia tăng',
  nameCompany: 'Tên công ty',
  addressCompany: 'Địa chỉ công ty',
  mst: 'Mã số thuế',
  customerContact: 'Thông tin liên lạc',
  policy_info: 'Điều khoản và điều kiện',
  booking_condition:
    'Tôi muốn nhận thông tin về chương trình ưu đãi & khuyến mãi đặc biệt từ Bedlinker. Tôi hiểu có thẻ bỏ theo dõi khi không muốn nhận thông tin vào bất kỳ lúc nào',
  booking_condition_content1: 'Tôi đã hiểu & Đồng ý với ',
  booking_condition_content2: 'Điều khoản sử dụng chung',
  booking_condition_content3: ' và ',
  booking_condition_content4: 'Chính sách quyền riêng tư',
  bookTour: 'Đặt tour',
  person: 'Người',
  adultCount: 'Người lớn {{index}}',
  childrenCount: 'Trẻ em {{index}}',
  personCount: 'Người {{index}}',
  elderlyCount: 'Người cao tuổi {{index}}',
  adultCountInfo: 'Thông tin người lớn {{index}}',
  personCountInfo: 'Thông tin người {{index}}',
  childrenCountInfo: 'Thông tin trẻ em {{index}}',
  elderlyCountInfo: 'Thông tin người cao tuổi {{index}}',
  add_adult: 'Thêm người lớn',
  add_children: 'Thêm trẻ em',
  add_elderly: 'Thêm người cao tuổi',
  customer: 'Khách hàng',
  bookedTour: 'Đơn đã đặt',
  expiredRequest: 'Vui lòng thanh toán trước',
  afterBookingDate: 'Sau thời gian trên mã đặt chỗ sẽ bị hủy',
  bookingCode: 'Mã đặt chỗ',
  sessionsType: 'Loại gói dịch vụ',
};
