import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getPaymentFeeRequest, getPaymentFeeFail, getPaymentFeeSuccess } from '../reducer'
import { getPaymentFee } from '../../services';

export const getPaymentFeeNotifier = (params: any = {}, onSuccess, onFailure) => {
    return {
        type: getPaymentFeeRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getPaymentFeeAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: getPaymentFee,
        params: payload,
        cancelId: type,
        failureAction: getPaymentFeeFail,
        successAction: getPaymentFeeSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}