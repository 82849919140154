
import { Grid } from "antd"
import { useState } from "react"
import { localStorageCons } from "utils/constants"

const { useBreakpoint } = Grid

export const useCommon = () => {
  // breakpoint
  const { xs, sm, md, lg, xl, xxl } = useBreakpoint()
  let breakpoint = ""
  if (xs && !sm && !md && !lg && !xl && !xxl) breakpoint = "xs"
  if (sm && !md && !lg && !xl && !xxl) breakpoint = "sm"
  if (md && !lg && !xl && !xxl) breakpoint = "md"
  if (lg && !xl && !xxl) breakpoint = "lg"
  if (xl && !xxl) breakpoint = "xl"
  if (xxl) breakpoint = "xxl"
  const isSmallScreen = breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md"

  const getLocalStorage = (key: string) => {
    return JSON.parse(localStorage.getItem(key) ?? '')
  }
  const setLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight)
    setWindowWidth(window.innerWidth)
  })

  const siteId = localStorage.getItem(localStorageCons.SITE_ID)
  const setSiteId = (value: string) => {
    localStorage.setItem(localStorageCons.SITE_ID, value)
  }
  return {
    currentBreakpoint: breakpoint,
    isSmallScreen,
    getLocalStorage,
    setLocalStorage,
    windowWidth,
    windowHeight,
    siteId,
    setSiteId
  }
}
