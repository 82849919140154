

export const keyTabs = {
    OVERVIEW: 'overview',
    FACILITIES: 'facilities',
    ROOMTYPES: 'roomtypes',
    POLICIES: 'policies',
}

export const tabsData = [
    {
        label: 'hotel.detail.overview',
        key: keyTabs.OVERVIEW
    },
    {
        label: 'hotel.detail.facilities',
        key: keyTabs.FACILITIES
    },
    {
        label: 'hotel.detail.roomtypes',
        key: keyTabs.ROOMTYPES
    },
    {
        label: 'hotel.detail.policies',
        key: keyTabs.POLICIES
    },
];