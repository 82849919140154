import { Button, Form, FormInstance, Row } from "antd";
import { TextCus } from "components";
import { t } from "i18next";
import React, { FC, memo } from "react";
import { theme } from "theme";
import { areEqual } from "utils/libs";
import createStyles from "./styles";
import CustomerItem from "./CustomerItem";
import Icons from "assets/icons";

interface IProps {
    index?: any
    form: FormInstance
}

const CustomerInfo : FC<IProps>= ({index, form}) => {
    const styles = createStyles();
    return (
        <>
            <Row align='middle' gutter={[12, 12]} style={{ marginTop: 24 }}>
                <div style={{
                    width: 16,
                    height: 16,
                    position: 'relative',
                    background: theme.background.primary,
                    borderRadius: 100
                }}
                />
                <TextCus style={styles.roomInfo}>{t('hotel.customer_info',{room:index})}</TextCus>
            </Row>
            <div
                style={{
                    padding: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 24,
                    justifyContent: 'start',
                }}
            >
                <Form.List name={`customerInfo${index}`}>
                    {
                        (fields, { add, remove }) => (
                            <>
                                {fields.map((field, i) => (
                                    <CustomerItem
                                        key={field.key}
                                        index={i}
                                        field={field}
                                        remove={remove}
                                        form={form}
                                        name={`customerInfo${index}`}
                                    />
                                ))}
                                <Button
                                    type='primary'
                                    style={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: 8 }}
                                    icon={<Icons.addIcon />}
                                    onClick={() => add()}
                                >{t('hotel.add_customer')}</Button>
                            </>
                        )
                    }

                </Form.List>
            </div>
        </>
    );
}
export default memo(CustomerInfo, areEqual);