import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'utils/helper/api/request';
import { getUserInfoRequest, getUserInfoFail, getUserInfoSuccess } from '../reducer'
import { getUserInfo } from '../../services';

export const getUserInfoNotifier = (params: any = {},onSuccess, onFailure) => {
    return {
        type: getUserInfoRequest.type,
        loader: true,
        payload: params,
        onSuccess,
        onFailure
    };
};

export function* getUserInfoAction({ payload, type,...props }: PayloadAction<any>) {
    yield call(request, {
        service: getUserInfo,
        params: payload,
        cancelId: type,
        failureAction: getUserInfoFail,
        successAction: getUserInfoSuccess,
        onSuccess: props['onSuccess'],
        onFailure: props['onFailure'],
    });
}