import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
    getUserInfoAction,
    loginAction,
    getCountriesAction,
    getBalanceAction,
    getPusblishConfigAction,
    getNewsAction,
    getNewsTypeAction,
    getPaymentFeeAction,
    createPaymentsAction,
    getPaymentDetailAction,
    getLandingPageAction
} from './actions'
import {
    getUserInfoRequest,
    loginRequest,
    getCountriesRequest,
    getBalanceRequest,
    getPusblishConfigRequest,
    getNewsRequest,
    getNewsTypeRequest,
    getPaymentFeeRequest,
    createPaymentsRequest,
    getPaymentDetailRequest,
    getLandingPageRequest
} from './reducer';

export default [
    takeLatest(loginRequest, loginAction),
    takeEvery(getUserInfoRequest, getUserInfoAction),
    takeEvery(getCountriesRequest, getCountriesAction),
    takeEvery(getBalanceRequest, getBalanceAction),
    takeEvery(getPusblishConfigRequest, getPusblishConfigAction),
    takeEvery(getNewsRequest, getNewsAction),
    takeEvery(getNewsTypeRequest, getNewsTypeAction),
    takeEvery(getPaymentFeeRequest, getPaymentFeeAction),
    takeLatest(createPaymentsRequest, createPaymentsAction),
    takeEvery(getPaymentDetailRequest, getPaymentDetailAction),
    takeEvery(getLandingPageRequest, getLandingPageAction),
]